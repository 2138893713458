import React, { useEffect, useState,useRef,useCallback } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import OpenInBrowser from '@mui/icons-material/OpenInBrowser';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, ListItem, ListItemText, Menu, MenuItem, styled } from '@mui/material';
import {
  useLoaderData,
  useNavigate
} from "react-router-dom";
import { debounce } from 'lodash';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import CreateCompanyModal from './CreateCompanyModal';
import { subscribe, unsubscribe } from "./events";
import Divider from '@mui/material/Divider';
import { red } from '@mui/material/colors';
import {  API, Amplify, graphqlOperation  } from 'aws-amplify';
import AWS from 'aws-sdk';
import awsExports from "./aws-exports";
import getTenantIdFromURL from './utils/getTenantIdFromURL';
import { useSelector } from 'react-redux';
import { toast } from "react-toastify";
import { getTenantInfo } from "./utils/GraphQLHelper/GetTenantInfo.js";

import { List, ListItemButton } from '@mui/material';
import { Padding, Search } from '@mui/icons-material';
import Green from './Assets/Images/smiley_green.png'
import Red from './Assets/Images/smiley_red.png'
import Yellow from './Assets/Images/smiley_yellow.png'
import RightIcon from "./Assets/Images/right.svg";
import LeftIcon from "./Assets/Images/left.svg";

import Null from './Assets/Images/null.svg';
import Bar from './Assets/Images/bar.svg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EmojiFlagsIcon from '@mui/icons-material/EmojiFlags';
import LocalAtmIcon from '@mui/icons-material/LocalAtm'; 
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import Refresh from "./Assets/Images/refresh.svg"
import { getUnreadAlerts } from './utils/GraphQLHelper/AlertTable';
import Bulb from "./Assets/Images/bulb.svg"
import locationIcon from "./Assets/Images/location.svg"
import bgArc from "./Assets/Images/bg-arc.svg"
import { getCompaniesByView, getCompany, getLastVisitedCompanies, listAPIMappings, listChatFileCollections, listLastVisitedCompanies, triggerQuestionsAgent } from "./graphql/queries";
import { onCreateAlert } from './graphql/subscriptions';
import { createAPIMapping, createChatFileCollection, createLastVisitedCompanies, deleteLastVisitedCompanies, updateAPIMapping, updateCompany, updateLastVisitedCompanies } from './graphql/mutations.js';
import zIndex from '@mui/material/styles/zIndex.js';
import { useSearchQuery } from './context/searchContext.js';
import dayjs from 'dayjs';
import { useAddCompanyModal } from './context/addCompanyModalContext.js';
import Backdrop from '@mui/material/Backdrop';

const ChatFile = ({ company, open, onClose }) => {
  const [fileList, setFileList] = useState([]);
  const [currentFileId, setCurrentFileId] = useState("");
  const [chatOpen, setChatOpen] = useState(false);

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const result = await API.graphql(graphqlOperation(listChatFileCollections, {
          filter: {
            companyId: { eq: company.id }
          }
        }));
        setFileList(result.data.listChatFileCollections.items);
      } catch (error) {
        console.error("Error fetching chat files:", error);
      }
    };
    fetchFiles();
  },[company.id]);

  const handleFileClick = (fileInfo) => {
    setCurrentFileId(fileInfo.fileId);
    setChatOpen(true);
  };

  const handleChatClose = () => {
    setChatOpen(false);
    setCurrentFileId(""); // Clear current file id
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Chat Files</DialogTitle>
      <DialogContent>
        {currentFileId ? (
          <Modal
            open={chatOpen}
            onClose={handleChatClose}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box sx={{ width: "80%", display: "flex" }}>
              <iframe
                title="Heybrain Chatbot"
                src={`https://dty0xa7tzq24v.cloudfront.net?api_key=325176a05bde66cf846db4bc164a0b83&fileId=${currentFileId}`}
                style={{
                  width: "100%",
                  height: "90vh",
                  border: "none",
                  borderRadius: 5,
                }}
              />
              <Box sx={{ position: "absolute", top: 20, right: 20 }}>
                <Button variant="contained" onClick={handleChatClose}>
                  X
                </Button>
              </Box>
            </Box>
          </Modal>
        ) : (
          <List>
            {fileList.map((file, index) => (
              <div key={index} style={{ width: '40%', justifyContent: 'center' }}>
                <ListItem>
                  <ListItemText
                    onClick={() => handleFileClick(file)}
                    primary={file.fileName}
                    style={{
                      cursor: 'pointer',
                      color: '#777A82'
                    }}
                  />
                </ListItem>
              </div>
            ))}
          </List>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const BarContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const Bars = styled('div')(({ filled }) => ({
  width: "3px",
  height: "7px",
  backgroundColor: filled ? '#FB8802' : '#777A82',
  borderRadius: "3px",
  margin: '0 2px',
}));

function CustomBarRating({ maxValue = 10, value = 4 }) {

  return (
    <BarContainer sx={{marginBottom:1}}>
      {[...Array(maxValue)].map((_, index) => (
        <Bars 
          key={index} 
          filled={index < value}
        />
      ))}
    </BarContainer>
  );
}

const CompanyCard = ({ company, summaryData,getSummaryData, alerts , selectable,setSelectedCompany,handleOpenModal}) => {
  const currentCompany = summaryData.find(companySummary => companySummary["Company Name"] === company?.name);
  const timer = React.useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const [dnbJsonData , setDnbJsonData] = React.useState("");
  const [companyDnbID , setCompanyDnbID] = React.useState(null);
  const [disabled, setDisabled] = useState(false);
  const [latestAlert , setLatestAlert] = React.useState();
  const [foundedYear , setFoundedYear] = React.useState("N/A");
  const [yearlyRevenue , setYearlyRevenue] = React.useState("N/A");
  const [fileListOpen, setFileListOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const confidenceLevel = currentCompany?.confidenceLevel || 0 ;


  const handleFileListClick = () => {
    setFileListOpen(true);
  };
  
  const handleModalClose = () => {
    setFileListOpen(false);
  };

  const handleCheckboxChange = (e) => {
      setSelectedCompany(company.id);
      handleOpenModal()
    
  };

  const open = Boolean(anchorEl);

  const getScoreImage = (score) => {
    if (score >= 1 && score < 4) {
      return Green;
    } else if (score >= 4 && score < 8) {
      return Yellow;
    } else if (score >= 8) {
      return Red;
    }
    return null; 
  };
  
  const getScoreColor = (score) => {
    if (score >= 1 && score < 4) {
      return '#60AC50'; 
    } else if (score >= 4 && score < 8) {
      return '#E7BF3C';
    } else if (score >= 8) {
      return '#F25A48'; 
    }
    return '#000000'; 
  };
  

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleActionClick = (action) => {
    console.log(`Performing action: ${action}`);
    handleClose();
  };

  const handleRefreshClick =()=>{
    onTriggerAgentClick();
    getSummaryData();
  }

  const formatToUSD = (yearlyRevenue) => {
    let usdRevenue = null;
    for (let revenue of yearlyRevenue) {
        if (revenue.currency === "USD") {
            usdRevenue = revenue.value;
            break;
        }
    }

    if (usdRevenue !== null) {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(usdRevenue);
    } else {
        return "N/A"; 
    }
};
  function extractYear(dateString) {
    if (typeof dateString !== 'string') {
        return "N/A";
    }

    const yearPattern = /\b(\d{4})\b/;
    const monthDayYearPattern = /\b(?:January|February|March|April|May|June|July|August|September|October|November|December)\s+\d{1,2},\s+(\d{4})\b/;
    const monthYearPattern = /\b(?:January|February|March|April|May|June|July|August|September|October|November|December)\s+(\d{4})\b/;
    
    let match = dateString.match(monthDayYearPattern);
    if (match) {
        return match[1];
    }
    
    match = dateString.match(monthYearPattern);
    if (match) {
        return match[1];
    }
    
    match = dateString.match(yearPattern);
    if (match) {
        return match[1];
    }
    
    return "N/A";
}

  useEffect(()=>{
    const date = (company?.googleSearch?.knowledgeGraph?.raw )?  JSON.parse(company.googleSearch.knowledgeGraph?.raw)?.founded:"N/A";
    const founded = extractYear(date);
    setFoundedYear(founded);
    const allRevenues = dnbJsonData&&dnbJsonData[0]?.response?.organization?.financials[0]?.yearlyRevenue?dnbJsonData&&dnbJsonData[0]?.response?.organization?.financials[0]?.yearlyRevenue:[];
    const usdRevenue = formatToUSD(allRevenues);
    setYearlyRevenue(usdRevenue)
    setCompanyDnbID(company?.dnbCompanyId)
  },[alerts])

  React.useEffect(() => {     
    const fetchDnbDataFromJson = async () => {
      if(companyDnbID){
      try {
        const key = "public/company-info/info-"+ companyDnbID +".json";
        const s3 = new AWS.S3();
        const data = await s3.getObject({ Bucket: "crediarc-content01853-production", Key: key }).promise();
        const fileData = data.Body.toString('utf-8');
        const summary = JSON.parse(fileData);

        for (let i = 0; i < summary.length; i++) {
          const row = summary[i];
          for (const key in row) {
            if (typeof row[key] === "string") {
              row[key] = isNaN(parseFloat(row[key].replace(/,/g, "")))
                ? row[key]
                : parseFloat(row[key].replace(/,/g, ""));
            }
          }
        }
        setDnbJsonData(summary);
      } catch (error) {
        setDnbJsonData(undefined)
        // console.log(error);
      }
    }

    };
    fetchDnbDataFromJson();
  }, [companyDnbID]);

  const onTriggerAgentClick = async () => {
    toast.success(`Trigger Agent started!`, {
      bodyStyle: {
        fontWeight: "500",
      }
    });
    setDisabled(true);
    await API.graphql(
      graphqlOperation(triggerQuestionsAgent, {
        tenantId: company.tenantId,
        id: company.id,
      })
    );
    console.log("triggered")
    timer.current = window.setTimeout(() => {
      setDisabled(false);
    }, 16000);

  };
  const cardStyle={
    background:"#FAFAFC 0% 0% no-repeat padding-box",
    boxShadow:"inset 0px 1px 3px #0000001A, 0px 3px 6px #00000029",
    borderRadius:"8px",
    width:"440px",
    height:"250px",
    padding:"20px 20px 0 20px",
    position:'relative'
  }


  const bgStyle={
    width:"100%",
    height:"100%",
    background: `transparent url(${bgArc}) 0% 0% no-repeat padding-box`,
    // opacity: "0.03",
    position:'absolute',
    top:0,
    left:0,
    zIndex:0
  }
  const avatarStyle={ 
    width: 45,
    height: 45,
    cursor: 'pointer' ,
    borderRadius:"50%",
    boxShadow:"0px 4px 6px #00000029"
  }
  const sideBoxStyle={ 
    display:"flex",
    flexDirection:"column",
    marginRight:"10px",
    alignItems:"center",
    gap:"5px",
  }
  const scoreImage = currentCompany&&currentCompany["Combined Score"] ? getScoreImage(currentCompany["Combined Score"]) : null;
  const scoreColor = currentCompany&&currentCompany["Combined Score"] ? getScoreColor(currentCompany["Combined Score"]) : "#000";

  function getWebsiteLink(data) {
    if (data) {
      const { organicResult,knowledgeGraph } = data;
      // let websiteLink = companyRecord?.website;
      let websiteLink = knowledgeGraph?.website;

      if (!websiteLink && organicResult?.items) {
        const companyNameWords = company?.name.split(" ") || [];
        for (const element of organicResult.items) {
          for (const word of companyNameWords) {
            const urlRegex =
              /^(http|https):\/\/(?!.*(linkedin\.com|youtube\.com|facebook\.com|twitter\.com|instagram\.com|pinterest\.com|tiktok\.com|snapchat\.com|reddit\.com))[^\s/$.?#].[^\s]*$/;
            if (
              element?.source.toLowerCase().includes(word.toLowerCase()) &&
              urlRegex.test(element?.link)
            ) {
              websiteLink = element?.link;
              return websiteLink;
            }
          }
        }
      }
      return websiteLink;
    }
  }
  const website = getWebsiteLink(company?.googleSearch);
  

  return (
    <Card sx={cardStyle} >
      <div style={bgStyle}></div>
      <div style={{ display: 'flex', justifyContent: 'space-between', position: 'relative', zIndex: 1 }}>
      <div style={{width:"320px"}} >
      <CardHeader
        title={
          <Typography
            sx={{
              cursor: "pointer",
              fontWeight: "500",
              color:"#1A2A56",
              fontSize:"20px",
            }}
            variant="h5"
            component="span">
            {company?.name?.substring(0, 15) + (company?.name?.length > 15 ? "..." : "")}
          </Typography>
        }
        sx={{padding:"0px",zIndex:1
        }}
        subheader={
          <Typography
            sx={{
              color: "#777A82",
              maxWidth: 300,
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontSize:"12px",
              fontWeight: "400",
              whiteSpace: "nowrap",
            }}
          >
            {company?.googleSearch?.knowledgeGraph?.type}
          </Typography>
        }
        avatar={<Avatar
          sx={avatarStyle}
          alt={company?.name} src={company?.logo ||
            // google favicon search
            `https://www.google.com/s2/favicons?sz=64&domain=${website}`
          } />}
      />
<CardContent
  sx={{
    height: 120,
    maxHeight: 120,
    padding: 0,
    overflow: 'hidden',
  }}
>
  <div style={{ 
    display: "flex", 
    flexDirection: "column", 
    justifyContent: "center", 
    margin: "10px 0 0 10px",
    height: '100%', 
    overflowY: 'scroll', 
    scrollbarWidth: 'none',
    msOverflowStyle: 'none', 
  }}>
    {selectable ? (
      <Button variant="outlined" sx={{width:"200px"}} onClick={handleCheckboxChange}>
                 Upload New Reports

      </Button>
    ) : (
      <Button variant="outlined" sx={{width:"150px"}} onClick={handleFileListClick}>
        Show Files
      </Button>
    )}
  </div>
</CardContent>

<ChatFile company={company} open={fileListOpen} onClose={handleModalClose} />
      </div>
      <div style={sideBoxStyle}>
        <div style={{display:"flex",gap:"8px", alignItems:"center",alignSelf:"flex-end"}}> 
          <Typography
          sx={{
            fontSize:"24px",
            color:scoreColor,
            fontWeight:"500",
          }}
          >{currentCompany&& typeof( currentCompany["Combined Score"])=="number" ? currentCompany["Combined Score"] : '-'}</Typography>
          <img src={scoreImage?scoreImage:Null} style={{ width: "16px", height: "18px" }} alt="" />
        </div>
        <div style={{alignSelf:"flex-end",marginBottom:"5px"}}>
          <Typography sx={{fontSize:"14px",color:"#777A82",alignSelf:"flex-end",textAlign:"right"}}>Rec. Limit:</Typography>
          <Typography sx={{fontSize:"19px",color:"#2F3D63",fontWeight:"500"}}>${currentCompany&&currentCompany["Recommended Limit"]?currentCompany["Recommended Limit"]:"  -"}</Typography>
        </div>
        <Box
          sx={{alignSelf:"flex-end"}}
          >
            <CustomBarRating value={confidenceLevel}></CustomBarRating>
          </Box>
      <div style={{alignSelf:"flex-end",}}>
      <Button
        id="action-button"
        aria-controls={open ? 'action-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        color="primary"
        disabled={false}
        sx={{
          
          background:'#5186EC 0% 0% no-repeat padding-box',
          borderRadius: "6px",
          backgroundColor:"#5186EC",
          width:"90px",
          height:"30px",
          fontSize:"14px",
          textTransform:"none",
          fontWeight:"400",
        }}
        endIcon={<ExpandMoreIcon />}
        onClick={handleClick}
      >
        Actions
      </Button>
      <Menu
        id="action-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'action-button',
        }}
      >
        <MenuItem onClick={() => handleActionClick('action1')}>Action 1</MenuItem>
        <hr />
        
        <MenuItem onClick={() => handleActionClick('action2')}>Action 2</MenuItem>
        <hr />

        <MenuItem onClick={() => handleActionClick('action3')}>Action 3</MenuItem>
      </Menu>
    </div>
      </div>
      </div>
      <Divider />
      <div style={{display:"flex",justifyContent:"space-between",padding:"10px 0 0 10px",height:"60px"}}>
        <div>
          <EmojiFlagsIcon style={{ color: "#777A82" }}/><br />
          <Typography 
          variant="body2" 
          sx={{
            fontSize:"14px",
            fontWeight:"400",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            maxHeight:"45px",
            maxWidth:"90px"
          }} 
          color="#2F3D63">
          {foundedYear}
          </Typography>
        </div>
        <div>
          <LocalAtmIcon style={{ color: "#777A82" }}/><br />
          <Typography variant="body2" sx={{fontSize:"14px",fontWeight:"400"}} color="#2F3D63">
          {yearlyRevenue}
          </Typography> 
        </div>
        <div>
          <GroupsOutlinedIcon style={{ color: "#777A82" }}/><br />
          <Typography variant="body2" sx={{fontSize:"14px",fontWeight:"400"}} color="#2F3D63">
          {(dnbJsonData&&dnbJsonData[0]?.response?.organization?.numberOfEmployees[0]?.value)?"(est.) "+dnbJsonData[0].response.organization.numberOfEmployees[0].value:"N/A"}
          </Typography>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '0px' }}>
        <img src={locationIcon} style={{ height: "24px", maxWidth: "100%",alignSelf:"flex-start" }}/>
        <Typography variant="body2" sx={{ fontSize: "14px", fontWeight: "400" }} color="#2F3D63">
          {company?.country ? company?.country : "N/A"}&nbsp;
        </Typography>
      </div>
      </div>
        
      
    </Card>
  );
};

const buttonStyle = {
  width:"fit-content",
  height:"44px",
  borderRadius:"8px",
  backgroundColor:"#5186EC",
  color:"#fff",
  fontSize:"20px"
}
const buttonsStyle = {
  height:"250px",
  display:"flex",
  justifyContent:"center",
  alignItems:"center",
  gap:"20px"
}


Amplify.configure(awsExports);

AWS.config.update(
  {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  }
);


const AIChatPage = () => {
  const [selectedOption, setSelectedOption] = useState(1);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [companies, setCompanies] = useState([]);
  const isAdmin = useSelector(state => state.userInfo.isAdmin);
  const URLTenant = getTenantIdFromURL();
  const [tenantId,setTenantId] = useState()
  const [summaryData, setSummaryData] = useState([]);
  const {searchQuery,setSearchQuery}=useSearchQuery();
  const [searchResults, setSearchResults] = useState([]);
  const fileInputRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [file, setFile] = useState(null);
  const {_,setOpenAddCompanyModal}=useAddCompanyModal()
  const [currentFileId, setCurrentFileId] = useState("");
  const [chatOpen, setChatOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const currentTenant  = useSelector(state => state.userInfo.tenantId);
  const selectTenantId =()=>{
    if(isAdmin) setTenantId(URLTenant)
    else setTenantId(currentTenant)
  }
  const buttonStyle = {
    width: "fit-content",
    height: "44px",
    borderRadius: "8px",
    backgroundColor: "#5186EC",
    color: "#fff",
    fontSize: "20px"
  };
  const selectedButtonStyle = {
    ...buttonStyle,
    backgroundColor: "#2F3D63",
  };
  
  const unselectedButtonStyle = {
    ...buttonStyle,
  };
  const buttonsStyle = {
    height: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px"
  };
  const textStyle = {
    width: '264px',
    fontSize: '14px',
    fontWeight: '200',
    color: '#777A82',
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const allowedExtensions = [
      "c", "cpp", "css", "csv", "docx", "gif", "html", "java", "jpeg", "jpg",
      "js", "json", "md", "pdf", "php", "png", "pptx", "py", "rb", "tar", "tex",
      "ts", "txt", "webp", "xlsx", "xml", "zip"
    ];

    const fileExtension = file.name.split('.').pop().toLowerCase();
    if (!allowedExtensions.includes(fileExtension)) {
      setErrorMessage("This file is not allowed.");
    } else {
      setErrorMessage("");
      setFile(file);
    }
  };

  const uploadFile = async () => {
    try {
      if (!file) return;
      setIsUploading(true)
      const bucket = "chat-files-production";

      const s3 = new AWS.S3({
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        region: "eu-central-1",
      });

      const params = {
        Bucket: bucket,
        Key: file.name,
        Body: file,
        ACL: "public-read-write",
      };
      
      const data = await s3.upload(params).promise();
      console.log(data, "Uploaded File Data");
      
      if (data.Location) {
        await uploadFileToHeyBrain(data.Location);
      }
      setIsModalOpen(false)
      setIsUploading(false)
      setFile(null);
    } catch (error) {
      console.error("Error uploading file: ", error);
    }
  };

  const mapAPItoDB = async (tenantId, isPaid, origin, endpoint) => {
    let result = await API.graphql(
      graphqlOperation(listAPIMappings,{tenantId})
    );
    const apiMappings = result.data.listAPIMappings.items;
    console.log(`APIMappings for ${tenantId}`, apiMappings);
    const existingEntry = apiMappings.find(
    (entry) => (entry.endpoint === endpoint && entry.origin === origin)
  );
    if (existingEntry) {
      console.log(`Mapping for endpoint ${endpoint} exists`);
      await API.graphql(
        graphqlOperation(updateAPIMapping, {
          input: {
            id: existingEntry.id,
            tenantId: tenantId,
            totalCalls: existingEntry.totalCalls + 1,
            isPaid,
          },
        }))
    } else {
      console.log(`Mapping for endpoint ${endpoint} doesn't exists`);
      await API.graphql(
        graphqlOperation(createAPIMapping, {
          input: {
            tenantId: tenantId,
            endpoint: endpoint,
            totalCalls: 1,
            origin: origin,
            isPaid,
          },
        })
      );
    }
  };

  const uploadFileToHeyBrain = async (filePath) => {
    const allowedExtensions = [
      "c", "cpp", "css", "csv", "docx", "gif", "html", "java", "jpeg", "jpg",
      "js", "json", "md", "pdf", "php", "png", "pptx", "py", "rb", "tar", "tex",
      "ts", "txt", "webp", "xlsx", "xml", "zip"
    ];    
    const url = "https://api.nlpgraph.com/stage/api/brain/get_url_data";
    const apiKey = "325176a05bde66cf846db4bc164a0b83";
    const fileExtension = file.name.split('.').pop().toLowerCase();
    if (!allowedExtensions.includes(fileExtension)) {
      setErrorMessage("This file is not allowed.");
      return;
    }

    try {
      const payload = {
        title: file.name,
        file_url: filePath,
      };

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "api-key": apiKey,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data from the API");
      }
      const {isPaid} = await  getTenantInfo();
      await mapAPItoDB(tenantId,isPaid,"Dashboard_AI_Chat_Page","Hey_Brain_Get_URL_Data");

      const responseData = await response.json();
      console.log(responseData, "HeyBrain Response!"); // Handle the response data here
      const fileId = responseData.response.__file_id;

      if (fileId && selectedCompany!="company") {
        addFileInfo(fileId, filePath);
      }

      if( selectedCompany==="company"){
        handleFileClick({fileId})
      }

      setErrorMessage("");
    } catch (error) {
      console.error("Error", error);
    }
  };

  const handleFileClick = (fileInfo) => {
    setCurrentFileId(fileInfo.fileId);
    setChatOpen(true);
  };

  const addFileInfo = async (fileId, filePath) => {
    try {
      const result = await API.graphql(
        graphqlOperation(createChatFileCollection, {
          input: {
            tenantId: tenantId,
            companyId: selectedCompany,
            fileId: fileId,
            fileName: file.name,
            fileS3Path: filePath,
          },
        })
      );

      console.log(result);
      setSelectedCompany(null)
      toast.success("File Uploaded Successfully!");
    } catch (error) {
      console.log(error);
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleContinueWithoutAdding = () => {
    setSelectedCompany('company');
    handleOpenModal();
  };

  const handleChatClose = () => {
    setChatOpen(false);
    setSelectedCompany(null);
    setCurrentFileId(""); 
  };


  const getSummaryData = async () => {
    try {
      const s3 = new AWS.S3();
      const key = 'public/summary_' + tenantId + '.json'
      const data = await s3.getObject({ Bucket: "crediarc-content01853-production", Key: key,IfModifiedSince: new Date(0) }).promise();
      const fileData = data.Body.toString('utf-8');
      const summary = JSON.parse(fileData);

      for (let i = 0; i < summary.length; i++) {
        const row = summary[i];
        for (const key in row) {
          if (typeof row[key] === 'string') {
            row[key] = isNaN(parseFloat(row[key].replace(/,/g, ''))) ? row[key] : parseFloat(row[key].replace(/,/g, ''));
          }
        }
      }
      console.log(summary, "5252")
      setSummaryData(summary);
      return { summary }

    } catch (error) {
      console.log(error);
      return null;
    }
  }
  useEffect(()=>{
    selectTenantId()
  },[isAdmin,currentTenant])

  useEffect(()=>{
    if(!searchQuery) {
      setSearchResults([]);
      return;
    }else debouncedSearch(searchQuery);
},[searchQuery,companies,filteredCompanies])

const debouncedSearch = useCallback(
  debounce((query) => {
    if (query === '') {
      setSearchResults([]);
    } else {
      const searchSource = selectedOption === 1 ? filteredCompanies : companies;
      const filteredResults = searchSource.filter((company) =>
        company.name.toLowerCase().includes(query.toLowerCase())
      );
      setSearchResults(filteredResults);
    }
  }, 400), [filteredCompanies, companies,selectedOption]);



  useEffect(() => {
    const fetchCompaniesAndChatFiles = async () => {
      setLoading(true); 
      try {
        const result = await API.graphql(graphqlOperation(getCompaniesByView, {
          view: 1,
          limit: 1000,
          filter: {
            tenantId: {
              eq: tenantId,
            }
          }
        }));
        const allCompanies = result.data.getCompaniesByView.items;
  
        const chatFilesResult = await API.graphql(graphqlOperation(listChatFileCollections, {
          filter: {
            tenantId: { eq: tenantId }
          }
        }));
        const chatFileCollections = chatFilesResult.data.listChatFileCollections.items;
  
        const companyIdsWithChatFiles = [...new Set(chatFileCollections.map(collection => collection.companyId))];
  
        const filtered = allCompanies.filter(company => companyIdsWithChatFiles.includes(company.id));
  
        setFilteredCompanies(filtered);
        setCompanies(allCompanies);
      } catch (error) {
        console.error("Error fetching companies or chat files:", error);
        toast.error("Error fetching data. Please try again later.");
      } finally {
        setLoading(false); 
      }
    };
  
    if (tenantId) {
      fetchCompaniesAndChatFiles();
      getSummaryData();
    }
  
  }, [tenantId]);

const handleOpenModal = () => setIsModalOpen(true);
const handleCloseModal = () => {setIsModalOpen(false);setSelectedCompany(null);setIsUploading(false)}

  return (
      <div style={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center",width:"100%"}}>
        <div style={buttonsStyle}>
        <Button
        style={selectedOption === 1 ? selectedButtonStyle : unselectedButtonStyle}
        variant="contained"
        onClick={() => { setSearchQuery(""); setSelectedOption(1); }}
      >
        Search Existing Reports
      </Button>
      <Button
        style={selectedOption === 2 ? selectedButtonStyle : unselectedButtonStyle}
        variant="contained"
        onClick={() => { setSearchQuery(""); setSelectedOption(2); }}
      >
        Add Company Reports
      </Button>
      </div>
        {searchQuery && selectedOption===1 ? (
        <Box sx={{ mt: 2 }}>
              <List 
                sx={{
                  display: 'flex',
                  // justifyContent: "center",
                  gap: '10px',
                  flexWrap:"wrap"
                }} 
              >
                {searchResults.map((company, index) => (
                  <div style={{ margin: "0 5px 0 5px" }} xs={12} sm={6} md={4} lg={4} key={company._id}>
                    <CompanyCard
                      key={company._id}
                      company={company}
                      summaryData={summaryData}
                      getSummaryData={getSummaryData}
                      tenantId={tenantId}
                    />
                  </div>
                ))}
              </List>
      </Box>
      ) : (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      {selectedOption === 1 && (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center",marginTop:"10px" }}>
          {loading ? (
            <CircularProgress />
          ) : (
              <Box sx={{ mt: 2 }}>
                    <List 
                      sx={{
                        display: 'flex',
                        justifyContent: "center",
                        gap: '10px',
                        flexWrap:"wrap"
                      }} 
                    >
                      {filteredCompanies.map((company) => (
                        <div style={{ margin: "0 5px 0 5px" }} xs={12} sm={6} md={4} lg={4} key={company._id}>
                          <CompanyCard
                            key={company._id}
                            company={company}
                            summaryData={summaryData}
                            getSummaryData={getSummaryData}
                            tenantId={tenantId}
                          />
                        </div>
                      ))}
                    </List>
              </Box>
          )}
        </div>
      )}
      {selectedOption === 2 && (
      <Box sx={{display:"flex",gap:"10px",flexDirection:"column"}}>
      {selectedCompany && (
        <Modal
  open={isModalOpen}
  onClose={handleCloseModal}
  closeAfterTransition
  BackdropComponent={Backdrop}
  BackdropProps={{
    timeout: 500,
  }}
>
  <Box
    style={{
      height: '180px',
      width: '370px',
      background: '#FAFAFC 0% 0% no-repeat padding-box',
      boxShadow: 'inset 0px 1px 3px #0000001A, 0px 3px 6px #00000029',
      borderRadius: '8px',
      opacity: 1,
      padding: '25px',
      margin: 'auto',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    }}
  >
    {isUploading ? (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <CircularProgress />
      </div>
    ) : (
      <>
        <Typography
          style={{
            width: '290px',
            height: '24px',
            fontSize: '20px',
            fontWeight: '500',
            color: '#1A2A56',
          }}
        >
          Upload File
        </Typography>
        <Typography
          onClick={handleButtonClick}
          style={{
            paddingTop: '20px',
            textTransform: 'none',
            color: '#5186EC',
            fontSize: '16px',
            fontWeight: '200',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
        >
          Click here to select a file
        </Typography>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            borderBottom: '1px solid #777A82',
            width: '320px',
            paddingBottom: '10px',
            paddingTop: '25px',
          }}
        >
          {file === null && (
            <Typography style={textStyle}>No file selected!</Typography>
          )}
          {file && <Typography style={textStyle}>{file.name}</Typography>}
          {errorMessage && (
            <Typography style={{ color: 'red' }}>{errorMessage}</Typography>
          )}
          <Button
            onClick={uploadFile}
            disabled={!selectedCompany}
            style={{
              width: '100px',
              height: '30x',
              background: !selectedCompany ? '#f0f0f0' : '#5186EC',
              borderRadius: '8px',
              opacity: 1,
              color: !selectedCompany ? 'black' : 'white',
              fontWeight: '200',
              textTransform: 'none',
              cursor: !selectedCompany ? 'not-allowed' : 'pointer',
              pointerEvents: !selectedCompany ? 'none' : 'auto',
            }}
          >
            Upload File
          </Button>
        </div>
      </>
    )}
  </Box>
</Modal>
        )}
      {searchQuery ? (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
         {loading ? (
          <div style={{marginBottom:"20px",marginTop:"50px"}}><CircularProgress  /></div>
        ) : (
        <Box sx={{ mt: 2 }}>
              <List 
                sx={{
                  display: 'flex',
                  // justifyContent: "center",
                  gap: '10px',
                  flexWrap:"wrap"
                }} 
              >
                {searchResults.map((company) => (
                  <div style={{ margin: "0 5px 0 5px" }} xs={12} sm={6} md={4} lg={4} key={company._id}>
                    <CompanyCard
                      key={company._id}
                      company={company}
                      summaryData={summaryData}
                      getSummaryData={getSummaryData}
                      tenantId={tenantId}
                      selectable={true}
                      setSelectedCompany={setSelectedCompany}
                      handleOpenModal={handleOpenModal}
                    />
                  </div>
                ))}
              </List>
      </Box>)}
      </div>) : null}
      {searchQuery && searchResults.length === 0 && (
              <div style={{ marginTop: '20px', display: 'flex', gap: '20px' }}>
                <Button
                  style={{
                    ...buttonStyle,
                    backgroundColor: '#5186EC',
                  }}
                  variant="contained"
                  onClick={() => {
                    setOpenAddCompanyModal(true)
                  }}
                >
                  Add Company
                </Button>
                <Button
                  style={{
                    ...buttonStyle,
                    backgroundColor: '#5186EC',
                  }}
                  variant="contained"
                  onClick={handleContinueWithoutAdding}
                >
                  Continue Without Adding
                </Button>
              </div>
            )}
      {!searchQuery && (<Typography sx={{ fontSize: "26px", fontWeight: "500", color: "#1A2A56",marginLeft:"5px" }}>Start Searching By Company Name</Typography>)}
      {currentFileId ? (
          <Modal
            open={chatOpen}
            onClose={handleChatClose}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box sx={{ width: "80%", display: "flex" }}>
              <iframe
                title="Heybrain Chatbot"
                src={`https://dty0xa7tzq24v.cloudfront.net?api_key=325176a05bde66cf846db4bc164a0b83&fileId=${currentFileId}`}
                style={{
                  width: "100%",
                  height: "90vh",
                  border: "none",
                  borderRadius: 5,
                }}
              />
              <Box sx={{ position: "absolute", top: 20, right: 20 }}>
                <Button variant="contained" onClick={handleChatClose}>
                  X
                </Button>
              </Box>
            </Box>
          </Modal>
        ) :null}
    </Box>
      )}
    </Box>)}
    </div>
  );
};

export  {AIChatPage}