import React, { useEffect, useState } from "react";
import { API, graphqlOperation, Amplify } from "aws-amplify";
import {
  Autocomplete,
  Box,
  Button,
  Modal,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { companiesByName, getDashboardPreferences, getTenant, listTenants } from "./graphql/queries";
import { getTenantIdAuth0 } from "./authUtils";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  createAlert,
  updateCompany,
  updateDashboardPreferences,
  updateNewCompanyRecord,
  updateTenant,
} from "./graphql/mutations";
import {
  DataGridPremium,
  GridToolbar,
  GridRowModes,
  GridRowEditStopReasons,
  GridActionsCellItem,
} from "@mui/x-data-grid-premium";
import awsExports from "./aws-exports";
import AddCounterModal from "./AddCounterModal";
import { toast } from "react-toastify";
import TenantSchedulerModal from "./TenantSchedulerModal"; 
import AWS from "aws-sdk";
import dayjs from "dayjs";
import { TableEnum } from "./utils/GraphQLHelper/TableEnum";

Amplify.configure(awsExports);
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});



function UpdateAgingDaysModal({
  open,
  onClose,
  tenantId
}) {
  const [agingDays, setAgingDays] = useState({ age1: '', age2: '', age3: '' });
  const [loading, setLoading] = useState(false);
  const [isAREnabled, setIsAREnabled] = useState(false);

  useEffect(() => {
    if (tenantId) {
      fetchAgingDays();
      fetchAREnabledStatus();
    }
  }, [tenantId]);

  const removeText = (value) => {
    return value.startsWith('AR Balance Aging ') 
      ? value.replace('AR Balance Aging ', '') 
      : value;
  }

  const fetchAgingDays = async () => {
    try {
      const result = await API.graphql(
        graphqlOperation(getDashboardPreferences, {
          tenantId,
          feature: TableEnum.COLLECTION_TABLE,
        })
      );
  
      const preferences = JSON.parse(result.data.getDashboardPreferences.preferences);
      
      const agingDaysFromPrefs = {
        age1:removeText(preferences.age1),
        age2:removeText(preferences.age2),
        age3:removeText(preferences.age3),
      };
      
  
      setAgingDays(agingDaysFromPrefs);
      console.log("Aging days:", agingDaysFromPrefs);
    } catch (error) {
      console.error("Error fetching aging days:", error);
    }
  };

  const fetchAREnabledStatus = async () => {
    try {
      const result = await API.graphql(
        graphqlOperation(getTenant, {
          id: tenantId,
        })
      );
      const enabled = result.data?.getTenant?.isAREnabled; 
      console.log("enabled",enabled) 
      setIsAREnabled(enabled);
    } catch (error) {
      console.error("Error fetching AR enabled status:", error);
    }
  };

  const handleClose = () => {
    onClose();
  };

  const handleChange = (key) => (event) => {
    setAgingDays({ ...agingDays, [key]: event.target.value });
  };
  const handleToggleChange = async (event) => {
    const newStatus = event.target.checked;
    setIsAREnabled(newStatus);
  };

  const handleSubmitAgingDays = async () => {
    try {
      setLoading(true);
      let formattedPreferences = {
        age1: `AR Balance Aging ${agingDays.age1}`,
        age2: `AR Balance Aging ${agingDays.age2}`,
        age3: `AR Balance Aging ${agingDays.age3}`,
      };
      if(!agingDays.age1 && !agingDays.age2 && !agingDays.age3) formattedPreferences = {}
  
      await API.graphql(
        graphqlOperation(updateDashboardPreferences, {
          input: {
            tenantId,
            feature: TableEnum.COLLECTION_TABLE,
            preferences: JSON.stringify(formattedPreferences),
          },
        })
      );
      await API.graphql(
        graphqlOperation(updateTenant, {
          input: {
            id:tenantId,
            isAREnabled
          },
        })
      );
      setLoading(false)
      handleClose()
      toast.success("AR Settings Updated Successfully!");
    } catch (error) {
      console.error("Error saving aging days:", error);
      setLoading(false)
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      sx={{
        backdropFilter: "blur(2px)",
        backgroundColor: "rgb(255, 255, 255, 0.5)"
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          border: "none"
        }}
        onClick={(event) => {
          if (event.target === event.currentTarget) {
            handleClose();
          }
        }}
      >
        <Box
          sx={{
            width: 400,
            p: 4,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            borderRadius: "20px",
            boxShadow: "inset 0px 1px 3px #0000001A, 0px 3px 6px #00000029",
            display: 'flex',
            flexDirection: "column",
            gap: "10px"
          }}
        >
          <Typography id="modal-title" gutterBottom>
            Age 1
          </Typography>
          <TextField
            label="Age 1"
            variant="outlined"
            value={agingDays.age1}
            onChange={handleChange('age1')}
            fullWidth
          />
          <Typography id="modal-title" gutterBottom>
            Age 2
          </Typography>
          <TextField
            label="Age 2"
            variant="outlined"
            value={agingDays.age2}
            onChange={handleChange('age2')}
            fullWidth
          />
          <Typography id="modal-title" gutterBottom>
            Age 3
          </Typography>
          <TextField
            label="Age 3"
            variant="outlined"
            value={agingDays.age3}
            onChange={handleChange('age3')}
            fullWidth
          />
          <Typography id="modal-title" gutterBottom>
            Enable AR
          </Typography>
          <Switch
            checked={isAREnabled}
            onChange={handleToggleChange}
          />
          <Button
            variant="contained"
            sx={{ mt: 2 }}
            onClick={handleSubmitAgingDays}
            disabled={loading}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

function AdminPage() {
  const [rows, setRows] = useState([]);
  const [columnsArray, setColumnsArray] = useState([]);
  const [tenant, setTenant] = useState();
  const [tenantId, setTenantId] = useState();
  const [tenantsArray, setTenantsArray] = useState([]);
  const [showDataGrid, setShowDataGrid] = useState(false);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [toggleS3, setToggleS3] = useState(undefined);
  const [recommendedLimitClicked, setRecommendedLimitClicked] = useState(false);
  const [combinedScoreClicked, setCombinedScoreClicked] = useState(false);
  const [underwritingReasonClicked, setUnderwritingReasonClicked] =
    useState(false);
  const [prevRecommendedLimit, setPrevRecommendedLimit] = useState("");
  const [prevCombinedScore, setPrevCombinedScore] = useState("");
  const [prevUnderwritingReason, setPrevUnderwritingReason] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSchedulerModalOpen, setIsSchedulerModal] = useState(false);
  const [isUpdateAgingDaysModalOpen, setIsUpdateAgingDaysModalOpen] = useState(false);
  const [counterValue, setCounterValue] = useState("");
  const [currentCounterValue, setCurrentCounterValue] = useState("");
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    "Company Name": true, 
    "Requested Limit": true, 
    "Risk Segment": true, 
    "Failure Score": true, 
    "Alternative Score": true, 
    "Combined Score": true, 
    "Last Update Time": true, 
    "Recommended Limit": true, 
    "Current Recommendation Type": true,
    "# Questions":true, 
    "Underwriting Reason":true, 
  });

  let columnDefinition = [];
  let data = [];
  let columns;

  const getData = async () => {
    try {
      console.log("tenant in admin pagee", tenantId);
      const key = "public/summary_" + tenantId + ".json";
      const s3 = new AWS.S3();
      // s3.listBuckets((err, data) => {
      //   if (err) {
      //     console.log("Error listing S3 buckets:", err);
      //   } else {
      //     console.log("Successfully listed S3 buckets:", data.Buckets);
      //   }
      // });
      AWS.config.getCredentials((err) => {
        if (err) {
          console.log("Error retrieving AWS credentials:", err);
        } else {
          console.log("AWS credentials are valid and set up correctly.");
        }
      });
      const data = await s3
        .getObject({
          Bucket: "crediarc-content01853-production",
          Key: key,
          IfModifiedSince: new Date(0),
        })
        .promise();
      console.log("upload json data ", data);
      const fileData = data.Body.toString("utf-8");
      const summary = JSON.parse(fileData);

      // summary is a list of objects. It can have strings which are formatted numbers (e.g. "1,000,000")
      // or numbers (e.g. 1000000). We need to convert the strings to numbers.
      for (let i = 0; i < summary.length; i++) {
        const row = summary[i];
        for (const key in row) {
          if (typeof row[key] === "string") {
            row[key] = isNaN(parseFloat(row[key].replace(/,/g, "")))
              ? row[key]
              : parseFloat(row[key].replace(/,/g, ""));
          }
        }
      }
      return { summary };
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const fetchDataForGrid = async () => {
    try {
      data = await getData();
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    const dataWithIds = data.summary?.map((obj, index) => {
      const { id, ...rest } = obj;

      return {
        id: index + 1,
        ...rest,
      };
    });
    setRows(dataWithIds);
  };

  const actionsColumnDefinition = {
    field: "actions",
    type: "actions",
    headerName: "Actions",
    hide: false,
    width: 100,
    cellClassName: "actions",
    getActions: ({ id, row }) => {
      const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

      if (isInEditMode) {
        return [
          <GridActionsCellItem
            icon={<SaveIcon />}
            label="Save"
            sx={{
              color: "primary.main",
            }}
            onClick={handleSaveClick(id)}
          />,
          <GridActionsCellItem
            icon={<CancelIcon />}
            label="Cancel"
            className="textPrimary"
            onClick={handleCancelClick(id)}
            color="inherit"
          />,
        ];
      }

      return [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          className="textPrimary"
          onClick={handleEditClick(id)}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={handleDeleteClick(id)}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={<ChangeCircleOutlinedIcon />}
          label="Type Change"
          onClick={() => handleRecommendationTypeChange(id)}
          color="inherit"
          disabled={
            !(row["Recommendation Manual"] && row["Recommendation Auto"])
          }
        />,
      ];
    },
  };

  const CreateColumnHeader = (headerTitle) => {
    return (
      <Typography variant="caption" fontWeight="bold">
        {headerTitle}
      </Typography>
    );
  };

  const uploadJsonToS3 = async () => {
    try {
      const jsonData = rows;
      const jsonString = JSON.stringify(jsonData);
      const key = "public/summary_" + tenantId + ".json";
      const s3 = new AWS.S3();

      const result = await s3
        .putObject({
          Bucket: 'crediarc-content01853-production',
          Key: key,
          Body: jsonString,
          ContentType: "application/json",
        })
        .promise();

      console.log("File uploaded successfully", result);
      toast.success("Summary File Updated Successfully!");
    } catch (error) {
      console.error("Error uploading file", error);
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleTenantDashboardClick = async () => {
    window.open(`/tenant-dashboard/${tenantId}`, "_blank");
  };
  const handleUpdateAgingDays = async () => {
    setIsUpdateAgingDaysModalOpen(true)
  };

  const syncCompanyRecord = async (companyId, row) => {
    let result = await API.graphql(
      graphqlOperation(updateNewCompanyRecord, {
        input: {
          tenantId: tenantId,
          id: companyId,
          summary: JSON.stringify(row),
        },
      })
    );

    console.log("Company Record Updated", result);
  };

  const handleSave = async (row) => {
    try {
      let companyNameValue = row["Company Name"];
      let result = await API.graphql(
        graphqlOperation(companiesByName, {
          name: companyNameValue,
          limit: 1,
          filter: {
            tenantId: {
              eq: tenantId,
            },
          },
        })
      );
      let company = null;
      let requestedCreditLimit = row["Requested Limit"];
      company = result.data.companiesByName.items[0];
      await syncCompanyRecord(company.id, row);

      await API.graphql(
        graphqlOperation(updateCompany, {
          input: {
            tenantId: tenantId,
            id: company.id,
            requestedCreditLimit: parseFloat(requestedCreditLimit),
            view: 1,
          },
        })
      );
      console.log("updated");
    } catch (error) {
      console.error("error while saving file", error);
    }
  };

  const handleSaveClick = (id) => () => {
    const jsonData = rows;
    const foundItem = rows.find((item) => item.id === id);
    handleSave(foundItem);
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const CreateAlertItem = async (message, type, companyName) => {
    try {
      const result = await API.graphql(
        graphqlOperation(createAlert, {
          input: {
            tenantId: tenantId,
            message: message,
            isRead: false,
            type: type,
            companyInfo: JSON.stringify({ companyName: companyName }),
          },
        })
      );
      console.log("created data alert!");
    } catch (error) {
      console.error("Error creating alert", error);
    }
  };

  const handleCellClick = (id) => {
    if (id.field === "Recommended Limit") {
      setRecommendedLimitClicked(true);
      setPrevRecommendedLimit(id.value);
    }
    if (id.field === "Underwriting Reason") {
      setUnderwritingReasonClicked(true);
      setPrevUnderwritingReason(id.value);
    }
    if (id.field === "Combined Score") {
      setCombinedScoreClicked(true);
      setPrevCombinedScore(id.value);
    }
  };
  const processRowUpdate = (newRow) => {
    if (recommendedLimitClicked) {
      let message =
        "Recommended Limit updated from " +
        prevRecommendedLimit +
        " to " +
        newRow["Recommended Limit"] +
        " for " +
        newRow["Company Name"];
        if (prevRecommendedLimit !== newRow["Recommended Limit"]) {
          CreateAlertItem(message, "Status Change", newRow["Company Name"]);
        }
      newRow["Evaluation Status"] = "Revised";
      setRecommendedLimitClicked(false);
      setPrevRecommendedLimit(null);
    }

    if (combinedScoreClicked) {
      let message =
        "Combined Score updated from " +
        prevCombinedScore +
        " to " +
        newRow["Combined Score"] +
        " for " +
        newRow["Company Name"];
        if (prevCombinedScore !== newRow["Combined Score"]) {
          CreateAlertItem(message, "Status Change", newRow["Company Name"]);
        }
      setPrevCombinedScore(null);
      setCombinedScoreClicked(false);
    }

    if (underwritingReasonClicked) {
      let message =
        "Underwriting Reason updated from " +
        prevUnderwritingReason +
        " to " +
        newRow["Underwriting Reason"] +
        " for " +
        newRow["Company Name"];
        if (prevUnderwritingReason !== newRow["Underwriting Reason"]) {
          CreateAlertItem(message, "Update", newRow["Company Name"]);
        }
      setPrevUnderwritingReason(null);
      setUnderwritingReasonClicked(false);
    }

    const manualObj = {
      "Type Created At": dayjs().format("ddd, DD MMM YYYY HH:mm:ss [GMT]"),
      "Recommended Net": newRow["Recommended Net"] || null,
      "Recommended Limit": newRow["Recommended Limit"] || null,
      "Underwriting Reason": newRow["Underwriting Reason"] || null,
    };

    const updatedRow = {
      ...newRow,
      "Last Update Time": dayjs().format("ddd, DD MMM YYYY HH:mm:ss [GMT]"),
      "Current Recommendation Type": "Manual",
      "Recommendation Manual": manualObj,
      "Type Last Updated At": dayjs().format("ddd, DD MMM YYYY HH:mm:ss [GMT]"),
    };

    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    console.log("Process Row Update", updatedRow);
    setToggleS3(!toggleS3);
    handleSave(updatedRow);
    return updatedRow;
  };
  const handleDeleteClick = (id) => () => {
    const isConfirmed = window.confirm("Are you sure you want to delete?");
    if (isConfirmed) {
      setRows(rows.filter((row) => row.id !== id));
      setToggleS3(!toggleS3);
    }
  };

  const handleRecommendationTypeChange = (id) => {
    const rowData = rows.find((item) => item.id === id);
    const currentType = rowData["Current Recommendation Type"];

    const alertStatement = `Do you want to change Recommendation Type from ${currentType} to ${
      currentType === "Manual" ? "Auto" : "Manual"
    } ?`;

    const isConfirmed = window.confirm(alertStatement);

    if (isConfirmed) {
      let updatedRow;

      if (currentType === "Manual") {
        updatedRow = {
          ...rowData,
          "Current Recommendation Type": "Auto",
          "Underwriting Reason":
            rowData["Recommendation Auto"]["Underwriting Reason"],
          "Recommended Net": rowData["Recommendation Auto"]["Recommended Net"],
          "Recommended Limit":
            rowData["Recommendation Auto"]["Recommended Limit"],
          "Type Last Updated At": dayjs().format(
            "ddd, DD MMM YYYY HH:mm:ss [GMT]"
          ),
        };
      } else {
        updatedRow = {
          ...rowData,
          "Current Recommendation Type": "Manual",
          "Underwriting Reason":
            rowData["Recommendation Manual"]["Underwriting Reason"],
          "Recommended Net":
            rowData["Recommendation Manual"]["Recommended Net"],
          "Recommended Limit":
            rowData["Recommendation Manual"]["Recommended Limit"],
          "Type Last Updated At": dayjs().format(
            "ddd, DD MMM YYYY HH:mm:ss [GMT]"
          ),
        };
      }

      const updatedList = rows.map((item) =>
        item.id === id ? updatedRow : item
      );
      setRows(updatedList);
      setToggleS3(!toggleS3);
    }
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  function getUniqueKeys(arrayOfObjects) {
    let uniqueKeys = new Set();
    arrayOfObjects?.forEach((obj) => {
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          uniqueKeys.add(key);
        }
      }
    });
    return Array.from(uniqueKeys);
  }

  const handleChange = (newValue) => {
    const selectedTenant = newValue ? newValue : undefined;
    setTenant(newValue);
    if (selectedTenant !== undefined) {
      setTenantId(newValue.id);
      setCurrentCounterValue(newValue.counter);
    } else {
      setShowDataGrid(false);
      setTenantId(undefined);
      console.error("Selected tenant not found");
    }
  };

  useEffect(() => {
    if (tenantId !== undefined) {
      fetchDataForGrid();
    }
  }, [tenantId]);

  const getTenant = async () => {
    try {
      let nextToken = null;
      let allTenants = [];
      do {
        let result = await API.graphql(
          graphqlOperation(listTenants, { nextToken: nextToken })
        );
        allTenants = allTenants.concat(result.data.listTenants.items);
        nextToken = result.data.listTenants.nextToken;
      } while (nextToken);

      let adminTenant = await getTenantIdAuth0();
      const tenantNames = {};
      let filteredTenants = allTenants.filter((tenant) => {
        tenantNames[tenant.id] = tenant.name;
        return tenant.id !== adminTenant;
      });
      const stringNames = JSON.stringify(tenantNames);
      localStorage.setItem("tenantNames", stringNames);
      setTenantsArray(filteredTenants);
    } catch (error) {
      console.error("Error fetching tenants:", error);
    }
  };

  useEffect(() => {
    getTenant();
  }, []);

  useEffect(() => {
    if (rows && rows.length > 0) {
      columns = getUniqueKeys(rows).filter(
        (column) =>
          column !== "" &&
          column !== "id" &&
          column !== "Recommendation Manual" &&
          column !== "Recommendation Auto"
      );
      columns.forEach((column) => {
        const definition = {
          field: column,
          headerName: column === "Combined Score" ? "Arc Score" : column,
          flex: 1,
          hide: false,
          renderHeader: (params) => {
            return CreateColumnHeader(params.colDef.headerName);
          },
          renderCell: (params) => {
            if (column === "Company Name") {
              return (
                <div style={{ cursor: "pointer" }}>
                  {params?.row["Company Name"]}
                </div>
              );
            }
          },
        };
        if (typeof rows[0][column] === "number") {
          definition.type = "number";
        }
        if (column === "Requested Limit") {
          definition.editable = true;
        }
        if (column === "Current Net") {
          definition.editable = true;
        }
        if (column === "Current Limit") {
          definition.editable = true;
        }
        if (column === "Recommended Limit") {
          definition.editable = true;
        }
        if (column === "Underwriting Reason") {
          definition.editable = true;
        }
        if (column === "Risk Segment") {
          definition.editable = true;
        }
        if (column === "Failure Score") {
          definition.editable = true;
        }
        if (column === "Delinquency Score") {
          definition.editable = true;
        }
        if (column === "Alternative Score") {
          definition.editable = true;
        }
        if (column === "Combined Score") {
          definition.editable = true;
        }
        if (column === "Country Score") {
          definition.editable = true;
        }
        if (column === "General Risk Score") {
          definition.editable = true;
        }
        if (column === "Legal Score") {
          definition.editable = true;
        }
        if (column === "Impact") {
          definition.editable = true;
        }
        if (column === "Recommended Net") {
          definition.editable = true;
        }

        columnDefinition.push(definition);
        if (!columnVisibilityModel.hasOwnProperty(column)) {
          setColumnVisibilityModel((prevModel) => ({
            ...prevModel,
            [column]: false, 
          }));
        }
      });
      columnDefinition.push(actionsColumnDefinition);
      setColumnsArray(columnDefinition);
      setShowDataGrid(true);
    }
  }, [rows, rowModesModel]);

  useEffect(() => {
    if (toggleS3 !== undefined) {
      uploadJsonToS3();
    }
  }, [!toggleS3]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleOpenSchedulerModal = () => {
    setIsSchedulerModal(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleCloseUpdateAgingDaysModal = () => {
    setIsUpdateAgingDaysModalOpen(false);
  };

  const handleCloseSchedulerModal = () => {
    setIsSchedulerModal(false);
  };

  const handleSaveCounter = async () => {
    try {
      const input = { id: tenantId, counter: counterValue };
      const res = await API.graphql(graphqlOperation(updateTenant, { input }));
      setCurrentCounterValue(res.data.updateTenant.counter);
      getTenant();
      toast.success("Counter Reset Successful!");
    } catch (error) {
      console.error("Error updating tenant counter:", error);
    }
    console.log("Counter value saved:", counterValue);
    setIsModalOpen(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <FormControl>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Autocomplete
            size="small"
            sx={{ width: 250, my: 5 }}
            value={tenant}
            onChange={(event, newValue) => handleChange(newValue)}
            options={tenantsArray}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Selected Tenant"
                variant="outlined"
              />
            )}
          />
          <Button
            disabled={!tenant}
            onClick={handleTenantDashboardClick}
            variant="contained"
            sx={{ marginLeft: 1 }}
          >
            Tenant Dashboard <OpenInNewIcon sx={{ marginLeft: 0.5 }} />
          </Button>
          <Button
            disabled={!tenant}
            onClick={handleUpdateAgingDays}
            variant="contained"
            sx={{ marginLeft: 1 }}
          >
            Update AR Settings
          </Button>
          <Button
            disabled={!tenant}
            onClick={handleOpenSchedulerModal} // Define handleOpenModal function
            variant="contained"
            sx={{ marginLeft: 1 }} // Add some left margin for spacing
          >
            Schedule Trigger
          </Button>
          <Box>
            <Button
              disabled={!tenant}
              onClick={handleOpenModal} // Define handleOpenModal function
              variant="contained"
              sx={{ marginLeft: 1 }} // Add some left margin for spacing
            >
              Reset Counter
            </Button>
            {tenant ? (
              <Typography variant="body1" mt={1} textAlign={"center"}>
                {currentCounterValue > 0
                  ? `Current Counter Value: ${currentCounterValue}`
                  : "Exhausted"}
              </Typography>
            ) : null}
          </Box>
        </Box>
      </FormControl>

      {showDataGrid && (
        <div
          style={{
            width: "100%",
            height: "85vh",
            marginTop: 80,
          }}
        >
          <Typography variant="h5" mb={5}>
            Summary & Recommendations
          </Typography>
          <DataGridPremium
            sx={{
              ".Mui-hovered": {
                cursor: "pointer",
              },
            }}
            rows={rows}
            columns={columnsArray}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            onCellClick={handleCellClick}
            processRowUpdate={processRowUpdate}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 50 },
              },
            }}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
            slots={{
              toolbar: GridToolbar,
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            pageSizeOptions={[5, 10, 50]}
          />
        </div>
      )}
      <UpdateAgingDaysModal
      open={isUpdateAgingDaysModalOpen}
      onClose={handleCloseUpdateAgingDaysModal}
      tenantId={tenantId}
      />
      <AddCounterModal
        open={isModalOpen}
        onClose={handleCloseModal}
        counterValue={counterValue}
        setCounterValue={setCounterValue}
        handleSaveCounter={handleSaveCounter}
      />
      <TenantSchedulerModal
        open={isSchedulerModalOpen}
        onClose={handleCloseSchedulerModal}
        tenantId={tenantId}
      />
    </Box>
  );
}
export default AdminPage;
