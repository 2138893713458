import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Box,
  Grid,
} from "@mui/material";

export default function CompanyFinancialInfo({
  overview,
  balanceSheet,
  incomeStatement,
  company,
  linkedInJSON,
  dnBFinancialV2Data,
  dnbJsonData
  // financialStatement
}) {

  const formatNumber = (number) => {
    if (typeof number === "string" && (number.includes("US $") || number.includes("$") || number.includes("billion") || number.includes("million") || number.includes("thousand"))) {
      return number;
    }
    if (number >= 1e9) {
      return `US $${(number / 1e9).toFixed(2)} billion`;
    } else if (number >= 1e6) {
      return `US $${(number / 1e6).toFixed(2)} million`;
    } else if (number >= 1e3) {
      return `US $${(number / 1e3).toFixed(2)} thousand`;
    } else {
      return `US $${number}`;
    }
  };

  function isValid(value) {
    return (
      value !== null &&
      value !== undefined &&
      value !== "" &&
      !Number.isNaN(value)
    );
  }

  const WikiInfobox = company.wikipedia?.infobox
    ? JSON.parse(company.wikipedia.infobox)
    : {};

  const manualSource = company?.manualSource
    ? JSON.parse(company.manualSource)
    : {};
  var dataYear1 = "";
  var dataYear2 = "";
  var dataYear3 = "";
  var dataYear4 = "";
  // var year1 = "";
  var revenueYear1 = "";
  // var year2 = "";
  var revenueYear2 = "";
  // var year3 = "";
  var revenueYear3 = "";
  let funding = {};
  //1	Revenue - last 3 years
  //financial statement
  //AV.incomeStatement.annualReports.totalRevenue
  //dnb_financials.response.organization.latestFinancials.overview.salesRevenue
  //company_data.wikipedia.infobox.revenue

const getDnbRevenueByYear = (dnbFinancials, year) => {
  if (!dnbFinancials) return null;

  const previousFinancials = Array.isArray(dnbFinancials.previousFinancials) ? dnbFinancials.previousFinancials : [];
  const allFinancials = [dnbFinancials.latestFinancials, ...previousFinancials];

  const matchingFinancial = allFinancials.find(financial =>
    financial &&
    financial.financialStatementToDate &&
    financial.financialStatementToDate.includes(year) && 
    financial.currency === "USD" 
  );

  if (matchingFinancial && matchingFinancial.overview) {
    let salesRevenue = matchingFinancial.overview.salesRevenue;
    const units = matchingFinancial.units || "SingleUnits";

    if (units === "Million") {
      salesRevenue *= 1000000;
    } else if (units === "Billion") {
      salesRevenue *= 1000000000;
    }

    return salesRevenue;
  }

  const currentYear = new Date().getFullYear();
  const lastYear = currentYear - 1;

  if (year === lastYear && dnbJsonData) {
    const yearlyRevenue = dnbJsonData?.organization?.financials?.[0]?.yearlyRevenue || [];
    const usdRevenue = yearlyRevenue.find(item => item.currency === "USD");

    if (usdRevenue) {
      return usdRevenue.value;
    }
  }

  return null;
};
  


function getRevenue(allSources) {
  const { financialStatement, AV, companySearch, wiki, SF, dnbFinancials } = allSources;
  console.log("allsources financial info",allSources)
  let revenueByYear = {};
  const currentYear = new Date().getFullYear();
  const lastThreeYears = [currentYear - 1, currentYear - 2, currentYear - 3];
  const lastYear = currentYear - 1;

  const getRevenueFromSource = (source, year) => {
      if (year === lastYear && manualSource?.revenue) {
        return manualSource?.revenue;
      }
      if (!source) return null;
      switch (source) {
          case "financialStatement":
              return (
                  financialStatement?.annualReports?.find((rep) => rep?.fiscalDateEnding?.includes(year))?.totalRevenue ||
                  financialStatement?.annualReports?.find((rep) => rep?.fiscalDateEnding?.includes(year))?.salesRevenue ||
                  financialStatement?.annualReports?.find((rep) => rep?.fiscalDateEnding?.includes(year))?.netSales
              );
          case "AV":
              return AV?.incomeStatement?.annualReports?.find((rep) => rep?.fiscalDateEnding?.includes(year))?.totalRevenue;
          case "companySearch":
              return companySearch?.financial_data?.data?.revenue?.[year];
          case "wiki":
              if (wiki?.revenue?.manual === true) {
                  return wiki.revenue.value;
              } else if (wiki?.revenue) {
                  return wiki.revenue;
              }
              return null;
          case "SF":
              return SF?.financial_data?.[year];
          case "dnbFinancials":
              return getDnbRevenueByYear(dnbFinancials?.organization, year);
          default:
              return null;
      }
  };


  let avRevenues = [];
  let dnbRevenues = [];
  let avDataAvailable = true;


  lastThreeYears.forEach(year => {
      const avRevenue = getRevenueFromSource('AV', year);
      if (!avRevenue) avDataAvailable = false;
      avRevenues.push(avRevenue);
  });


  lastThreeYears.forEach(year => {
      const dnbRevenue = getRevenueFromSource('dnbFinancials', year);
      dnbRevenues.push(dnbRevenue);
  });


  if (!financialStatement && !avDataAvailable) {
      const revenues = [
          getRevenueFromSource('companySearch', currentYear - 1),
          getRevenueFromSource('SF', currentYear - 1),
          getRevenueFromSource('wiki', currentYear - 1)
      ].filter(Boolean);

      if (revenues.length > 0) {
          const maxRevenue = Math.max(...revenues);
          const minRevenue = Math.min(...revenues);
          
          if (maxRevenue / minRevenue > 1.25) {
              revenueByYear[currentYear - 1] = `Range: ${formatNumber(minRevenue)} - ${formatNumber(maxRevenue)}`;
          } else {
              revenueByYear[currentYear - 1] = formatNumber(revenues[0]);
          }
      } else {
        lastThreeYears.forEach((year, index) => {
          const dnbRevenue = dnbRevenues[index];
          if (dnbRevenue) {
              revenueByYear[year] = formatNumber(dnbRevenue);
          } else {
              revenueByYear[year] = null;
          }
      });
      }
      
  } else {
      lastThreeYears.forEach((year, index) => {
          const financialStatementRevenue = getRevenueFromSource('financialStatement', year);
          const avRevenue = avRevenues[index];

          if (financialStatementRevenue) {
              revenueByYear[year] = formatNumber(financialStatementRevenue);
          } else if (avRevenue) {
              revenueByYear[year] = formatNumber(avRevenue);
          } else if (year === currentYear - 1) {
              const otherRevenues = [
                  getRevenueFromSource('companySearch', year),
                  getRevenueFromSource('SF', year),
                  getRevenueFromSource('wiki', year)
              ].filter(Boolean);
              if (otherRevenues.length > 0) {
                  const maxRevenue = Math.max(...otherRevenues);
                  const minRevenue = Math.min(...otherRevenues);

                  if (maxRevenue / minRevenue > 1.25) {
                      revenueByYear[year] = `Range: ${formatNumber(minRevenue)} - ${formatNumber(maxRevenue)}`;
                  } else {
                      revenueByYear[year] = formatNumber(otherRevenues[0]);
                  }
              } else {
                  revenueByYear[year] = null;
              }
          }
      });
  }

  return revenueByYear;
}



// AV:{overview,balanceSheet,incomeStatement}
const revenues = getRevenue({ AV:{ overview, balanceSheet, incomeStatement }, wiki: WikiInfobox, dnbFinancials: dnBFinancialV2Data })
console.log("all revenues",revenues)
const currentYear = new Date().getFullYear();

// get the revenue for the last 3 years
revenueYear1 = revenues[currentYear - 1];
revenueYear2 = revenues[currentYear - 2]; 
revenueYear3 = revenues[currentYear - 3]; 

  // old implementation
  
  // if (incomeStatement) {
  //   dataYear1 = incomeStatement?.annualReports[0];
  //   year1 = dataYear1?.fiscalDateEnding.substring(0, 4);
  //   revenueYear1 = dataYear1?.totalRevenue;
  //   dataYear2 = incomeStatement?.annualReports[1];
  //   year2 = dataYear2?.fiscalDateEnding.substring(0, 4);
  //   revenueYear2 = dataYear2?.totalRevenue;
  //   dataYear3 = incomeStatement?.annualReports[2];
  //   year3 = dataYear3?.fiscalDateEnding.substring(0, 4);
  //   revenueYear3 = dataYear3?.totalRevenue;
  //   dataYear4 = incomeStatement?.annualReports[3];
  // }

  //2	Net profit - last year

  const financialStatement = null; // todo - replace with original financialStatement
  let netProfit = null;
  //financial statement.Net income
  if (financialStatement) {
    netProfit = financialStatement.NetIncome;
  }
  //AV.incomeStatement.annualReports.netIncome
  else if (incomeStatement) {
    netProfit = incomeStatement?.annualReports[0].netIncome;
  }
  //wikipedia.infobox.net_income	int
  else if (WikiInfobox) {
    netProfit = WikiInfobox.net_income;
  }

  //3	Market value
  let marketValue = null;
  //AV.overview.MarketCapitalization
  if (overview) {
    marketValue = overview.MarketCapitalization;
  } else if (financialStatement) { // financial statement(Financial variables).MarketCap
    marketValue = financialStatement.MarketCap;
  } else if(WikiInfobox) { // wikipedia.infobox.MarketCap
    marketValue = WikiInfobox.MarketCap;
  }

  //4	Operating income
  let operatingIncome = null;
  //financial statement
  if (financialStatement) {
    operatingIncome = financialStatement.OperatingProfit;
  }
  //AV.incomeStatement.annualReports.operatingIncome
  else if (incomeStatement) {
    operatingIncome = incomeStatement.annualReports[0].operatingIncome;
  }
  //wikipedia.infobox.operating_income
  else if (WikiInfobox) {
    operatingIncome = WikiInfobox.operating_income;
  }

  //5	Net interest expense
  let netInterestExpense = null;
  //financial statement
  if (financialStatement) {
    netInterestExpense = null;
  }
  //AV.incomeStatement.annualReports.interestExpense
  else if (incomeStatement) {
    netInterestExpense = incomeStatement.annualReports[0].interestExpense;
  }

  //6	EBITDA
  let ebitda = null;
  //financial statement
  if (financialStatement) {
    ebitda = null;
  }
  //AV.incomeStatement.annualReports.ebitda
  else if (incomeStatement) {
    ebitda = incomeStatement.annualReports[0].ebitda;
  }

  //7	Net income
  let netIncome = null;
  //financial statement
  if (financialStatement) {
    netIncome = 0;
  }
  //AV.incomeStatement.annualReports.netincome
  else if (incomeStatement) {
    netIncome = incomeStatement?.annualReports[0].netIncome;
  } 
  //response.organization.latestFinancials.overview.netIncome
  else if (dnBFinancialV2Data) {
    netIncome = dnBFinancialV2Data?.organization?.latestFinancials?.overview?.netIncome
  }
  //wikipedia.infobox.net_income
  else if (WikiInfobox) {
    netIncome = WikiInfobox?.net_income;
  }

  //8	Cash and cash equivalents
  let CashAndCashEquivalents = null;
  //financial statement
  if (financialStatement) {
    CashAndCashEquivalents = null;
  }
  //AV.balanceSheet.annualReports.cashAndCashEquivalentsAtCarryingValue
  else if (incomeStatement) {
    CashAndCashEquivalents =
      balanceSheet.annualReports[0].cashAndCashEquivalentsAtCarryingValue;
  }
  //response.organization.latestFinancials.overview.cashAndLiquidAssets
  else if(dnBFinancialV2Data){
    CashAndCashEquivalents = dnBFinancialV2Data?.organization?.latestFinancials?.overview?.cashAndLiquidAssets
  }
  //9	Total Assets
  let totalAssets = null;
  //financial statement
  if (financialStatement) {
    totalAssets = null;
  }
  //AV.balanceSheet.annualReports.totalAssets
  else if (balanceSheet) {
    totalAssets = balanceSheet?.annualReports[0]?.totalAssets;
  }
  //response.organization.latestFinancials.overview.totalAssets	wikipedia.infobox.assets
  else if (dnBFinancialV2Data) {
    totalAssets = dnBFinancialV2Data?.organization?.latestFinancials?.overview?.totalAssets
  } 
  else if(WikiInfobox){
    totalAssets = WikiInfobox?.totalAssets
  }

  //10	Total Assets - last year
  let totalAssetsLastYear = null;
  //financial statement
  if (financialStatement) {
    totalAssetsLastYear = null;
  }
  //AV.balanceSheet.annualReports.totalAssets
  else if (balanceSheet) {
    totalAssetsLastYear = balanceSheet.annualReports[1].totalAssets;
  }
  //response.organization.previousFinancials[0].overview.cashAndLiquidAssets
  else if (dnBFinancialV2Data) {
    totalAssetsLastYear = dnBFinancialV2Data?.organization?.previousFinancials[0]?.overview?.cashAndLiquidAssets // dnbFinancials.organization.previousFinancials[0].overview.totalAssets
  } 
  //11	Short term debt
  let ShortTermDebt = null;
  //financial statement
  if (financialStatement) {
    ShortTermDebt = null;
  }
  //AV.balanceSheet.annualReports.shortTermDebt
  else if (balanceSheet) {
    ShortTermDebt = balanceSheet.annualReports[0].shortTermDebt;
  }

  //12	Long term debt
  let longTermDebt = null;

  // Stock
  const ticker = linkedInJSON?.stock?.ticker;
  const tickerMarket = linkedInJSON?.stock?.market;
  //financial statement
  if (financialStatement) {
    longTermDebt = null;
  }
  //AV.balanceSheet.annualReports.longTermDebt
  else if (balanceSheet) {
    longTermDebt = balanceSheet.annualReports[0].longTermDebt;
  }
  //response.organization.latestFinancials.overview.longTermDebt
  else if (dnBFinancialV2Data) {
    longTermDebt = dnBFinancialV2Data?.organization?.latestFinancials?.overview?.longTermDebt;
  }
  //13	Total Liabilities
  let TotalLiabilities = null;
  //financial statement
  if (financialStatement) {
    TotalLiabilities = null;
  }
  //AV.balanceSheet.annualReports.totalLiabilities
  else if (balanceSheet) {
    TotalLiabilities = balanceSheet.annualReports[0].totalLiabilities;
  }
  //response.organization.latestFinancials.overview.totalLiabilities
  else if (dnBFinancialV2Data) {
    TotalLiabilities = dnBFinancialV2Data?.organization?.latestFinancials?.overview?.totalLiabilities;
  }
  //14	Equity
  let Equity = null;
  //financial statement
  if (financialStatement) {
    Equity = null;
  }
  //AV.balanceSheet.annualReports.totalShareholderEquity
  else if (balanceSheet) {
    Equity = balanceSheet.annualReports[0].totalShareholderEquity;
  }
  else if (WikiInfobox) {
    Equity = WikiInfobox?.equity
  }

  //15	Equity - last year
  let EquityLastYear = null;
  //financial statement
  if (financialStatement) {
    EquityLastYear = null;
  }
  //AV.balanceSheet.annualReports.totalShareholderEquity
  else if (balanceSheet) {
    EquityLastYear = balanceSheet.annualReports[1].totalShareholderEquity;
  }

  //16	report date
  let reportDate = null;
  //financial statement
  if (financialStatement) {
    reportDate = null;
  }
  //AV.incomeStatement.annualReports.fiscalDateEnding
  else if (incomeStatement) {
    reportDate = incomeStatement.annualReports[0]?.fiscalDateEnding;
  }
  //response.organization.latestFinancials.financialStatementToDate
  else if (dnBFinancialV2Data) {
    reportDate = dnBFinancialV2Data.organization.latestFinancials.financialStatementToDate
  }
// Funding todo - add zoominfo data here
  funding = linkedInJSON?.funding;
  const getUnit = (number) => {
    if (number >= 1e9) {
      return { unit: `USD Billion`, unitValue: 1e9 };
    } else if (number >= 1e6) {
      return { unit: `USD Million`, unitValue: 1e6 };
    } else if (number >= 1e3) {
      return { unit: `USD Thousand`, unitValue: 1e3 };
    } else {
      return { unit: `USD`, unitValue: 1 };
    }
  };

  function createData(name, TTM, year1, year2, year3, year4) {

    const min = Math.min(year1, year2, year3, year4)

    const {unit,unitValue} = getUnit(min);
    year1=year1/unitValue;
    year2=year2/unitValue;
    year3=year3/unitValue;
    year4=year4/unitValue;

    return { name: `${name} (${unit})`, TTM, year1, year2, year3, year4 };
  }

  //TTM //last 3 years
  const rows = [
    createData(
      "Total Revenue",
      "-",
      dataYear1?.totalRevenue,
      dataYear2?.totalRevenue,
      dataYear3?.totalRevenue,
      dataYear4?.totalRevenue
    ),
    createData(
      "Cost of Revenue",
      "-",
      dataYear1?.costOfRevenue,
      dataYear2?.costOfRevenue,
      dataYear3?.costOfRevenue,
      dataYear4?.costOfRevenue
    ),
    createData(
      "Gross Profit",
      "-",
      dataYear1?.grossProfit,
      dataYear2?.grossProfit,
      dataYear3?.grossProfit,
      dataYear4?.grossProfit
    ),
    createData(
      "Operating Expense",
      "-",
      dataYear1?.operatingExpenses,
      dataYear2?.operatingExpenses,
      dataYear3?.operatingExpenses,
      dataYear4?.operatingExpenses
    ),
  ];

  var dataYearQ1 = "";
  var dataYearQ2 = "";
  var dataYearQ3 = "";
  var dataYearQ4 = "";

  if (overview && incomeStatement && balanceSheet) {
    dataYearQ1 = incomeStatement?.quarterlyReports[0];
    dataYearQ2 = incomeStatement?.quarterlyReports[1];
    dataYearQ3 = incomeStatement?.quarterlyReports[2];
    dataYearQ4 = incomeStatement?.quarterlyReports[3];
  }

  const headingStyle = {
    // height: "16px",
    // textAlign: "left",
    fontSize: "15px",
    // padding: "20px",
  };

  const valueStyle = {
    // textAlign: "left",
    // height: "16px",
    fontSize: "14px",
    fontWeight: "600",
    // paddingLeft: "20px",
    // paddingTop: "5px",
    // marginTop: 30
  };

  function createQuaterData(name, Q1, Q2, Q3, Q4) {

        const min = Math.min(Q1, Q2, Q3, Q4)

        const {unit,unitValue} = getUnit(min);
        Q1 =Q1/unitValue;
        Q2=Q2/unitValue;
        Q3=Q3/unitValue;
        Q4=Q4/unitValue;

        return { name: `${name} (${unit})`, Q1, Q2, Q3, Q4 };
  }

  const QuaterRows = [
    createQuaterData(
      "Total Revenue",
      dataYearQ1?.totalRevenue,
      dataYearQ2?.totalRevenue,
      dataYearQ3?.totalRevenue,
      dataYearQ4?.totalRevenue
    ),
    createQuaterData(
      "Cost of Revenue",
      dataYearQ1?.costOfRevenue,
      dataYearQ2?.costOfRevenue,
      dataYearQ3?.costOfRevenue,
      dataYearQ4?.costOfRevenue
    ),
    createQuaterData(
      "Gross Profit",
      dataYearQ1?.grossProfit,
      dataYearQ2?.grossProfit,
      dataYearQ3?.grossProfit,
      dataYearQ4?.grossProfit
    ),
    createQuaterData(
      "Operating Expense",
      dataYearQ1?.operatingExpenses,
      dataYearQ2?.operatingExpenses,
      dataYearQ3?.operatingExpenses,
      dataYearQ4?.operatingExpenses
    ),
  ];


  const CustomIcon = () => {
    return (
      <Box
        sx={{
          width: 30,
          height: 30,
          backgroundColor: "#FFFFFF",
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.29)",
        }}
      >
        <ExpandMoreIcon style={{ color: "#5186EC" }} />
      </Box>
    );
  };

  //	Profitability ratios:
  // Operating income/ Revenue	calculated
  // Net income/ Revenue	calculated
  // ROA	calculated	Net income/ 50%*(total assets+total assets previous year)
  const assets = (totalAssets + totalAssetsLastYear) * 0.5;
  const ROA = ((netIncome / assets) * 100).toFixed(2);
  // ROE	calculated	Net income/ 50%*(equity+equity previous year)
  const ROE = ((netIncome / (0.5 * (Equity + EquityLastYear))) * 100).toFixed(
    2
  );

  const financeInfoVars = [
    revenueYear1,
    revenueYear2,
    revenueYear3,
    incomeStatement?.annualReports[0].operatingIncome,
    TotalLiabilities,
    reportDate,
    totalAssets,
    totalAssetsLastYear,
    marketValue,
    netInterestExpense,
    ebitda,
    netIncome,
    CashAndCashEquivalents,
    netProfit,
    Equity,
    EquityLastYear,
    ShortTermDebt,
    longTermDebt,
  ];

  const isAllFinanceInfoVarsInvalid = financeInfoVars.every(
    (v) => v === undefined || v === null || isNaN(v) || v === ""
  );

  const profitabilityRatioVars = [
    ROA,
    ROE,
    operatingIncome / revenueYear1,
    netIncome / revenueYear1,
  ];
  let isAllPRVarsInvalid = profitabilityRatioVars.every(
    (v) => v === undefined || v === null || isNaN(v) || v === ""
  );

  const flattenedValuesQR = QuaterRows.map((obj) => Object.values(obj)).flat();

  const quarterlyReviewVars = [
    dataYearQ1?.fiscalDateEnding,
    dataYearQ2?.fiscalDateEnding,
    dataYearQ3?.fiscalDateEnding,
    dataYearQ4?.fiscalDateEnding,
    ...flattenedValuesQR,
  ];

  let isAllQRVarsInvalid = quarterlyReviewVars.every(
    (v) => v === undefined || v === null || isNaN(v) || v === ""
  );

  const flattenedValuesYR = rows.map((obj) => Object.values(obj)).flat();

  const yearlyReviewVars = [
    dataYearQ1?.fiscalDateEnding,
    dataYearQ2?.fiscalDateEnding,
    dataYearQ3?.fiscalDateEnding,
    dataYearQ4?.fiscalDateEnding,
    ...flattenedValuesYR,
  ];

  let isAllYRVarsInvalid = yearlyReviewVars.every(
    (v) => v === undefined || v === null || isNaN(v) || v === ""
  );

  return (
    <div style={{ padding: "25px" }}>
      <Accordion
        width="90%"
        defaultExpanded
        style={{
          background: "#FAFAFC",
          boxShadow: "inset 0px 1px 3px #0000001A, 0px 3px 6px #00000029",
          borderRadius: "8px",
          opacity: "1",
        }}
      >
        <AccordionSummary
          expandIcon={<CustomIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography
            style={{
              width: "290px",
              height: "24px",
              fontSize: "20px",
              fontWeight: "500",
              color: "#1A2A56",
            }}
          >
            Finance Info
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            rowGap={2}
            display={isAllFinanceInfoVarsInvalid ? "none" : ""}
          >
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              display={
                revenueYear1 ? "" : "none"
              }
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  style={{
                    ...headingStyle,
                    color: "#777A82",
                  }}
                >
                  Revenue{" "}
                  {isValid(revenueYear3) && isValid(revenueYear2)
                    ? "(Last 3 years)"
                    : ""}
                  :
                </Typography>
                <Typography
                  style={{
                    color: "#2F3D63",
                    ...valueStyle,
                  }}
                >
                  {revenueYear3 && revenueYear2
                    ? `${revenueYear1}, ${revenueYear2}, ${revenueYear3}`
                    : revenueYear1}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              display={
                incomeStatement?.annualReports[0].operatingIncome ? "" : "none"
              }
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  style={{
                    ...headingStyle,
                    color: "#777A82",
                  }}
                >
                  Operating Income:
                </Typography>
                <Typography
                  style={{
                    ...valueStyle,
                    color: "#2F3D63",
                  }}
                >
                  {formatNumber(
                    incomeStatement?.annualReports[0].operatingIncome
                  )}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              display={TotalLiabilities ? "" : "none"}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  style={{
                    ...headingStyle,
                    color: "#777A82",
                  }}
                >
                  Total Liabilities:
                </Typography>
                <Typography
                  style={{
                    ...valueStyle,
                    color: "#2F3D63",
                  }}
                >
                  {formatNumber(TotalLiabilities)}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} md={4} display={reportDate ? "" : "none"}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  style={{
                    ...headingStyle,
                    color: "#777A82",
                  }}
                >
                  Report Date:
                </Typography>
                <Typography
                  style={{
                    ...valueStyle,
                    color: "#2F3D63",
                  }}
                >
                  {reportDate}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              display={totalAssets ? "" : "none"}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  style={{
                    ...headingStyle,
                    color: "#777A82",
                  }}
                >
                  Total Assets:
                </Typography>
                <Typography
                  style={{
                    ...valueStyle,
                    color: "#2F3D63",
                  }}
                >
                  {formatNumber(totalAssets)}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              display={totalAssetsLastYear ? "" : "none"}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  style={{
                    ...headingStyle,
                    color: "#777A82",
                  }}
                >
                  Total Assets (Last year):
                </Typography>
                <Typography
                  style={{
                    ...valueStyle,
                    color: "#2F3D63",
                  }}
                >
                  {formatNumber(totalAssetsLastYear)}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              display={marketValue ? "" : "none"}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  style={{
                    ...headingStyle,
                    color: "#777A82",
                  }}
                >
                  Market Value:
                </Typography>
                <Typography
                  style={{
                    ...valueStyle,
                    color: "#2F3D63",
                  }}
                >
                  {formatNumber(marketValue)}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              display={netInterestExpense ? "" : "none"}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  style={{
                    ...headingStyle,
                    color: "#777A82",
                  }}
                >
                  Net Interest Expense:
                </Typography>
                <Typography
                  style={{
                    ...valueStyle,
                    color: "#2F3D63",
                  }}
                >
                  {formatNumber(netInterestExpense)}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              display={ebitda ? "block" : "none"}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  style={{
                    ...headingStyle,
                    color: "#777A82",
                  }}
                >
                  EBITDA:
                </Typography>
                <Typography
                  style={{
                    ...valueStyle,
                    color: "#2F3D63",
                  }}
                >
                  {formatNumber(ebitda)}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} md={4} display={netIncome ? "" : "none"}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  style={{
                    ...headingStyle,
                    color: "#777A82",
                  }}
                >
                  Net Income:
                </Typography>
                <Typography
                  style={{
                    ...valueStyle,
                    color: "#2F3D63",
                  }}
                >
                  {formatNumber(netIncome)}
                </Typography>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              display={CashAndCashEquivalents ? "" : "none"}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  style={{
                    ...headingStyle,
                    color: "#777A82",
                  }}
                >
                  Cash And Cash Equivalents:
                </Typography>
                <Typography
                  style={{
                    ...valueStyle,
                    color: "#2F3D63",
                  }}
                >
                  {formatNumber(CashAndCashEquivalents)}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} md={4} display={netProfit ? "" : "none"}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  style={{
                    ...headingStyle,
                    color: "#777A82",
                  }}
                >
                  Net Profit:
                </Typography>
                <Typography
                  style={{
                    ...valueStyle,
                    color: "#2F3D63",
                  }}
                >
                  {formatNumber(netProfit)}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={4} md={4} display={Equity ? "" : "none"}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  style={{
                    ...headingStyle,
                    color: "#777A82",
                  }}
                >
                  Equity:
                </Typography>
                <Typography
                  style={{
                    ...valueStyle,
                    color: "#2F3D63",
                  }}
                >
                  {formatNumber(Equity)}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              display={EquityLastYear ? "" : "none"}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  style={{
                    ...headingStyle,
                    color: "#777A82",
                  }}
                >
                  Equity (Last year):
                </Typography>
                <Typography
                  style={{
                    ...valueStyle,
                    color: "#2F3D63",
                  }}
                >
                  {formatNumber(EquityLastYear)}
                </Typography>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              display={ShortTermDebt ? "" : "none"}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  style={{
                    ...headingStyle,
                    color: "#777A82",
                  }}
                >
                  Short Term Debt:
                </Typography>
                <Typography
                  style={{
                    ...valueStyle,
                    color: "#2F3D63",
                  }}
                >
                  {formatNumber(ShortTermDebt)}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              display={longTermDebt ? "" : "none"}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  style={{
                    ...headingStyle,
                    color: "#777A82",
                  }}
                >
                  Long Term Debt:
                </Typography>
                <Typography
                  style={{
                    ...valueStyle,
                    color: "#2F3D63",
                  }}
                >
                  {formatNumber(longTermDebt)}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              display={longTermDebt ? "" : "none"}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography style={{ ...headingStyle, color: "#777A82" }}>
                  Ticker:
                </Typography>
                <Typography style={{ ...valueStyle, color: "#2F3D63" }}>
                  {ticker} {tickerMarket ? `(${tickerMarket})` : ""}
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Box sx={{ display: isAllPRVarsInvalid ? "none" : "block" }}>
            <Typography
              style={{
                width: "290px",
                height: "24px",
                fontSize: "20px",
                fontWeight: "500",
                color: "#1A2A56",
                padding: "20px",
              }}
              marginY={4}
            >
              Profitability Ratios
            </Typography>
            <Grid container rowGap={2} ml={3}>
              <Grid
                item
                xs={12}
                sm={3}
                md={3}
                display={
                  !(operatingIncome / revenueYear1) ||
                  isNaN(operatingIncome / revenueYear1)
                    ? "none"
                    : "block"
                }
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography style={{ ...headingStyle, color: "#777A82" }}>
                    Operating income/ Revenue
                  </Typography>
                  <Typography style={{ ...valueStyle, color: "#2F3D63" }}>
                    {((operatingIncome / revenueYear1) * 100).toFixed(2)}%
                  </Typography>
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                sm={3}
                md={3}
                display={
                  !(netIncome / revenueYear1) || isNaN(netIncome / revenueYear1)
                    ? "none"
                    : "block"
                }
              >
                <Box>
                  <Typography style={{ ...headingStyle, color: "#777A82" }}>
                    Net income/ Revenue:
                  </Typography>
                  <Typography style={{ ...valueStyle, color: "#2F3D63" }}>
                    {((netIncome / revenueYear1) * 100).toFixed(2)}%
                  </Typography>
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                sm={3}
                md={3}
                display={
                  !ROA || isNaN(ROA) || ROA === "0.00" ? "none" : "block"
                }
              >
                <Box>
                  <Typography style={{ ...headingStyle, color: "#777A82" }}>
                    ROA:
                  </Typography>
                  <Typography style={{ ...valueStyle, color: "#2F3D63" }}>
                    {ROA}%
                  </Typography>
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                sm={3}
                md={3}
                display={
                  !ROE || isNaN(ROE) || ROE === "0.00" ? "none" : "block"
                }
              >
                <Box>
                  <Typography style={{ ...headingStyle, color: "#777A82" }}>
                    ROE:
                  </Typography>
                  <Typography style={{ ...valueStyle, color: "#2F3D63" }}>
                    {ROE}%
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ display: funding ? "block" : "none" }}>
            <Typography
              style={{
                width: "290px",
                height: "24px",
                fontSize: "20px",
                fontWeight: "500",
                color: "#1A2A56",
                padding: "20px",
              }}
              marginY={4}
            >
              Funding Information
            </Typography>
            <Grid container rowGap={2} ml={3}>
              <Grid item xs={12} sm={6} md={4}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography style={{ ...headingStyle, color: "#777A82" }}>
                    Total Rounds:
                  </Typography>
                  <Typography style={{ ...valueStyle, color: "#2F3D63" }}>
                    {funding?.totalRounds || "N/A"}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography style={{ ...headingStyle, color: "#777A82" }}>
                    Last Round Type:
                  </Typography>
                  <Typography style={{ ...valueStyle, color: "#2F3D63" }}>
                    {funding?.lastRound?.type || "N/A"}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography style={{ ...headingStyle, color: "#777A82" }}>
                    Last Round Date:
                  </Typography>
                  <Typography style={{ ...valueStyle, color: "#2F3D63" }}>
                    {funding?.lastRound?.date || "N/A"}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography style={{ ...headingStyle, color: "#777A82" }}>
                    Investors:
                  </Typography>
                  {funding?.investors?.length > 0 ? (
                    funding?.investors.map((investor, index) => (
                      <Typography
                        key={index}
                        style={{ ...valueStyle, color: "#2F3D63" }}
                      >
                        {investor.name} {/* Display only the investor's name */}
                      </Typography>
                    ))
                  ) : (
                    <Typography style={{ ...valueStyle, color: "#2F3D63" }}>
                      N/A
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box padding="25px" display={isAllYRVarsInvalid ? "none" : ""}>
            <Typography
              style={{
                width: "290px",
                height: "24px",
                fontSize: "20px",
                fontWeight: "500",
                color: "#1A2A56",
              }}
              marginBottom={2}
            >
              Yearly Review
            </Typography>
            <Box
              style={{
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                boxShadow: "0px 3px 6px #00000029",
                border: "1px solid #F9F9F9",
                borderRadius: "8px",
                opacity: 1,
                padding: "25px",
              }}
            >
              <TableContainer style={{ borderRadius: "8px" }}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead
                    style={{
                      background: "#80AAC11A",
                      borderRadius: "8px",
                      opacity: 1,
                      fontFamily: "Rubik , sans-serif",
                      fontSize: "12px",
                    }}
                  >
                    <TableRow>
                      <TableCell
                        style={{ color: "#1A2A56", borderBottom: "none" }}
                      >
                        Breakdown
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ color: "#1A2A56", borderBottom: "none" }}
                      >
                        {dataYear1?.fiscalDateEnding}
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ color: "#1A2A56", borderBottom: "none" }}
                      >
                        {dataYear2?.fiscalDateEnding}
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ color: "#1A2A56", borderBottom: "none" }}
                      >
                        {dataYear3?.fiscalDateEnding}
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ color: "#1A2A56", borderBottom: "none" }}
                      >
                        {dataYear4?.fiscalDateEnding}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow
                        key={row.name}
                        style={{
                          background: "#FAFAFC",
                          borderRadius: "8px",
                          opacity: 1,
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ color: "#1A2A56", borderBottom: "none" }}
                        >
                          {row.name}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{ color: "#1A2A56", borderBottom: "none" }}
                        >
                          {row.year1 ? row.year1 : null}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{ color: "#1A2A56", borderBottom: "none" }}
                        >
                          {row.year2 ? row.year2 : null}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{ color: "#1A2A56", borderBottom: "none" }}
                        >
                          {row.year3 ? row.year3 : null}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{ color: "#1A2A56", borderBottom: "none" }}
                        >
                          {row.year4 ? row.year4 : null}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
          <Box padding="25px" display={isAllQRVarsInvalid ? "none" : ""}>
            <Typography
              style={{
                width: "290px",
                height: "24px",
                fontSize: "20px",
                fontWeight: "500",
                color: "#1A2A56",
              }}
              marginBottom={2}
            >
              Quaterly Review
            </Typography>
            <Box
              style={{
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                boxShadow: "0px 3px 6px #00000029",
                border: "1px solid #F9F9F9",
                borderRadius: "8px",
                opacity: 1,
                padding: "25px",
              }}
            >
              <TableContainer style={{ borderRadius: "8px" }}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead
                    style={{
                      background: "#80AAC11A",
                      borderRadius: "8px",
                      opacity: 1,
                      fontFamily: "Rubik , sans-serif",
                      fontSize: "12px",
                    }}
                  >
                    <TableRow>
                      <TableCell
                        style={{ color: "#1A2A56", borderBottom: "none" }}
                      >
                        Breakdown
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ color: "#1A2A56", borderBottom: "none" }}
                      >
                        {dataYearQ1?.fiscalDateEnding}
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ color: "#1A2A56", borderBottom: "none" }}
                      >
                        {dataYearQ2?.fiscalDateEnding}
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ color: "#1A2A56", borderBottom: "none" }}
                      >
                        {dataYearQ3?.fiscalDateEnding}
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ color: "#1A2A56", borderBottom: "none" }}
                      >
                        {dataYearQ4?.fiscalDateEnding}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {QuaterRows.map((row) => (
                      <TableRow
                        key={row.name}
                        style={{
                          background: "#FAFAFC",
                          borderRadius: "8px",
                          opacity: 1,
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ color: "#1A2A56", borderBottom: "none" }}
                        >
                          {row.name}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{ color: "#1A2A56", borderBottom: "none" }}
                        >
                          {row.Q1 ? row.Q1 : null}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{ color: "#1A2A56", borderBottom: "none" }}
                        >
                          {row.Q2 ? row.Q2 : null}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{ color: "#1A2A56", borderBottom: "none" }}
                        >
                          {row.Q3 ? row.Q3 : null}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{ color: "#1A2A56", borderBottom: "none" }}
                        >
                          {row.Q4 ? row.Q4 : null}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}