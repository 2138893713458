import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Button, Card, CardContent, CardHeader } from "@mui/material";
import CompletionSVGIcon from "../Assets/Images/completion.svg";
import StatusChangeSVGIcon from "../Assets/Images/status_change.svg";
import AttentionSVGIcon from "../Assets/Images/attention.svg";
import dayjs from "dayjs";
import { ColorEnum } from "../Assets/Colors/ColorsEnum";
import { API, graphqlOperation } from "aws-amplify";
import { companiesByName } from "../graphql/queries";
import { createCompany } from "../graphql/mutations";
import RightIcon from "../Assets/Images/right.svg";
import getTenantIdFromURL from "../utils/getTenantIdFromURL";
import { useSelector } from "react-redux";

const drawerWidth = 300;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  position: "relative",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

export default function NotificationDrawer(props) {
  const URLTenant = getTenantIdFromURL();
  const isAdmin = useSelector(state => state.userInfo.isAdmin);
  const handleDrawerClose = () => {
    props.close();
  };

  const handleAlertClick = async (params) => {
    const companyName = JSON.parse(params.companyInfo)?.companyName;
    if (companyName) {
      let result = await API.graphql(
        graphqlOperation(companiesByName, {
          name: companyName,
          limit: 1,
          filter: {
            tenantId: {
              eq: params.tenantId,
            },
          },
        })
      );

      const items = result.data.companiesByName.items;
      let company = null;
      if (items.length === 0) {
        try {
          result = await API.graphql(
            graphqlOperation(createCompany, {
              input: {
                name: companyName,
                tenantId: params.tenantId,
              },
            })
          );
        } catch (error) {
          console.log(error);
        }
        company = result.data.createCompany;
      } else {
        company = items[0];
      }
      if(isAdmin)window.open(`/tenant-dashboard/${URLTenant}/company/${company.id}`, "_blank");
      else window.open(`/company/${company.id}`, "_blank");
    }
  };

  const notificationTypes = {
    "Status Change": (
      <CardHeader
        avatar={
          <Box
            sx={{
              width: "28px",
              height: "28px",
              background: "#3753A033",
              borderRadius: "50%",
              opacity: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img width="16px" src={StatusChangeSVGIcon} alt="Icon" />
          </Box>
        }
        title="Status Change"
        titleTypographyProps={{
          color: ColorEnum.DARK_BLUE_SHADE2,
          opacity: 1,
          fontWeight: "bold",
        }}
        action={
          <Button
            variant="text"
            sx={{
              color: ColorEnum.PRIMARY_BLUE,
              fontSize: 10,
              textDecoration: "underline",
              textTransform: "capitalize",
              top: 3,
            }}
          >
            Hide
          </Button>
        }
      />
    ),
    Complete: (
      <CardHeader
        avatar={
          <Box
            sx={{
              width: "28px",
              height: "28px",
              background: "#60AC5033",
              borderRadius: "50%",
              opacity: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img width="16px" src={CompletionSVGIcon} alt="Icon" />
          </Box>
        }
        title="Process Completed"
        titleTypographyProps={{
          color: ColorEnum.GREEN,
          opacity: 1,
          fontWeight: "bold",
        }}
        action={
          <Button
            variant="text"
            sx={{
              color: ColorEnum.PRIMARY_BLUE,
              fontSize: 10,
              textDecoration: "underline",
              textTransform: "capitalize",
              top: 3,
            }}
          >
            Hide
          </Button>
        }
      />
    ),
    Update: (
      <CardHeader
        avatar={
          <Box
            sx={{
              width: "28px",
              height: "28px",
              background: "#3753A033",
              borderRadius: "50%",
              opacity: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img width="16px" src={AttentionSVGIcon} alt="Icon" />
          </Box>
        }
        title="Attention"
        titleTypographyProps={{
          color: ColorEnum.DARK_BLUE_SHADE2,
          opacity: 1,
          fontWeight: "bold",
        }}
        action={
          <Button
            variant="text"
            sx={{
              color: ColorEnum.PRIMARY_BLUE,
              fontSize: 10,
              textDecoration: "underline",
              textTransform: "capitalize",
              top: 3,
            }}
          >
            Hide
          </Button>
        }
      />
    ),
  };

  const [delayedOpen, setDelayedOpen] = React.useState(false);
  const [showUnread, setShowUnread] = React.useState(false);

  React.useEffect(() => {
    let timer;
    if (props.open) {
      timer = setTimeout(() => {
        setDelayedOpen(true);
      }, 1500); // Delay rendering for 1.5 seconds
    } else {
      setDelayedOpen(false);
    }

    return () => clearTimeout(timer);
  }, [props.open]);

  const filteredData = showUnread
    ? props.data.filter((item) => !item.isRead)
    : props.data;
    
    console.log(props.data);
    

  return (
    <Drawer
      variant="permanent"
      anchor="right"
      open={props.open}
      sx={{
        width: props.open ? drawerWidth : 0,
        flexShrink: 0,
        opacity: props.open ? 1 : 0,
        height: props.open ? "100%" : "0",
        whiteSpace: "nowrap",
        transition: "width 1.0s, height 1.5s, opacity 1.5s",
        zIndex: props.open ? "5" : "-1",
        
        '& .MuiDrawer-paper': {
          // width: drawerWidth,
          // overflowX: 'hidden',
          '::-webkit-scrollbar': {
            width: 0,
            height: 0,
          },
        },
      }}
    >
      <DrawerHeader >
        <div>
          <IconButton
            onClick={handleDrawerClose}
            style={{ position: "relative", left: "-10px", zIndex: 10 }}
          >
            {props.open ? (
              <img src={RightIcon} alt="Expand Less" width="33" height="33" />
            ) : null}
          </IconButton>
        </div>
        <Typography
          color={ColorEnum.DARK_BLUE}
          fontWeight={"bold"}
          component="div"
        >
          Notifications
        </Typography>
        <Button
          sx={{ fontSize: 10, textTransform: "capitalize", position : 'relative' ,width: 80, display: "inline-block", left: 25 }}
          color="primary"
          variant="contained"
          size="small"
          disabled={props.data.length === 0}
          onClick={() => {
            setShowUnread(!showUnread);
          }}
        >
          {showUnread ? "Show All" : "Show Unread"}
        </Button>
      </DrawerHeader>
      <Divider />
      {filteredData.length > 0 && (
        <>
          <List>
            {filteredData.map((item, index) => (
              <Card
                key={index}
                sx={{ maxWidth: 280, margin: "10px", backgroundColor: "#1A2A5614" }}
              >
                {notificationTypes[item.type]
                  ? notificationTypes[item.type]
                  : notificationTypes.Complete}
                <CardContent
                  sx={{ position: "relative", bottom: 12 }}
                  onClick={() => handleAlertClick(item)}
                >
                  <Typography  variant="body2" sx={{ whiteSpace: 'pre-line' }} color={ColorEnum.TEXT_DARK_BLUE}>
                    {item.message}
                  </Typography>
                  <Typography
                    color={ColorEnum.GREY}
                    fontSize={12}
                    position={"relative"}
                    top={15}
                  >
                    {dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss")}
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </List>
          <Divider />
        </>
      )}
    </Drawer>
  );
}
