import {
  Button,
  Card,
  LinearProgress,
  linearProgressClasses,
  Typography,
  Menu,
  MenuItem,
  Box,
  CircularProgress,
  Tooltip,
  Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import RequestManualEvaluationDialog from "./RequestManualEvaluationDialog";
import { companiesByName, getTenant, triggerSendEvaluationEmail } from "./graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import AWS from 'aws-sdk';
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import { toast } from "react-toastify";

Amplify.configure(awsExports);

AWS.config.update(
  {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  }
);


function ExecutiveSummary({
  company,
  summary,
  overview,
  incomeStatement,
  AgingDataForCompany,
  DnbData,
  tenantId,
  setShowExport,
  linkedInJSON,
  dnBFinancialV2Data,
  dnbJsonData
}) {
  const WikiInfobox = company?.wikipedia?.infobox
    ? JSON.parse(company.wikipedia.infobox)
    : {};
  const manualSource = company?.manualSource
    ? JSON.parse(company.manualSource)
    : {};
  const google = company.googleSearch?.knowledgeGraph?.raw
    ? JSON.parse(company.googleSearch.knowledgeGraph.raw)
    : {};
  const options = [
    { key: "Financial Statement", value: "Financial Statement" },
    { key: "Comfort letter", value: "Comfort letter" },
    { key: "Parent company guarantee", value: "Parent company guarantee" },
    { key: "Owners' guarantee", value: "Owners' guarantee" },
    { key: "Government guarantee", value: "Government guarantee" },
    { key: "Insurance", value: "Insurance" },
    { key: "Collateral", value: "Collateral" }
  ]

  const [anchorEl, setAnchorEl] = useState(null);
  const [title, setTitle] = useState("");
  const [comment, setComment] = useState("");
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [specificCreditLimit, setSpecificCreditLimit] = useState("");
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    console.log("company, summary, DnbData, tenantId",{company, summary, DnbData, tenantId})
    if (company && summary && DnbData && tenantId) {
      const timeoutId = setTimeout(() => {
        setLoading(false);
        setShowExport(true)
      }, 1000);
      getFounded();
      return () => clearTimeout(timeoutId);
    }
  }, [company, summary, DnbData, tenantId]);


  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleCommentsChange = (event) => {
    setComment(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleFullNameChange = (event) => {
    setFullName(event.target.value);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleSpecificCreditLimitValue = (event) => {
    setSpecificCreditLimit(event.target.value);
  };

  const handleSubmit = async (row) => {
    console.log("Selected Option:", selectedOption);
    const companyName = company?.name;
    try {
      let limit;
      if (selectedOption === "Specific Credit Limit") {
        console.log("Specific credit limit:", specificCreditLimit);
        limit = specificCreditLimit;
      } else {
        limit = "Max";
      }

      let result = await API.graphql(
        graphqlOperation(companiesByName, {
          name: companyName,
          limit: 1,
          filter: {
            tenantId: {
              eq: tenantId,
            },
          },
        })
      );

      const companyid = result.data.companiesByName.items[0].id;
      let tenant = await API.graphql(
        graphqlOperation(getTenant, {
          id: tenantId,
        })
      );
      const tenantName = tenant.data.getTenant.name;
      await API.graphql(
        graphqlOperation(triggerSendEvaluationEmail, {
          tenantId: tenantId,
          tenantName: tenantName,
          id: companyid,
          companyName: companyName,
          requestedAmount: limit,
          comments: comment,
          fullName: fullName,
          title: title,
          email: email,
          phoneNumber: phoneNumber
        })
      );

      handleCloseDialog();
      toast.success("Evaluation request sent successfully!")
    } catch (error) {
      console.error("Error occurred:", error);
      toast.error("An error occurred while sending the evaluation request. Please try again later.");
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleManualEvaluationClick = (event) => {
    setIsDialogOpen(true);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getColor = (score) => {
    if (isNaN(score)) return "#2F3D63";

    if (score >0 && score < 4) {
      return "#3AA623";
    } else if (score >= 4 && score < 7) {
      return "#E7BF3C";
    } else if (score >= 7) {
      return "#F25A48";
    } else {
      return "#2F3D63"; // default color
    }
  };

  const getImage = (score) => {
    if (score >= 1 && score < 4) {
      return "/greenSmiley.svg";
    } else if (score >= 4 && score < 7) {
      return "/yellowSmiley.svg";
    } else {
      return "/redSmiley.svg";
    }
  };

  var ARCScore = "";
  var legalScore = "";
  var alternativeScore = "";
  var countryScore = "";
  var riskScore = "";
  var recommendedLimit = "";
  var requestedLimit = "";
  var underwritingReason = "";
  var currentArBalance = "";
  var totalArBalance = "";
  var termsRecommended = "";
  var currentRecommended = "";
  var paydex = "";
  var tenure = "";
  var avgCustomerPaymentDays = "";
  var arear = "-"
  var delinquencyScore = "";
  var failureScore = "";
  var altmanZScore = "";
  var arBalance30 = "";
  var arBalance60 = "";
  var arBalance90 = "";
  var lastUpdatedDate = "";

  const open = Boolean(anchorEl);
  if (summary) {
    ARCScore = summary["Combined Score"] && !isNaN(summary["Combined Score"])?summary["Combined Score"]:0;
    legalScore = parseFloat(summary["Legal Score"]);
    alternativeScore = parseFloat(summary["Alternative Score"]);
    countryScore = parseFloat(summary["Country Score"]);
    delinquencyScore = summary["Delinquency Score"] ? parseFloat(summary["Delinquency Score"]) : undefined;
    failureScore = summary["Failure Score"] ? parseFloat(summary["Failure Score"]) : undefined;
    riskScore = summary["Risk Segment"] ? parseFloat(summary["Risk Segment"]) : undefined;
    altmanZScore = summary["Altman Z Score"] ? parseFloat(summary["Altman Z Score"]) : undefined;

    recommendedLimit = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(summary["Recommended Limit"]);

    requestedLimit = summary["Requested Limit"] 
    ? new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(summary["Requested Limit"])
    : "";

    if (summary["Type Last Updated At"]) {
      var date = new Date(summary["Type Last Updated At"]);
      var day = String(date.getDate()).padStart(2, '0');
      var month = String(date.getMonth() + 1).padStart(2, '0');
      var year = date.getFullYear();
  
      lastUpdatedDate = `${day}/${month}/${year}`;
    }
  
    currentArBalance = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(summary["Current AR Balance"]);

    totalArBalance = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(summary["Total AR Balance"]);

    arBalance30 = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(summary["AR Balance Aging 30"]);

    arBalance60 = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(summary["AR Balance Aging 60"]);

    arBalance90 = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(summary["AR Balance Aging 90"]);
    
    let recommendedNet = summary["Recommended Net"];
    if (recommendedNet && recommendedNet.startsWith('Net ')) {
      recommendedNet = recommendedNet.substring(4);
    }
    recommendedNet = parseInt(recommendedNet) || '-';
    termsRecommended = recommendedNet;

    let currentNet = summary["Current Net"];
    if (currentNet && currentNet.startsWith('Net ')) {
      currentNet = currentNet.substring(4);
    }
    currentNet = parseInt(currentNet) || '-';
    currentRecommended = currentNet;

    paydex = summary["Paydex"] ? parseFloat(summary["Paydex"])?.toFixed(1) : "-"; 
    tenure = summary["Tenure"] ? parseInt(summary["Tenure"]) : "-";
    avgCustomerPaymentDays = summary["AVG Customer Payment Days"] ? summary["AVG Customer Payment Days"] : "-";
      
    underwritingReason = summary["Underwriting Reason"];
  }


  const descriptionWikipedia = "";
  const regex = /\{\{Short description\|(.+?)\}\}/;
  const match = company.wikipedia?.content?.match(regex);
  if (match) {
    const descriptionWikipedia = match[1];
    console.log(descriptionWikipedia);
  } else {
    console.log("No short description found");
  }

  const formatNumber = (number) => {
    if (typeof number === "string" && (number.includes("US $") || number.includes("$") || number.includes("billion") || number.includes("million") || number.includes("thousand"))) {
      return number;
    }
    if (number >= 1e9) {
      return `US $${(number / 1e9).toFixed(2)} billion`;
    } else if (number >= 1e6) {
      return `US $${(number / 1e6).toFixed(2)} million`;
    } else if (number >= 1e3) {
      return `US $${(number / 1e3).toFixed(2)} thousand`;
    } else {
      return `US $${number}`;
    }
  };
  const getDescription = () => { // TODO
    if(manualSource?.description){
      return manualSource?.description;
    } else if (overview?.Description) {
      return overview.Description;
    } else if (linkedInJSON?.description) {
      return linkedInJSON?.description;
    } else if (descriptionWikipedia !== "") {
      return descriptionWikipedia;
    } else if (WikiInfobox?.description?.manual === true) {
      return WikiInfobox.description.value;
    } else if (WikiInfobox?.description) {
      return WikiInfobox.description;
    } else {
      return "";
    }
  };
  const getSector = () => {
  if(manualSource?.industry){
    return manualSource?.industry;
  } else if (AgingDataForCompany?.["Entity:Industry"]) {
      return AgingDataForCompany["Entity:Industry"];
  } else if (overview?.sector) {
      return overview.sector;
  } else if (dnbJsonData?.organization?.primaryIndustryCode?.usSicV4Description) {
      return dnbJsonData.organization.primaryIndustryCode.usSicV4Description;
  } else if (linkedInJSON?.industry) {
      return linkedInJSON.industry;
  } else if (WikiInfobox?.industry?.manual === true) {
      return WikiInfobox.industry.value;
  } else if (WikiInfobox?.industry) {
      return WikiInfobox.industry;
  } else {
      return "";
  }
  };
  function extractYear(dateString) {
    if (typeof dateString !== 'string') {
      return "";
    }

    const yearPattern = /\b(\d{4})\b/;
    const monthDayYearPattern = /\b(?:January|February|March|April|May|June|July|August|September|October|November|December)\s+\d{1,2},\s+(\d{4})\b/;
    const monthYearPattern = /\b(?:January|February|March|April|May|June|July|August|September|October|November|December)\s+(\d{4})\b/;

    let match = dateString.match(monthDayYearPattern);
    if (match) {
      return match[1];
    }

    match = dateString.match(monthYearPattern);
    if (match) {
      return match[1];
    }

    match = dateString.match(yearPattern);
    if (match) {
      return match[1];
    }

    return "";
  }
const getFounded = () => { // TODO add zoom info
  if(manualSource?.founded){
    return manualSource?.founded;
  } else if(dnbJsonData?.organization?.incorporatedDate){
    return dnbJsonData.organization?.incorporatedDate;
  } else if (company?.googleSearch?.knowledgeGraph?.raw) {
    const date = JSON.parse(company.googleSearch.knowledgeGraph.raw)?.founded;
    return date || "";
  } else if (WikiInfobox?.founded?.manual === true) {
    return WikiInfobox.founded.value;
  } else if (WikiInfobox?.foundation?.manual === true) {
    return WikiInfobox.foundation.value;
}else if (WikiInfobox?.foundation) {
    return WikiInfobox.foundation;
}
  else if (WikiInfobox?.founded) {
    return WikiInfobox.founded;
  } else {
    return "";
  }
};

const getSymbol = () => { // TODO
  if(manualSource?.ticker){
    return manualSource?.ticker;
  } else if (overview?.Symbol) {
    return overview.Symbol + " + " + overview.Exchange;
  } else if (company?.ticker) {
    return company.ticker;
  } else if (WikiInfobox?.traded_as?.manual === true) {
    return WikiInfobox.traded_as.value;
}  else if (WikiInfobox?.traded_as) {
    return WikiInfobox.traded_as;
  } if (linkedInJSON?.stock) {
    const ticker = linkedInJSON?.stock?.ticker;
    const tickerMarket = linkedInJSON?.stock?.market;
    return `${ticker} ${tickerMarket ? `(${tickerMarket})` : ""}`;
  } else {
    return "";
  }
};
  const formatToUSD = (yearlyRevenue) => {
    let usdRevenue = null;
    for (let revenue of yearlyRevenue) {
        if (revenue.currency === "USD") {
            usdRevenue = revenue.value;
            break;
        }
    }

    if (usdRevenue !== null) {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(usdRevenue);
    } else {
        return ""; 
    }
};
// const getRevenue = () => { 
// if(manualSource?.revenue){
//     return formatNumber(manualSource?.revenue);
// } else if(dnbJsonData?.[0]?.response?.organization?.financials?.[0]?.yearlyRevenue ) {
//   const allRevenues = dnbJsonData?.[0]?.response?.organization?.financials?.[0]?.yearlyRevenue || [];
//     const usdRevenue = formatToUSD(allRevenues);
//     return usdRevenue;
//   } else if (WikiInfobox?.revenue?.manual === true) {
//     return WikiInfobox.revenue.value;
// }else if (WikiInfobox?.revenue) {
//     return WikiInfobox.revenue;
//   }
// };

function getRevenue(allSources) {
  const { financialStatement, AV, companySearch, SF, dnbFinancials, wiki, DnbData } = allSources;
  console.log("allSources", allSources);

  function getRevenueFromSource(source) {
    switch (source) {
      case "manual":
        if(manualSource?.revenue){
          return formatNumber(manualSource?.revenue);
        }
        return null;
      case "financialStatement":
        return (
          financialStatement?.annualReports?.[0]?.totalRevenue ||
          financialStatement?.annualReports?.[0]?.salesRevenue ||
          financialStatement?.annualReports?.[0]?.netSales ||
          null
        );
      case "AV":
        return AV?.incomeStatement?.annualReports?.[0]?.totalRevenue || null;
      case "companySearch":
        return companySearch?.financial_data?.data?.revenue?.latest || null;
      case "SF":
        return SF?.financial_data?.latest || null;
      case "dnbFinancials":
        let revenue = dnbFinancials?.organization?.latestFinancials?.overview?.salesRevenue || null;
        const units = dnbFinancials?.units || "SingleUnits"; 
        const currency = dnbFinancials?.organization?.latestFinancials?.currency || "USD";

        if (revenue !== null && currency === "USD") {
            if (units === "Million") {
                revenue *= 1000000;
            } else if (units === "Billion") {
                revenue *= 1000000000;
            }
        }

        if (revenue === null) {
            const yearlyRevenue = DnbData?.organization?.financials[0]?.yearlyRevenue || [];
            const usdRevenue = yearlyRevenue?.find((item) => item.currency === "USD");

            if (usdRevenue) {
                revenue = usdRevenue.value;
            }
        }
      
        return revenue;          
      case "wiki":
        if (wiki?.revenue?.manual === true) {
            return wiki.revenue.value;
        } else if (wiki?.revenue) {
            return wiki.revenue;
        }
        return null;
      default:
        return null;
    }
}

  const revenueSources = [
      "manual",
      "financialStatement",
      "AV",
      "companySearch",
      "SF",
      "dnbFinancials",
      "wiki"
  ];

  for (let source of revenueSources) {
      const revenue = getRevenueFromSource(source);
      if (revenue) {
          return formatNumber(revenue); 
      }
  }

  return null; 
}

  const getMarketCap = () => {
    //AV_data.Overview.MarketCapitalization
    if(manualSource?.marketCap){
      return formatNumber(manualSource?.marketCap);
    } else if (overview?.MarketCapitalization) {
      return formatNumber(overview.MarketCapitalization);
    }
  };
  const getNumberOfEmployees = () => {
    let sources = [];
    if(manualSource?.numberOfEmployees){
      return manualSource?.numberOfEmployees;
    }

    // Source 1: Financial Statement
    // if (financialStatement?.numberOfEmployees) {
    //     sources.push({ source: 'financialStatement', value: financialStatement.numberOfEmployees });
    // }

    // Source 2: Company Search
    // if (companySearch?.number_of_employees?.data) {
    //     sources.push({ source: 'companySearch', value: companySearch.number_of_employees.data });
    // }

    // Source 3: LinkedIn (only exact number)
    if (linkedInJSON?.company_size && !isRange(linkedInJSON.company_size)) {
        const linkedInCount = parseEmployeeCount(linkedInJSON.company_size);
        if (linkedInCount) {
            sources.push({ source: 'linkedIn', value: linkedInCount });
        }
    }

    // Source 4: ZoomInfo
    // if (zoomInfo?.employeeCount) {
    //     sources.push({ source: 'zoomInfo', value: zoomInfo.employeeCount });
    // }

    // Source 5: Financial Data from SF
    // if (financialDataSF?.numberOfEmployees) {
    //     sources.push({ source: 'financialDataSF', value: financialDataSF.numberOfEmployees });
    // }

    // Source 6: DNB 
    const dnbEmployees = dnbJsonData?.organization?.globalUltimate?.numberOfEmployees;
    console.log("dnbEmployees",dnbEmployees)
    if (dnbEmployees) {
        const consolidatedEmployees = dnbEmployees.find(
            employee => employee.informationScopeDescription === "Consolidated"
        );
        if (consolidatedEmployees?.value) {
            sources.push({ source: 'dnb', value: consolidatedEmployees.value });
        }
    }

    // Source 7: Wikipedia Infobox
    if (WikiInfobox?.num_employees) {
        const wikiEmployees = WikiInfobox.num_employees.manual ? WikiInfobox.num_employees.value : WikiInfobox.num_employees;
        if (wikiEmployees) {
            sources.push({ source: 'wikipedia', value: wikiEmployees });
        }
    }

    // If no sources are available, return an empty string
    if (sources.length === 0) {
        return "";
    }

    // Sort sources by value in descending order
    sources.sort((a, b) => b.value - a.value);

    // If the difference between the minimum and maximum is greater than 25%, return the range
    const maxEmployees = sources[0].value;
    const minEmployees = sources[sources.length - 1].value;

    if ((maxEmployees - minEmployees) / maxEmployees > 0.25) {
        return `${minEmployees} - ${maxEmployees}`;
    }

    // Return the latest value (highest priority)
    return maxEmployees;
};

const isRange = (size) => {
  return size.includes('-');
};

const parseEmployeeCount = (employeeRange) => {
  if (!employeeRange || !isNaN(employeeRange)) {
    return null;
  }

  employeeRange = employeeRange.replace(/employees?/i, '').trim();


  if (employeeRange.endsWith('+')) {

    const baseCount = employeeRange.replace(/\+$/, '').replace(/,/g, '');
    return parseInt(baseCount, 10);
  }


  const rangeMatch = employeeRange.match(/^(\d{1,3}(?:,\d{3})*)\s*-?\s*(\d{0,3}(?:,\d{3})*)$/);

  if (rangeMatch) {

    const minCount = parseInt(rangeMatch[1].replace(/,/g, ''), 10);
    const maxCount = rangeMatch[2] ? parseInt(rangeMatch[2].replace(/,/g, ''), 10) : minCount;
    return maxCount;
  }

  return 0;
};
  const getCreditRating = () => { //TODO
    //financial statement.credit rating or rating
    return "";
  };
  const getLocation = () => { // TODO add zoom info
    if (manualSource?.country || manualSource?.city) {
      const location = `${manualSource?.city || ''}${manualSource?.city && manualSource?.country ? ', ' : ''}${manualSource?.country || ''}`;
      return location;
    } else if (google?.headquarters) {
      return google.headquarters;
    } else if (WikiInfobox?.hq_location_country?.manual === true) {
      return WikiInfobox.hq_location_country.value+ " , " + WikiInfobox.hq_location_city.value;
    }else if (WikiInfobox?.hq_location_country) {
      return WikiInfobox.hq_location_country + " , " + WikiInfobox.hq_location_city;
    } else if (linkedInJSON?.locations) {
      const primaryLocation  = linkedInJSON?.locations.find((item)=>item.tag==="Primary")
      return primaryLocation?.address;
    } if (dnbJsonData?.organization?.primaryAddress) {
      return dnbJsonData?.organization?.primaryAddress?.addressLocality?.name + " , " + dnbJsonData?.organization?.primaryAddress?.addressCountry?.name;
    } else if (company?.country) {
      return company.country;
    } 
    else {
      return ""; // Default to empty string if no data available
    }
  };
  const getFounders = () => { // TODO
    if(manualSource?.founders){
      return manualSource?.founders;
    } else if (WikiInfobox?.founder?.manual === true) {
      return WikiInfobox.founder.value;
    } else if (WikiInfobox?.founder) {
      return WikiInfobox.founder;
    } 
    else {
      return ""; // Default to empty string if no data available
    }
  };
  const getKeyPeople = () => { //TODO
    if(manualSource?.keyPeople){
      return manualSource?.keyPeople;
    } else if (WikiInfobox?.key_people?.manual === true) {
      return WikiInfobox.key_people.value;
    } else if (WikiInfobox?.key_people) {
      return WikiInfobox.key_people;
    }
      else {
      return ""; // Default to empty string if no data available
    }
  };
  const getParentCompany = () => { // TODO
    if(manualSource?.parentCompany){
      return manualSource?.parentCompany;
    } else if (WikiInfobox?.parent?.manual === true) {
      return WikiInfobox.parent.value;
    }else  if (WikiInfobox?.parent) {
      return WikiInfobox.parent;
    }
      else {
      return "";
    }
  };
  const getWebsite = () => {
    if (manualSource?.website) {
      return manualSource?.website;
    } else if (WikiInfobox?.website?.manual) {
      return WikiInfobox?.website?.value;
    } else if (WikiInfobox?.website) {
      return WikiInfobox?.website
    } else if (linkedInJSON?.website) {
      return linkedInJSON?.website;
    } else  if (google?.website) {
      return google?.website;
    }
      else {
      return null  
    }
  };
  var Description = getDescription();
  const Main_sector = getSector();
  const Symbol = getSymbol();
  const founded = getFounded();
  const Revenue = getRevenue({
    AV: { overview, incomeStatement },
    wiki: WikiInfobox,
    dnbFinancials: dnBFinancialV2Data,
    DnbData: dnbJsonData
  });
  const Number_of_employees = getNumberOfEmployees();
  const Main_location_office = getLocation();
  const Market_cap = getMarketCap();
  const Credit_Rating = getCreditRating();
  const founders = getFounders();
  const Key_staff = getKeyPeople();
  const Parent_company = getParentCompany();
  const website = getWebsite()
  const main_competitors = ""; // TODO

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: "12.78px",
    borderRadius: 5,
    width: "199.68px",
    boxShadow: "inset 0px 3px 6px rgba(0, 0, 0, 0.16)",
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: getColor(ARCScore),
    },
  }));

  const fields = [
    Description,
    Main_sector,
    founded,
    Symbol,
    Revenue,
    Market_cap,
    Number_of_employees,
    Credit_Rating,
    Main_location_office,
    founders,
    Key_staff,
    Parent_company,
    website,
  ];

  let isAllFieldsInvalid = fields.every(
    (v) => v === undefined || v === null || v === ""
  );

  const predefinedFields = [
    "description",
    "industry",
    "founders",
    "keyPeople",
    "founded",
    "ticker",
    "website",
    "country",
    "city",
    "parentCompany",
    "revenue",
    "marketCap",
    "numberOfEmployees",
    "AQR",
    "netIncome",
    "yearsOfOperation",
    "equity"
  ];

  const infoboxCard = [
    { label: "Description:", value: Description },
    { label: "Main sector:", value: Main_sector },
    { label: "Founded:", value: founded },
    { label: "Symbol:", value: Symbol },
    { label: "Website:", value: website },
    { label: "Revenue:", value: Revenue },
    { label: "Market Cap/Evaluation:", value: Market_cap },
    { label: "Number of employees:", value: Number_of_employees },
    { label: "Credit Rating:", value: Credit_Rating },
    { label: "Main location:", value: Main_location_office },
    { label: "Founders:", value: founders },
    { label: "Key Staff:", value: Key_staff },
    { label: "Parent Company:", value: Parent_company },
  ];

  const additionalFields = Object.keys(manualSource)
  .filter(field => !predefinedFields.includes(field))
  .map(field => ({
    label: `${field}:`,
    value: manualSource[field]
  }))
  .filter(field => field.value !== undefined && field.value !== null && field.value !== '');  // Filter out empty values

const combinedInfoboxCard = [...infoboxCard, ...additionalFields];
console.log("Combined Infobox Card",combinedInfoboxCard)
  function isValid(value) {
    return (
      value !== null &&
      value !== undefined &&
      value !== "" &&
      !Number.isNaN(value) &&
      value !== "NaN" && 
      value !== Infinity
    );
  }

    const ScoreCard = ({
      risk,
      alternative,
      delinquency,
      legal,
      failure,
      country,
      altmanZ,
    }) => {
      const scores = [
        { label: "Risk Segment", value: risk, priority: 1 },
        { label: "Alternative Score", value: alternative, priority: 2 },
        { label: "Delinquency Score", value: delinquency, priority: 3 },
        { label: "Legal Score", value: legal, priority: 4 },
        { label: "Failure Score", value: failure, priority: 5 },
        { label: "Country Score", value: country, priority: 6 },
        { label: "Altman Z Score", value: altmanZ, priority: 7 },
      ];
    
  const availableScores = scores.filter(
    (score) =>
      score.value !== undefined &&
      score.value !== null &&
      !isNaN(score.value)
  );

  const nonAltmanScores = availableScores.filter(
    (score) => score.label !== "Altman Z Score"
  );
  if(nonAltmanScores.length < 4 && isValid(altmanZ)){
    nonAltmanScores.push({ label: "Altman Z Score", value: altmanZ, priority: 7 })
  }
  nonAltmanScores.sort((a, b) => {
    if (b.value === a.value) {
      return a.priority - b.priority;
    }
    return b.value - a.value;
  });

  const displayScores =
  nonAltmanScores.length > 4
      ? nonAltmanScores.slice(0, 4)
      : nonAltmanScores;
      return (
        <div
          style={{
            width: "30%",
            gridTemplateColumns: "repeat(auto-fit, minmax(335px, 1fr))",
          }}
        >
          {displayScores.map((score, index) => (
            <React.Fragment key={index}>
              <Typography
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitBoxOrient: "horizontal",
                  color: "#777A82",
                  textAlign: "left",
                  fontSize: "14px",
                  paddingTop: "15px",
                }}
              >
                {score.label}:
              </Typography>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitBoxOrient: "horizontal",
                    fontSize: "14px",
                    fontWeight: "500",
                    width: "fit-content",
                    marginRight: "5px",
                    color: getColor(score.value),
                  }}
                >
                  {score.value}
                </Typography>
                <img
                  src={getImage(score.value)}
                  alt="smiley"
                  height={"12.22px"}
                  width={"10.9px"}
                />
              </div>
            </React.Fragment>
          ))}
        </div>
      );
    }


  return (
    <Box padding={"25px"}>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="335px"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "20px",
            }}
          >
            <Card
              style={{
                flex: "1",
                minHeight: "335px",
                background:
                  "rgba(250, 250, 252, 1) 0% 0% no-repeat padding-box",
                boxShadow:
                  "inset 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 3px 6px rgba(0, 0, 0, 0.16)",
                borderRadius: "8px",
                opacity: 1,
                padding: "25px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    width: "60%",
                    gridTemplateColumns: "repeat(auto-fit, minmax(335px, 1fr))",
                  }}
                >
                  <Typography
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitBoxOrient: "horizontal",
                      textAlign: "left",
                      fontSize: "16px",
                      color: "#2F3D63",
                      marginRight: "10px",
                      paddingRight: "10px",
                      fontWeight: "500",
                      paddingTop: 4,
                    }}
                  >
                    ARC Score
                  </Typography>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitBoxOrient: "horizontal",
                        textAlign: "left",
                        fontSize: "24px",
                        color: getColor(ARCScore),
                        marginRight: "10px",
                        paddingRight: "10px",
                        fontWeight: "500",
                        paddingTop: 4,
                      }}
                    >
                      {ARCScore}
                    </Typography>
                    <img
                      src={getImage(ARCScore)}
                      alt="smiley"
                      height={"18px"}
                      width={"16px"}
                    />
                  </div>
                  <BorderLinearProgress
                    variant="determinate"
                    value={ARCScore * 10}
                  />
                  <Typography
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitBoxOrient: "horizontal",
                      width: "248px",
                      color: "#777A82",
                      textAlign: "left",
                      fontSize: "14px",
                      paddingTop: "15px",
                    }}
                  >
                    Recommended Limit (USD):
                  </Typography>
                  <Typography
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitBoxOrient: "horizontal",
                      width: "153px",
                      textAlign: "left",
                      fontSize: "12px",
                      color: "#2F3D63",
                      marginRight: "10px",
                      paddingRight: "10px",
                      fontWeight: "500",
                      paddingTop: 4,
                    }}
                  >
                    {recommendedLimit}
                  </Typography>
                  {requestedLimit && <Typography
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitBoxOrient: "horizontal",
                      width: "153px",
                      textAlign: "left",
                      fontSize: "12px",
                      color: "#2F3D63",
                      marginRight: "10px",
                      paddingRight: "10px",
                      fontWeight: "500",
                      paddingTop: 4,
                    }}
                  >
                    [Requested : {requestedLimit}]
                  </Typography>}
                  <Typography
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitBoxOrient: "horizontal",
                      color: "#777A82",
                      textAlign: "left",
                      fontSize: "14px",
                      paddingTop: "15px",
                    }}
                  >
                    Rationale:
                  </Typography>
                  <Typography
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitBoxOrient: "horizontal",
                      textAlign: "left",
                      fontSize: "12px",
                      color: "#2F3D63",
                      marginRight: "10px",
                      paddingRight: "10px",
                      fontWeight: "500",
                      paddingTop: 4,
                      paddingBottom: 20,
                    }}
                  >
                    {underwritingReason}
                  </Typography>
                </div>
                <ScoreCard
                  risk={riskScore}
                  alternative={alternativeScore}
                  delinquency={delinquencyScore}
                  legal={legalScore}
                  failure={failureScore}
                  country={countryScore}
                  altmanZ={altmanZScore}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: "5px",
                }}
              >
                <Box>
                  <Button
                    id="action-button"
                    aria-controls={open ? "action-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    variant="contained"
                    color="primary"
                    disabled={false}
                    sx={{
                      background: "#5186EC",
                      borderRadius: "6px",
                      backgroundColor: "#5186EC",
                      width: "130px",
                      height: "30px",
                      fontSize: "13px",
                      textTransform: "none",
                      fontWeight: "400",
                      padding: "2px",
                    }}
                    endIcon={<KeyboardArrowDownIcon />}
                    onClick={handleClick}
                  >
                    Risk Mitigations
                  </Button>
                  <Menu
                    id="action-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "action-button",
                    }}
                  >
                    {options.map((option) => (
                      <MenuItem
                        style={{
                          color: "#434343",
                          fontWeight: 400,
                          cursor: "pointer",
                          borderBottom: "1px solid #D5D5D5",
                        }}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
                <Box>
                  <Button
                    id="action-button"
                    aria-controls={open ? "action-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    variant="contained"
                    color="primary"
                    disabled={false}
                    sx={{
                      background: "#5186EC",
                      borderRadius: "6px",
                      backgroundColor: "#5186EC",
                      height: "30px",
                      fontSize: "13px",
                      textTransform: "none",
                      fontWeight: "400",
                      padding: "5px",
                      lineHeight: "13px",
                    }}
                    onClick={handleManualEvaluationClick}
                  >
                    Request Manual Evaluation
                  </Button>
                </Box>
                <div style={{ display: isDialogOpen ? "block" : "none" }}>
                  <RequestManualEvaluationDialog
                    title={title}
                    comment={comment}
                    email={email}
                    fullName={fullName}
                    phoneNumber={phoneNumber}
                    isDialogOpen={isDialogOpen}
                    selectedOption={selectedOption}
                    specificCreditLimit={specificCreditLimit}
                    handleTitleChange={handleTitleChange}
                    handleCloseDialog={handleCloseDialog}
                    handleCommentsChange={handleCommentsChange}
                    handleEmailChange={handleEmailChange}
                    handleFullNameChange={handleFullNameChange}
                    handleOptionChange={handleOptionChange}
                    handlePhoneNumberChange={handlePhoneNumberChange}
                    handleSpecificCreditLimitValue={
                      handleSpecificCreditLimitValue
                    }
                    handleSubmit={handleSubmit}
                  />
                </div>
              </div>
            </Card>
            <Card
              style={{
                flex: "1.5",
                minHeight: "335px",
                background:
                  "rgba(250, 250, 252, 1) 0% 0% no-repeat padding-box",
                boxShadow:
                  "inset 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 3px 6px rgba(0, 0, 0, 0.16)",
                borderRadius: "8px",
                opacity: 1,
                display: isAllFieldsInvalid ? "none" : "",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  padding: "0 25px",
                }}
              />
              <Grid
                container
                // spacing={1}
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(3, 1fr)",
                  gap: "10px",
                  justifyContent: "flex-start",
                  padding: "25px 25px",
                }}
              >
                {combinedInfoboxCard.map(
                  (data, index) =>
                    data.value &&
                    data.value !== undefined &&
                    data.value !== null &&
                    data.value && (
                      <Grid item key={index}>
                        <Typography
                          sx={{
                            color: "#777A82",
                            textAlign: "left",
                            fontSize: "14px",
                          }}
                        >
                          {data.label}
                        </Typography>
                        {data.label === "Description:" ? (
                          <Tooltip title={data.value} arrow>
                            <Typography
                              sx={{
                                color: "#2F3D63",
                                textAlign: "left",
                                fontSize: "12px",
                                fontWeight: "500",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: 3,
                                cursor: "default",
                              }}
                              component="p"
                            >
                              {data.value}
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography
                            style={{
                              color:
                                data.label === "Website:"
                                  ? "#1E90FF"
                                  : "#2F3D63",
                              textAlign: "left",
                              fontSize: "12px",
                              fontWeight: "500",
                              textDecoration:
                                data.label === "Website:"
                                  ? "underline"
                                  : "none",
                              cursor:
                                data.label === "Website:"
                                  ? "pointer"
                                  : "default",
                            }}
                            component={data.label === "Website:" ? "a" : "p"}
                            href={
                              data.label === "Website:"
                                ? data.value.startsWith("http://") ||
                                  data.value.startsWith("https://")
                                  ? data.value
                                  : `http://${data.value}`
                                : undefined
                            }
                            target={
                              data.label === "Website:" ? "_blank" : undefined
                            }
                            rel={
                              data.label === "Website:"
                                ? "noopener noreferrer"
                                : undefined
                            }
                          >
                            {data.value}
                          </Typography>
                        )}
                      </Grid>
                    )
                )}
              </Grid>
            </Card>
          </div>
          {AgingDataForCompany && (
            <>
            <Typography
              style={{
                textAlign: "left",
                fontSize: "20px",
                color: "#1A2A56",
                fontWeight: "600",
                width: "290px",
                height: "24px",
                padding: "25px 0",
              }}
            >
              Aging
            </Typography>
            <Box
              sx={{
                paddingTop: "20px",
                width: "100%",
                display: "flex",
                gap: "20px", 
              }}
            >
              <Card
                sx={{
                  width: "100%",
                  height: "275px",
                  background: "#FAFAFC",
                  boxShadow:
                    "inset 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 3px 6px rgba(0, 0, 0, 0.16)",
                  borderRadius: "8px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "20px",
                }}
              >
                <Box
                  sx={{
                    textAlign: "center",
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    flexWrap: "wrap",
                    position: "relative",
                    bottom: "18%",
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        color: "#777A82",
                        fontSize: "14px",
                        fontWeight: 500,
                        mb: 1,
                      }}
                    >
                      Paydex
                    </Typography>
                    <Typography
                      sx={{
                        color: "#2F3D63",
                        fontSize: "12px",
                        fontWeight: 500,
                        mb: 2,
                      }}
                    >
                      {paydex}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        color: "#777A82",
                        fontSize: "14px",
                        fontWeight: 500,
                        mb: 1,
                      }}
                    >
                      Avg. Payment Days
                    </Typography>
                    <Typography
                      sx={{
                        color: "#2F3D63",
                        fontSize: "12px",
                        fontWeight: 500,
                        mb: 2,
                      }}
                    >
                      {avgCustomerPaymentDays}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        color: "#777A82",
                        fontSize: "14px",
                        fontWeight: 500,
                        mb: 1,
                      }}
                    >
                      Tenure
                    </Typography>
                    <Typography
                      sx={{
                        color: "#2F3D63",
                        fontSize: "12px",
                        fontWeight: 500,
                      }}
                    >
                      {tenure}
                    </Typography>
                  </Box>
                </Box>
              </Card>
              <Card
                sx={{
                  width: "100%",
                  height: "275px",
                  background: "#FAFAFC",
                  boxShadow:
                    "inset 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 3px 6px rgba(0, 0, 0, 0.16)",
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    textAlign: "center",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#2F3D63",
                      fontSize: "16px",
                      fontWeight: 500,
                      mb: 3,
                    }}
                  >
                    Account Receivable
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-around",
                      padding: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#777A82",
                          fontSize: "14px",
                          fontWeight: 500,
                          mb: 1,
                        }}
                      >
                        Current
                      </Typography>
                      <Typography
                        sx={{
                          color: "#2F3D63",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        {currentArBalance}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#777A82",
                          fontSize: "14px",
                        fontWeight: 500,
                          mb: 1,
                        }}
                      >
                        In Arear
                      </Typography>
                      <Typography
                        sx={{
                          color: "#2F3D63",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        {arear}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#777A82",
                          fontSize: "14px",
                        fontWeight: 500,
                          mb: 1,
                        }}
                      >
                        Total
                      </Typography>
                      <Typography
                        sx={{
                          color: "#2F3D63",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        {totalArBalance}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-around",
                      padding: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#777A82",
                          fontSize: "14px",
                        fontWeight: 500,
                          mb: 1,
                        }}
                      >
                        AR Aging 30
                      </Typography>
                      <Typography
                        sx={{
                          color: "#2F3D63",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        {arBalance30}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#777A82",
                          fontSize: "14px",
                        fontWeight: 500,
                          mb: 1,
                        }}
                      >
                        AR Aging 60
                      </Typography>
                      <Typography
                        sx={{
                          color: "#2F3D63",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        {arBalance60}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#777A82",
                          fontSize: "14px",
                        fontWeight: 500,
                          mb: 1,
                        }}
                      >
                        AR Aging 90
                      </Typography>
                      <Typography
                        sx={{
                          color: "#2F3D63",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        {arBalance90}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      padding: "20px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        mt : "2px",
                      }}
                    >
                      <Typography
                        sx={{
                          mt: "-1px",
                          color: "#777A82",
                          fontSize: "14px",
                          fontWeight: 500,
                        }}
                      >
                        Last Updated
                      </Typography>
                      <Typography
                        sx={{
                          color: "#2F3D63",
                          fontSize: "13px",
                          fontWeight: 500,
                        }}
                      >
                        {lastUpdatedDate}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Card>
              <Card
                sx={{
                  width: "100%",
                  height: "275px",
                  background: "#FAFAFC",
                  boxShadow:
                    "inset 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 3px 6px rgba(0, 0, 0, 0.16)",
                  borderRadius: "8px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "20px",
                }}
              >
                <Box
                  sx={{
                    textAlign: "center",
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    flexWrap: "wrap",
                    position: "relative",
                    bottom: "18%",
                  }}
                >
                  <Box>
                      <Typography
                        sx={{
                          color: "#777A82",
                          fontSize: "14px",
                          fontWeight: 500,
                          mb: 1,
                        }}
                      >
                        Terms Recommended
                      </Typography>
                      <Typography
                        sx={{
                          color: "#2F3D63",
                          fontSize: "12px",
                          fontWeight: 500,
                          mb: 2,
                        }}
                      >
                        {termsRecommended}
                      </Typography>
                  </Box>
                  <Box>
                      <Typography
                        sx={{
                          color: "#777A82",
                          fontSize: "14px",
                          fontWeight: 500,
                          mb: 1,
                        }}
                      >
                        Terms Actual
                      </Typography>
                      <Typography
                        sx={{
                          color: "#2F3D63",
                          fontSize: "12px",
                          fontWeight: 500,
                          mb: 2,
                        }}
                      >
                        {currentRecommended}
                      </Typography>
                  </Box>
                </Box>
              </Card>
            </Box>
            </>
          )}
        </>
      )}
    </Box>
  );
}

export default ExecutiveSummary;
