import React, { useState, useRef, useEffect,useCallback } from "react";
import Box from "@mui/material/Box";
import { styled } from '@mui/material/styles';
import { API, graphqlOperation, Amplify } from "aws-amplify";
import {
  companiesByName,
  dnbSearchCompany,
  getTenant,
} from "./graphql/queries";
import { createCompany, updateCompany } from "./graphql/mutations";
import Modal from "@mui/material/Modal";
import AddIcon from "@mui/icons-material/Add";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { publish } from "./events";
import Stack from "@mui/material/Stack";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import RightIcon from "./Assets/Images/right.svg";
import LeftIcon from "./Assets/Images/left.svg";
import CompletedIcon from "./Assets/Images/completed.svg";
import Tick from "./Assets/Images/tick.svg";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItem,
  ListItemButton,
  Select,
  MenuItem,
  InputLabel,
  Autocomplete,
} from "@mui/material";
import AddCompanyManually from "./CreateCompanyManuallyModal";
import { toast } from "react-toastify";
import { PubSub } from "@aws-amplify/pubsub";
import awsExports from "./aws-exports";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import { BorderClear } from "@mui/icons-material";
import { CountryISOCodes } from "./utils/CountryISOCodes";
import ReactGA from "react-ga4"
import AWS from 'aws-sdk'
import getTenantIdFromURL from "./utils/getTenantIdFromURL";
import { useSelector } from "react-redux";

//stepper-changes
import Fab from '@mui/material/Fab';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import { FormGroup, FormControlLabel, FormLabel } from '@mui/material';
import Close from "./Assets/Images/close.svg"
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import PropTypes from 'prop-types';
import { useAddCompanyModal } from "./context/addCompanyModalContext";

const CustomCheckbox = styled(Checkbox)({
  '&.Mui-checked': {
    color: '#5186EC', 
  },
  '& .MuiSvgIcon-root': {
    borderRadius: "4px",
    width:"15px"
  },
});

const trackingID = "G-9QZ6JYFX19";
const adminTrackingId = "G-VYW7DRSVZE";

Amplify.configure(awsExports);

AWS.config.update(
  {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  }
);

function CreateCompanyModal() {
  const [companyNameValue, setCompanyNameValue] = useState("");
  const [requestedCreditLimit, setRequestedCreditLimit] = useState("");
  const [companyDnbId, setCompanyDnbId] = useState("");
  const [companySearchResults, setCompanySearchResults] = useState([]);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(false);
  const [errorTxt, setErrorTxt] = useState("This field is required");
  const [open, setOpen] = React.useState(false);
  const [isAddCompanyManuallyModelOpen, setIsAddCompanyManuallyModelOpen] = useState(false);
  const [dnbJsonData, setDnbJsonData] = useState([]);
  const [isPublic, setIsPublic] = useState(false);
  const [tickerValue, setTickerValue] = useState("");

  // State Variable used to Filter Search Result
  const [companyCountryValue, setCompanyCountryValue] = useState("");
  const [companyStateValue, setCompanyStateValue] = useState("");
  const [companyStreetValue, setCompanyStreetValue] = useState("");
  const [companyZipCode, setCompanyZipCode] = useState("");
  const [currentTenant, setCurrentTenant] = useState(null);

  //Parent company values
  const [parentCompanyName, setParentCompanyName] = useState("");
  const [parentCompanyCountry, setParentCompanyCountry] = useState("");
  const [parentCompanyDailog, setParentCompanyDailog] = useState(false);
  const [addParentConpany, setAddParentCompany] = useState(false);
  const [showParentCompanySearchResultsDialog, setShowParentCompanySearchResultsDialog] = useState(false);
  const [parentCompanySearchResults, setParentCompanySearchResults] = useState([]);
  const [parentSelectedCompany, setParentSelectedCompany] = useState("");
  const [addCompanyWithoutParent, setAddCompanyWithoutParent] = useState(true);

  const [companyRegistrationNumber, setCompanyRegistrationNumber] = useState("");
  const [countryISOCode, setCountryISOCode] = useState("");

  const [modalOpen, setModalOpen] = useState(false);
  const [searchOption, setSearchOption] = useState(null);
  const [tenantId,setTenantId] = useState()

  const isAdmin = useSelector(state => state.userInfo.isAdmin);
  const URLTenant = getTenantIdFromURL();
  const currentTenantId  = useSelector(state => state.userInfo.tenantId);
  const tenantEmail = useSelector(state => {
    const email = state.userInfo.email;
    if (email) {
      const username = email.split('@')[0];
      return username.length > 5 ? `${username.slice(0, 5)}..` : username;
    }
    return null;
  });
  
  const selectTenantId =()=>{
    if(isAdmin) setTenantId(URLTenant)
    else setTenantId(currentTenantId)
  }
  const fileInputRef = useRef(null);

  const tickerValueLength = tickerValue.length === 0;
  const isSearchDisabledBasedOnTicker = isPublic ? tickerValueLength : false;

  // initialize react google analytics tracking id based on role of user

 //stepper-changes
 const [activeStep, setActiveStep] = useState(0);
 const [selectedOption, setSelectedOption] = useState(null); 
 const [completed, setCompleted] = useState(false);
 const [showLeftButton, setShowLeftButton] = useState(false);
 const {openAddCompanyModal,setOpenAddCompanyModal}=useAddCompanyModal();
 const [creditLimits, setCreditLimits] = useState({});

 const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor:"#2F3D63"
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor:"#2F3D63"
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 0,
    border: '1px solid #2F3D63',
    backgroundColor:"#2F3D63",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : "#E9E9E9",
  zIndex: 1,
  color: '#fff',
  width: 45,
  height: 45,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow: '0px 3px 6px #00000029',
  ...(ownerState.active && {
    backgroundColor: "#2F3D63",
    boxShadow: '0px 3px 6px #00000029',
    border: "2px solid #5186EC",
  }),
  ...(ownerState.completed && {
    backgroundColor: "#2F3D63",
    boxShadow: '0px 3px 6px #00000029',
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className, icon } = props;

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {completed ? (
        <img src={Tick} width={25} alt="" />
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <span style={{ color: active ? "#fff" : '#777A82', fontSize: "18px", fontWeight: "500" }}>{icon}</span>
        </div>
      )}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};


   const listRef = useRef(null);
 const [isScrolling, setIsScrolling] = useState(false); 

 const handleScroll = () => {
   if (listRef.current) {
     const newScrollLeft = listRef.current.scrollLeft; 
     setShowLeftButton(newScrollLeft > 0); 
     setIsScrolling(false); 
   }
 };

 const scrollListLeft = useCallback(() => {
   if (listRef.current) {
     const scrollAmount = 705; 
     const newScrollLeft = Math.max(0, listRef.current.scrollLeft - scrollAmount);
     listRef.current.scrollTo({
       left: newScrollLeft,
       behavior: 'smooth',
     });

     setIsScrolling(true); 
     listRef.current.addEventListener('scroll', handleScroll); 
   }
 }, [listRef]); 

 const scrollListRight = useCallback(() => {
   if (listRef.current) {
     const scrollAmount = 705; 
     listRef.current.scrollTo({
       left: listRef.current.scrollLeft + scrollAmount,
       behavior: 'smooth',
     });
     listRef.current.addEventListener('scroll', handleScroll);
   }
 }, [listRef]); 

 useEffect(() => {
   return () => {
     if (listRef.current) {
       listRef.current.removeEventListener('scroll', handleScroll);
     }
   };
 }, [listRef]); 
 const handleNext = () => {
   setActiveStep((prevActiveStep) => prevActiveStep + 1);
 };

 const handleBack = () => {
   setActiveStep((prevActiveStep) => {
     switch (prevActiveStep+1) {
       case 2:
         setCompanyRegistrationNumber("")
         setCompanyNameValue("")
         setTickerValue("")
         setCountryISOCode("")
         setShowLeftButton(false); 
         break;
       case 3:
         setSelectedOption(null)
         setSelectedCompanies([]);
         setDisabled(false)
         setShowLeftButton(false); 
         break;
       case 4:
         
         break;
       default:
         break;
     }
     return prevActiveStep - 1;
   });
 };
 const handleContinue = () => {
   setActiveStep((prevActiveStep) => {
     if((prevActiveStep + 1)<=steps.length-1) return prevActiveStep + 1;
     else return prevActiveStep
   });
 };
 const handleChange = (value) => {
   setSelectedOption(value);
 };
 const steps = ["one","two","three","four"]

 const style = {
   position: "absolute",
   top: "50%",
   left: "50%",
   transform: "translate(-50%, -50%)",
   width: "833px",
   bgcolor: "background.paper",
   borderRadius:"20px",
   boxShadow: "inset 0px 1px 3px #0000001A, 0px 3px 6px #00000029",
 };
const formControlLabelStyle={
 '& .MuiFormControlLabel-label': {
   fontSize: '14px', 
 },
 margin:"0px"
}
 const inputStyle={
   width:"285px",
   '& .MuiOutlinedInput-root': {
     borderRadius: '8px', 
     height: '48px',      
   },
   "& .MuiOutlinedInput-input":{
     borderRadius: '8px', 
     height: '12px',
   },
   '& .MuiOutlinedInput-notchedOutline': {
     borderRadius: '8px', 
   },
   marginTop:"10px"
 }
 const questionInputStyle={
   width:"285px",
   '& .MuiOutlinedInput-root': {
     borderRadius: '8px', 
     height: '48px',      
   },
   "& .MuiOutlinedInput-input":{
     borderRadius: '8px', 
     height: '12px',
   },
   '& .MuiOutlinedInput-notchedOutline': {
     borderRadius: '8px', 
   },
   marginBottom:"15px"
 }

 const stepStyle = {
   "& .MuiStepLabel-root": {
     color: "#2F3D63"
   },
   "& .Mui-completed": {
     color: "#2F3D63"
   },
   "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel": {
     color: "#fff"
   },
   "& .MuiStepLabel-root .Mui-active": {
     color: "#2F3D63"
   },
   "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel": {
     color: "#fff"
   },
   "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
     fill: "#fff"
   },
   "& .MuiStepConnector-root": {
     backgroundColor:"#2F3D63",height:"1px"
   },
   "& .MuiStepConnector-line": {
     backgroundColor:"#2F3D63",height:"1px"
   },
 };


 const modalStyle = { 
   backdropFilter: "blur(2px)", 
   backgroundColor: "rgb(255, 255, 255, 0.5)" 
 }

 const stepperStyle ={
   background:"#5186EC1A",
   height:"82px",
   display:"flex",
   justifyContent:"center",
   alignItems:"center"
 }

 const fabStyle={
   margin:0,
   width:"45px",
   height:"45px",
   backgroundColor:"#5186EC"
 }

 const containerStyle ={
   width:"325px",
   height:"100%",
   display:"flex",
   boxSizing:"border-box",
   padding:"20px",
   flexDirection:"column",
   // alignItems:"center",
   position:"relative",
   justifyContent:"space-around",
 }
 const companyListStyle={
  //  height:"263px",
   minWidth:"225px",
   maxWidth:"225px",
   border:"1px solid #E9E9E9",
   boxShadow: "0px 1px 3px #00000029",
   borderRadius:"10px",
   display:"flex",
   flexDirection:"column",
   justifyContent:"space-between",
   alignItems:"center"
   // margin:"5px",
 }
 const dropdownStyle= {
   "& .MuiOutlinedInput-root": {
     "&.Mui-focused fieldset": {
     },
   },
   "& .MuiAutocomplete-option": {
     fontSize: "0.875rem",
     padding: "0.5rem",
   },
   "& .MuiAutocomplete-options": {
     backgroundColor: "#f5f5f5",
   },
 }
 const backStyle ={
   width: "138px",
   height: "36px",
   border: "1px solid #5186EC",
   borderRadius: "8px",
   
   
   
 }
 const questionStyle={
   textAlign: "left",
   fontSize: "14px",
   letterSpacing: "0px",
   color: "#2F3D63",
   alignItems: "flex-start",

 }
 const searchStyle ={
   width: "138px",
   height: "36px",
   marginLeft:"10px",
   borderRadius: "8px",
   backgroundColor:"#5186EC",
   '&:disabled': {
      backgroundColor: '#f0f0f0', // Set the color for the disabled state
    },
    '&:hover': {
      backgroundColor: '#5186EC', // Same color on hover
    },
 }

 const buttonsStyle = {
   height:"250px",
   display:"flex",
   justifyContent:"center",
   alignItems:"center",
   gap:"20px"
 }
 const buttonStyle = {
   width:"132px",
   height:"44px",
   borderRadius:"8px",
   backgroundColor:"#5186EC",
 }
const handleForward=()=>{
   setActiveStep((prevActiveStep) => {
     setError(false)
     if((prevActiveStep + 1)<=steps.length-1) return prevActiveStep + 1;
     else return prevActiveStep;
   });

}

 const handleAddAnotherCompany = ()=>{
   handleClose();
   setActiveStep(0);
 }

 const handleAddCompany = (event)=>{
   handleAddClick();
   handleClose();
   if(selectedOption=="Prospect"){ setCompleted(true); setActiveStep(4)}
   handleForward();
 }


  useEffect(() => {
    async function run (){
      await getCurrentTenant();
    }
    run();
    selectTenantId();
  }, [tenantId]);

  useEffect(() => {
    // const isAdmin = async ()=>{
    //   const admin = await isAdminAuth0();
    //   setAdminUser(admin);
    // }
    // isAdmin();
  }, []);



  const handleAddCompanyClick = async () => {
    let operation = "";
    if (parentSelectedCompany === null) {
      setAddCompanyWithoutParent(true);
    }
    if (selectedCompanies.length === 0) {
      toast.error("No Company Selected");
      setError(true);
      setDisabled(false);
      return;
    }
    setDisabled(true);
    for (let selectedCompany of selectedCompanies) {
      let result = await API.graphql(
        graphqlOperation(companiesByName, {
          name: selectedCompany.organization.primaryName,
          limit: 1,
          filter: {
            tenantId: {
              eq: tenantId,
            },
          },
        })
      );
      let company = null;
      if (
        result.data.companiesByName &&
        result.data.companiesByName.items.length > 0
      ) {
        company = result.data.companiesByName.items[0];
        await API.graphql(
          graphqlOperation(updateCompany, {
            input: {
              tenantId: tenantId,
              id: company.id,
              dnbCompanyId: selectedCompany.organization.duns,
              view: 1,
              ticker: selectedCompany?.organization?.tickerSymbol || "",
              requestedCreditLimit: parseFloat(creditLimits[selectedCompany?.organization?.duns]),
              parentCompanyName: parentSelectedCompany?.organization?.primaryName || "",
              parentDnbCompanyId: parentSelectedCompany?.organization?.duns || "",
              parentCountry: parentSelectedCompany?.organization?.primaryAddress?.addressCountry?.name || "",
              parentTicker: parentSelectedCompany?.organization?.tickerSymbol || "",
              
            },
          })
        );
        operation = "update";
      } else {
        result = await API.graphql(
          graphqlOperation(createCompany, {
            input: {
              tenantId: tenantId,
              name: selectedCompany.organization.primaryName,
              dnbCompanyId: selectedCompany.organization.duns,
              country:
                selectedCompany?.organization?.primaryAddress?.addressCountry
                  ?.name,
              requestedCreditLimit:parseFloat(creditLimits[selectedCompany?.organization?.duns]),
              view: 1,
              ticker: selectedCompany?.organization?.tickerSymbol || "",
              parentCompanyName: parentSelectedCompany?.organization?.primaryName || "",
              parentDnbCompanyId: parentSelectedCompany?.organization?.duns || "",
              parentCountry: parentSelectedCompany?.organization?.primaryAddress?.addressCountry?.name || "",
              parentTicker: parentSelectedCompany?.organization?.tickerSymbol || "",
            },
          })
        );
        company = result.data.createCompany;
        operation = "create";
      }
      if (!company) {
        setError(true);
        setErrorTxt(`The company: (${companyNameValue}) already exists`);
        return;
      }
      console.log("operation", operation);
      if (operation === "create") {
        toast.success(`${company?.name} Added Successfully!`, {
          bodyStyle: {
            fontWeight: "bold",
          },
        });
      } else if (operation === "update") {
        toast.success(`${company?.name} Updated Successfully!`, {
          bodyStyle: {
            fontWeight: "bold",
          },
        });
      }
      publish("create_company", company);
      setError(false);
      setDisabled(false);
      setShowSearchResults(false);
      setOpen(false);
      setCompanyCountryValue("");
      setCompanyStateValue("");
      setCompanyStreetValue("");
      setCompanyZipCode("");
      setTickerValue("");
      setIsPublic(false);
    }
    setParentSelectedCompany(null);
    setParentCompanyName(null);
    setParentCompanyCountry(null);
    setSelectedCompanies([]);

  };

  const handleSelectClick = async (company) => {
    setParentSelectedCompany(company);
    return null;
  }
  const handleCountryISOCodeChange = (event , value) => {
    // setCountryISOCode(value["alpha-2"]);
    if (value) {
      console.log(value['alpha-2']);
      setCountryISOCode(value['alpha-2']);
    } else {
      setCountryISOCode(null);
    }
  }

  const handleYesClick = async () => {
    setAddCompanyWithoutParent(false);
    setAddParentCompany(true);
  }

  const handleNoClick = async () => {
    handleAddCompanyClick();
    setAddParentCompany(false);
    setParentCompanyDailog(false);
    setShowSearchResults(false);
    setShowParentCompanySearchResultsDialog(false);
  }

  const handleAddClick = async () => {
    handleAddCompanyClick();
    // setParentCompanyDailog(true);
  };

  const handleClose = async () => {
    // setParentCompanyCountry("");
    // setParentCompanyName("");
    // setParentSelectedCompany(null);
    // setOpen(false);
    setError(false);
    setSelectedOption(null)
    setCompanyRegistrationNumber("");
    setError(false);
    setDisabled(false);
    setShowSearchResults(false);
    setOpen(false);
    setCompanyCountryValue("");
    setCompanyStateValue("");
    setCompanyStreetValue("");
    setCompanyZipCode("");
    setTickerValue("");
    setIsPublic(false);
    publish("create_company", {});
    setSelectedCompanies([]);
  };

  const handleAddParentCompany = async () => {
    console.log("Add parent company:", parentCompanyName);
    handleAddCompanyClick();
    setParentCompanyDailog(false);
    setAddParentCompany(false);
    setShowParentCompanySearchResultsDialog(false);
  };

  const searchParentCompany = async () => {
    if (parentCompanyName.trim() === "") {
      setError(true);
      setDisabled(false);
      return;
    }
    let result = await API.graphql(
      graphqlOperation(dnbSearchCompany, {
        name: parentCompanyName,
        tenantId: tenantId,
        isPaid: currentTenant?.isPaid,
      })
    );
    let company = null;
    let searchResults = undefined;

    if (result.data.dnbSearchCompany) {
      searchResults = JSON.parse(result.data.dnbSearchCompany).body
        .searchCandidates;
    }

    console.log("Search Result --> ", searchResults);



    if (searchResults === undefined) {
      processNameAndSearchAgain(parentCompanyName);
    } else {
      const filteredResult = searchResults
        ?.filter((element) => {
          return (
            (element?.organization?.primaryName
              ?.toLowerCase()
              ?.includes(parentCompanyName?.toLowerCase()) &&
              element?.organization?.primaryAddress?.addressCountry?.name?.toLowerCase() ===
              parentCompanyName.toLowerCase()) ||
            element?.organization?.primaryAddress?.streetAddress?.line1
              ?.toLowerCase()
              ?.includes(parentCompanyCountry?.toLowerCase())
          );
        })
        .sort(customSort);

      console.log("Filter Result --> ", filteredResult);
      setParentCompanySearchResults(filteredResult);
    }

    insertDnbDataToJson(searchResults);
    if (searchResults !== undefined) {
      setParentCompanySearchResults(searchResults);
      setShowParentCompanySearchResultsDialog(true);
    } else {
      setShowParentCompanySearchResultsDialog(false);
    }
  };

  const toggleAddCompanyManuallyModal = () => {
    if(isAddCompanyManuallyModelOpen)handleOptionsModalClose();
    setIsAddCompanyManuallyModelOpen(!isAddCompanyManuallyModelOpen);
    
  };

  const processNameAndSearchAgain = async (companyName) => {
    const pattern = /[^a-zA-Z0-9\s]/;
    let companyFinalName = "";

    if (pattern.test(companyName)) {
      const specialCharacters = ["-", "!", "$", "&", ",", "@", "%", "!", "."];
      const inputString = companyName?.trim();

      // Step 1: Omitting Special Characters
      const regex = new RegExp(`[${specialCharacters.join("\\")}]`, "g");
      const stringWithoutSpecialChars = inputString.replace(regex, "");

      // Step 2: Truncating the Last Expression(Word) of the Name
      const words = stringWithoutSpecialChars.split(" ");
      const truncatedName = words.slice(0, -1).join(" ");

      // Step 3: Truncating Anything Past a Special Character
      const indexOfSpecialChar = truncatedName.indexOf(specialCharacters[0]);
      const finalResult =
        indexOfSpecialChar !== -1
          ? truncatedName.slice(0, indexOfSpecialChar)
          : truncatedName;
      companyFinalName = finalResult;
    } else {
      companyFinalName = companyName;
    }

    console.log("Company Final Name -> ", companyFinalName);
    let result = await API.graphql(
      graphqlOperation(dnbSearchCompany, {
        name: companyFinalName,
        tenantId: tenantId,
        isPaid: currentTenant?.isPaid,
      })
    );

    let searchResults = undefined;
    if (result.data.dnbSearchCompany) {
      
      searchResults = JSON.parse(result.data.dnbSearchCompany).body
        .searchCandidates;
    }
    console.log("Search Results",searchResults)

    if (searchResults !== undefined) {
      setShowSearchResults(true);
      setCompanySearchResults(searchResults);
      setDisabled(false);
    } else {
      setCompanySearchResults([]);
      setDisabled(false);
    }
    setActiveStep(2);
  };

  const getCurrentTenant = async () => {
    if(tenantId) {
      try {
        const response = await API.graphql(
          graphqlOperation(getTenant, {
            id: tenantId,
          })
        );
        console.log("Current Tenant -> ", response.data?.getTenant);
        setCurrentTenant(response?.data?.getTenant);
      } catch (error) {
        console.error("Error fetching tenant:", error);
      }
  }
  };

  const searchCompany = async () => {
    
    ReactGA.event({
      category: isAdmin ? 'Admin User' : 'User',
      action: isAdmin ? `Search_Comp-${tenantEmail}_admin_${currentTenant?.name}` : `Search_Comp-${tenantEmail}_${currentTenant?.name}`
    });

    const isSearchWithRegistrationNumber = disableAllFields;

    let result;
    setDisabled(true);

    if ((companyNameValue.trim() === "" && companyRegistrationNumber === "")) {
      console.log("Entered in If Country Name Condition");
      setError(true);
      setDisabled(false);
      return;
    }
    if (isSearchWithRegistrationNumber) {
      console.log("Entered in Registration Number Search");
      result = await API.graphql(
        graphqlOperation(dnbSearchCompany, {
          name: companyNameValue,
          tenantId: tenantId,
          isPaid: currentTenant?.isPaid,
          searchType: "SearchWithRegistrationNumber",
          registrationNumber: companyRegistrationNumber,
          iso2Code: countryISOCode,
        })
      );
    }
    else {
      result = await API.graphql(
        graphqlOperation(dnbSearchCompany, {
          name: companyNameValue,
          tenantId: tenantId,
          isPaid: currentTenant?.isPaid,
        })
      );
    }

    let company = null;
    let searchResults = undefined;

    console.log("Search Company Search Result --> ", JSON.parse(result?.data?.dnbSearchCompany));

    if (result.data.dnbSearchCompany) {
      searchResults = isSearchWithRegistrationNumber ? JSON.parse(result.data.dnbSearchCompany).body
        ?.matchCandidates : JSON.parse(result.data.dnbSearchCompany).body
        ?.searchCandidates;
      // searchResults = JSON.parse(result.data.dnbSearchCompany).body
      //   .searchCandidates;
    }

    console.log("Search Result --> ", searchResults);

    if (searchResults === undefined) {
      processNameAndSearchAgain(companyNameValue);
    } else {
      const filteredResult = searchResults
        ?.filter((element) => {
          return (
            (element?.organization?.primaryName
              ?.toLowerCase()
              ?.includes(companyNameValue?.toLowerCase()) &&
              element?.organization?.primaryAddress?.addressCountry?.name?.toLowerCase() ===
              companyCountryValue?.toLowerCase()) ||
            element?.organization?.primaryAddress?.postalCode ===
            companyZipCode ||
            element?.organization?.primaryAddress?.streetAddress?.line1
              ?.toLowerCase()
              ?.includes(companyStreetValue?.toLowerCase())
          );
        })
        .sort(customSort);

      console.log("Filter Result --> ", filteredResult);

      setCompanySearchResults(filteredResult);
      setActiveStep(2);
    }

    insertDnbDataToJson(searchResults);
    // if (searchResults !== undefined) {
    //   setShowSearchResults(true);
    //   setDisabled(false);
    // } else {
    //   // setDisabled(false);
    // }
  };

  const customSort = (companyObj1, companyObj2) => {
    const aMatches =
      companyObj1?.organization?.primaryName
        ?.toLowerCase()
        ?.includes(companyNameValue?.toLowerCase()) &&
      (!companyCountryValue ||
        companyObj1?.organization?.primaryAddress?.addressCountry?.name?.toLowerCase() ===
        companyCountryValue?.toLowerCase()) &&
      (!companyZipCode ||
        companyObj1?.organization?.primaryAddress?.postalCode ===
        companyZipCode) &&
      companyObj1?.organization?.primaryAddress?.streetAddress?.line1
        ?.toLowerCase()
        ?.includes(companyStreetValue?.toLowerCase());

    const bMatches =
      companyObj2?.organization?.primaryName
        ?.toLowerCase()
        ?.includes(companyNameValue?.toLowerCase()) &&
      (!companyCountryValue ||
        companyObj2?.organization?.primaryAddress?.addressCountry?.name?.toLowerCase() ===
        companyCountryValue?.toLowerCase()) &&
      (!companyZipCode ||
        companyObj2?.organization?.primaryAddress?.postalCode ===
        companyZipCode) &&
      companyObj2?.organization?.primaryAddress?.streetAddress?.line1
        ?.toLowerCase()
        ?.includes(companyStreetValue?.toLowerCase());

    if (aMatches && !bMatches) {
      return -1; // 'a' comes first if it matches and 'b' does not
    } else if (!aMatches && bMatches) {
      return 1; // 'b' comes first if 'a' does not match and 'b' matches
    } else {
      return 0; // no priority, maintain current order
    }
  };

  const handleCompanyNameChange = (event) => {
    setCompanyNameValue(event.target.value);
  };



  const handleModalOpen = () => {
    setDisabled(false);
    // setOpen(true);
    setShowSearchResults(false);
    setCompanyNameValue("");
    setCompanyCountryValue("");
    setRequestedCreditLimit("");
    setCompanyDnbId("");
    setCompanySearchResults([]);
    setError(false);
    setTickerValue("");
    setIsPublic(false);
    setCompanyRegistrationNumber("");
    setCountryISOCode("");
  };

  const handleModalClose = (event) => {
    // setOpen(!open);
    setModalOpen(false)
    setDisabled(false);
  };

  const handleCloseClick = () => {
    publish("create_company", {});
    setOpen(false);
    setCompanyCountryValue("");
    setCompanyStateValue("");
    setCompanyStreetValue("");
    setCompanyZipCode("");
    setError(false);
    setTickerValue("");
    setIsPublic(false);
    setCompanyRegistrationNumber("");
    setCountryISOCode("");
  };

  const insertDnbDataToJson = async (dnbData) => {
    if (dnbData) {
      const jsonDataArray = [];
      try {
        dnbData.forEach((data) => {
          jsonDataArray.push(data.organization);
        });

        // Filter and Merge Unique Object in an Array
        const uniqueNamesSet = new Set();
        const s3 = new AWS.S3();

        // Merge arrays while filtering out duplicate names
        const mergedArray = [...jsonDataArray, ...dnbJsonData].filter((obj) => {
          const isUnique = !uniqueNamesSet.has(parseInt(obj.duns));
          uniqueNamesSet.add(parseInt(obj.duns));
          return isUnique;
        });

        const jsonString = JSON.stringify(mergedArray);

        const result = await s3.putObject({
          Bucket: 'crediarc-content01853-production', // Specify your bucket name
          Key: 'public/dnbData.json',
          Body: jsonString,
          ContentType: 'application/json',
        }).promise();
    
        console.log("dnbData File Uploaded Successfully:", result);
      } catch (error) {
        console.error("Error Uploading dnbData Json File: ", error);
      }
    }
  };

  const fetchDnbDataFromJson = async () => {
    try {
      const s3 = new AWS.S3();
      const key = 'public/dnbData.json'

      const data = await s3.getObject({ Bucket: "crediarc-content01853-production", Key: key }).promise();
      const fileData = data.Body.toString('utf-8');
      const summary = JSON.parse(fileData);

      for (let i = 0; i < summary.length; i++) {
        const row = summary[i];
        for (const key in row) {
          if (typeof row[key] === "string") {
            row[key] = isNaN(parseFloat(row[key].replace(/,/g, "")))
              ? row[key]
              : parseFloat(row[key].replace(/,/g, ""));
          }
        }
      }
      setDnbJsonData(summary);
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const handleCheckboxChange = (company) => {
    if (selectedCompanies.some((selected) => selected.organization.duns === company.organization.duns)) {
      setSelectedCompanies(selectedCompanies.filter((selected) => selected.organization.duns !== company.organization.duns));
      
      const { [company.organization.duns]: _, ...updatedCreditLimits } = creditLimits;
      setCreditLimits(updatedCreditLimits);
    } else {
      setSelectedCompanies([...selectedCompanies, company]);
  
      setCreditLimits((prev) => ({
        ...prev,
        [company.organization.duns]: '',
      }));
    }
  };
  const handleRequestedCreditLimitChange = (company, value) => {
    console.log("company",company)
    setCreditLimits((prev) => ({
      ...prev,
      [company.organization.duns]: value,
    }));
  };

  const handleIsCompanyPublicChange = (value) => {
    setIsPublic(value);
    if (!value) setTickerValue("");
  };

  const handleTickerValueChange = (event) => {
    setTickerValue(event.target.value);
  };

  const handleCompanyRegistrationNumber = (event) => {
    setCompanyRegistrationNumber(event.target.value);
  }

  const disableAllFields = companyRegistrationNumber?.length > 0;

  const handleOptionsModalOpen = () => {
    
    ReactGA.event({
      category: isAdmin ? 'Admin User' : 'User',
      action: isAdmin ? `Add_Comp-${tenantEmail}_admin_${currentTenant?.name}` : `Add_Comp-${tenantEmail}_${currentTenant?.name}`
    });

    setModalOpen(true);
  };

  const handleOptionsModalClose = () => {
    setSelectedOption(null)
    setSelectedCompanies([])
    setActiveStep(0);// reset stepper
    setCompleted(false);
    setModalOpen(false);
    setShowLeftButton(false); 
    setOpenAddCompanyModal(false)
  };

  const handleSearchOptionChange = (option) => {
    setSearchOption(option);
    // setModalOpen(false);
    handleModalOpen();
  };

  const handleCountryValueChange = (event, value) => {
    setCompanyCountryValue(value?.name);
  }
  return (
    <React.Fragment>
      <div>
        <Fab
          color="primary"
          aria-label="add"
          style={fabStyle}
          onClick={handleOptionsModalOpen}
        >
          <AddIcon />
        </Fab>
        <Modal open={modalOpen||openAddCompanyModal} style={modalStyle} onClose={handleOptionsModalClose}>
          <div style={{color:"#2F3D63"}}>
            <Box sx={style}>
              <button style={{position:"absolute",top:"20px",left:"30px",}} onClick={handleOptionsModalClose}><img style={{width:"23px",height:"23px"}} src={Close} alt="" /></button>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{
                  textAlign: "center",
                  padding: 2,
                  color: "#2F3D63"
                }}
              >
                Add Company
              </Typography>
              <Stepper activeStep={activeStep} alternativeLabel connector={<ColorlibConnector />} style={stepperStyle}>
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={ColorlibStepIcon} icon={index + 1} ></StepLabel>
                  </Step>
                ))}
              </Stepper>
              <div style={{display:"flex",justifyContent:"center"}}>
                {activeStep === 0 && (
                  <div>
                    <Typography sx={{
                      textAlign: "center",
                      fontWeight: "bold",
                      color:"#2F3D63",
                      position:"absolute",
                      top:"55%",
                      left:"50%",
                      transform:"translate(-50%,-50%)"
                    }}>
                      Search By
                    </Typography>
                    <div style={buttonsStyle}>
                      <Button style={buttonStyle} variant="contained" onClick={() => {
                        handleSearchOptionChange("name");
                        handleNext();
                      }}>
                        Name
                      </Button>
                      <Button style={buttonStyle} variant="contained" onClick={() => {
                        handleSearchOptionChange("id");
                        handleNext();
                      }}>
                        ID
                      </Button>
                      <Button style={buttonStyle} variant="contained" onClick={() => {
                        handleSearchOptionChange("ticker");
                        handleNext();
                      }}>
                        Ticker
                      </Button>
                    </div>
                  </div>
                )}
                {activeStep === 1 && searchOption === "name" && (
                  <div style={containerStyle}>
                      <Typography sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        color:"#2F3D63",
                        marginBottom:"20px"
                        // position:"absolute",
                        // top:"55%",
                        // left:"50%",
                        // transform:"translate(-50%,-50%)"
                    }}>
                      Search By Name
                    </Typography>
                    <Typography>Company Name</Typography>
                    <TextField
                      id="companyName"
                      sx={inputStyle}
                      helperText={error ? errorTxt : ""}
                      label=""
                      variant="outlined"
                      required={true}
                      value={companyNameValue}
                      onChange={handleCompanyNameChange}
                      error={error}
                    />
                    <FormControl
                      sx={{ display: "inline-flex", flexDirection: "column", marginTop: 2 }}
                    >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent:"space-between",
                        alignItems:"center",
                        marginTop: "4px",
                        marginBottom: "8px",
                      }}
                    >
                      <Typography
                        variant="body1"
                        style={{ marginTop: "9px", marginRight: "10px",fontSize: "14px" }}
                      >
                        Is Company Public?
                      </Typography>
                      <div>
                      <Button
                        // variant={!isPublic ? "contained" : "outlined"}
                        color="primary"
                        onClick={() => handleIsCompanyPublicChange(false)}
                        style={{
                          marginLeft: "10px",
                          height:"28px",
                          width:"61px",
                          background: isPublic ? "#fff" : "#5186EC4D 0% 0% no-repeat padding-box",
                          color: "#5186EC",
                          border: "1px solid #5186EC",
                          borderRadius: "10px"
                        }}
                      >
                        No
                      </Button>
                      <Button
                        // variant={isPublic ? "contained" : "outlined"}
                        color="primary"
                        style={{
                          marginLeft: "10px",
                          height:"28px",
                          width:"61px",
                          background: isPublic ? "#5186EC4D 0% 0% no-repeat padding-box" : "#fff",
                          color: "#5186EC",
                          border: "1px solid #5186EC",
                          borderRadius: "10px"
                        }}
                        onClick={() => handleIsCompanyPublicChange(true)}
                      >
                        Yes
                      </Button>

                      </div>
                    </div>
                        <div>
                          {/* <TextField
                            fullWidth
                            label="Ticker*"
                            variant="standard"
                            value={tickerValue}
                            onChange={handleTickerValueChange}
                          /> */}
                          <div style={{ marginTop: "20px" }}>
                            <FormControl fullWidth>
                              <Autocomplete
                                id="country-select"
                                options={CountryISOCodes}
                                style={dropdownStyle}
                                getOptionLabel={(option) => option?.name}
                                value={CountryISOCodes.find(option => option["alpha-2"] === countryISOCode) || null}
                                onChange={handleCountryISOCodeChange}
                                renderInput={(params) => <TextField {...params} label="Country" />}
                              />
                            </FormControl>
                          </div>
                        </div>
                    </FormControl> 
                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: '30px 0 25px 0', }}>
                      <Button onClick={handleBack} style={backStyle}>Back</Button>
                      <Button variant="contained" sx={searchStyle} disabled={disabled}  onClick={searchCompany}>Search</Button>
                    </div>
                  </div>
                )}
                {activeStep === 1 && searchOption === "id" && (
                  <div style={containerStyle}>
                    <Typography sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        color:"#2F3D63",
                        marginBottom:"20px"
                        // position:"absolute",
                        // top:"55%",
                        // left:"50%",
                        // transform:"translate(-50%,-50%)"
                    }}>
                      Search By ID
                    </Typography>
                    <Typography>Company Registration Number</Typography>
                    <TextField
                      id="companyRegistrationNumber"
                      label=""
                      variant="outlined"
                      sx={inputStyle}
                      value={companyRegistrationNumber}
                      required={true}
                      onChange={handleCompanyRegistrationNumber}
                      fullWidth
                    />
                    <div style={{ marginTop: "10px" }}>
                      <FormControl fullWidth>
                        <Autocomplete
                          id="country-select"
                          options={CountryISOCodes}
                          style={dropdownStyle}
                          getOptionLabel={(option) => option?.name}
                          value={CountryISOCodes.find(option => option["alpha-2"] === countryISOCode) || null}
                          onChange={handleCountryISOCodeChange}
                          renderInput={(params) => <TextField {...params} label="Country" />}
                        />
                      </FormControl>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                      <Button onClick={handleBack} sx={backStyle}>Back</Button>
                      <Button variant="contained" sx={searchStyle} disabled={!(countryISOCode)|| disabled} color="primary" onClick={searchCompany}>Search</Button>
                    </div>
                  </div>
                )}
                {activeStep === 1 && searchOption === "ticker" && (
                  <div sx={containerStyle}>
                    <Typography sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        color:"#2F3D63",
                        margin:"20px 0 20px 0",

                        // position:"absolute",
                        // top:"55%",
                        // left:"50%",
                        // transform:"translate(-50%,-50%)"
                    }}>
                      Search By Ticker
                    </Typography>
                    <Typography sx={{marginTop:"20px"}}>Ticker</Typography>
                    <TextField
                      id="ticker"
                      label=""
                      variant="outlined"
                      value={tickerValue}
                      required={true}
                      sx={inputStyle}
                      onChange={handleTickerValueChange}
                      fullWidth
                    />
                      <div style={{ marginTop: "10px" }}>
                      <FormControl fullWidth>
                        <Autocomplete
                          id="country-select"
                          options={CountryISOCodes}
                          style={dropdownStyle}
                          getOptionLabel={(option) => option?.name}
                          value={CountryISOCodes.find(option => option["alpha-2"] === countryISOCode) || null}
                          onChange={handleCountryISOCodeChange}
                          renderInput={(params) => <TextField {...params} label="Country" />}
                        />
                      </FormControl>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 0 30px 0' }}>
                      <Button onClick={handleBack} sx={backStyle}>Back</Button>
                      <Button variant="contained" sx={searchStyle} color="primary" disabled={true} onClick={searchCompany}>Search</Button>
                    </div>
                  </div>
                )}
                {activeStep === 2 && (<div style={{display:"flex",flexDirection:"column",alignItems:"center",gap:"8px"}}>
                  <Typography variant="h6" sx={{marginTop:"10px"}}>Search Results</Typography>
                  <Typography variant="subtitle1" color="textSecondary" sx={{fontSize:"12px"}}>
                    {companySearchResults.length} companies found
                  </Typography>
                  <div style={{ position: 'relative' }}>
                  <div
                    style={{
                      maxWidth: '705px',
                      overflow: 'hidden',
                      display: 'flex',
                      gap: '10px',
                      width: '1920px',
                    }}
                    ref={listRef}
                  >
                  {companySearchResults?.map((company, index) => (
                    <div style={companyListStyle} key={index}>
                      <ListItemButton
                        key={index}
                        style={{
                          border: 1,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          width:"225px"
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <input
                            type="checkbox"
                            style={{ marginLeft: 0, marginRight: "8px", border: "1px solid #E9E9E9" }}
                            checked={selectedCompanies.some((selected) => selected.organization.duns === company.organization.duns)}
                            onChange={() => handleCheckboxChange(company)}
                          />
                          <ListItemText
                            primaryTypographyProps={{ fontSize: 14, fontWeight: "bold" }}
                            primary={company.organization.primaryName}
                          />
                        </div>
                        <div>
                              {company?.organization?.tickerSymbol && (
                                <ListItemText
                                secondaryTypographyProps={{fontSize:12,color:"#1A2A56"}}
                                  secondary={`Ticker - ${company?.organization?.tickerSymbol}`}
                                />
                              )}
                              <ListItemText
                                secondaryTypographyProps={{fontSize:12,color:"#1A2A56",}}
                                secondary={`Street - ${company?.organization?.primaryAddress.streetAddress?.line1}`}
                              />
                              {company?.organization?.primaryAddress.addressLocality
                                ?.name && (
                                  <ListItemText
                                secondaryTypographyProps={{fontSize:12,color:"#1A2A56"}}

                                    secondary={`City - ${company?.organization?.primaryAddress.addressLocality?.name}`}
                                  />
                                )}
                              {company?.organization?.primaryAddress.addressRegion
                                ?.name && (
                                  <ListItemText
                                secondaryTypographyProps={{fontSize:12,color:"#1A2A56"}}

                                    secondary={`State - ${company?.organization?.primaryAddress.addressRegion?.name}`}
                                  />
                                )}
                              <ListItemText
                                secondaryTypographyProps={{fontSize:12,color:"#1A2A56"}}

                                secondary={`Country - ${company?.organization?.primaryAddress?.addressCountry?.name}`}
                              />
                              {company?.organization?.primaryAddress?.postalCode && (
                                <ListItemText
                                secondaryTypographyProps={{fontSize:12,color:"#1A2A56"}}

                                  secondary={`Zip Code - ${company?.organization?.primaryAddress?.postalCode}`}
                                />
                              )}
                              </div>
                            </ListItemButton>
                            {(selectedCompanies.some((selected) => selected.organization.duns === company.organization.duns))?
                            <div style={{display:"flex",gap:"5px",padding:"5px", flexDirection:"column",justifyContent:"center"}}>
                              <Typography sx={{fontSize:"14px"}}>
                             Enter Requested Amount:
                              </Typography>
                            <TextField
                              value={creditLimits['requestedCreditLimit']}
                              onChange={(e) => handleRequestedCreditLimitChange(company, e.target.value)}
                              sx={{
                                '& .MuiInputBase-root': {
                                  height: '30px',
                                  border: '1px solid black', 
                                  borderRadius: '4px', 
                                },
                                '& .MuiInputLabel-root': {
                                  top: '-8px',
                                  fontSize: "12px"
                                },
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    borderColor: 'darkgrey', 
                                  },
                                  '&:hover fieldset': {
                                    borderColor: 'black', 
                                  },
                                  '&.Mui-focused fieldset': {
                                    borderColor: 'black', 
                                  },
                                },
                              }}
                            />
                            </div>:null
                            }
                            
                  </div>
                ))}
              </div>
        {
          showLeftButton?(<button
            style={{
              position: 'absolute',
              top: '50%',
              left: "-35px",
              transform: 'translateY(-50%)',
              zIndex: 1,
            }}
            onClick={scrollListLeft}
          >
            <img src={LeftIcon} alt="" />
          </button>):null
        }
        
      
      {
        companySearchResults.length>3?
      <button
        style={{
          position: 'absolute',
          top: '50%',
          right: "-30px",
          transform: 'translateY(-50%)',
          zIndex: 1,
        }}
        onClick={scrollListRight}
      >
        <img src={RightIcon} alt="" />
      </button>:null
      }

    </div>
    
    <Box>
                {companySearchResults.length===0 && (
                  <Button
                    fullWidth
                    onClick={() => {
                      handleModalClose();
                      toggleAddCompanyManuallyModal();
                    }}
                    variant="contained"
                    sx={{...buttonStyle,width:"232px"}}
                  >
                    Add Company Manually
                  </Button>
                )}
              </Box>
    {selectedCompanies.length?(<div style={{alignSelf:"start"}}>
                <FormControl component="fieldset">
                  <FormLabel component="legend" >Please Choose:</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      value="Prospect"
                      control={<CustomCheckbox checked={selectedOption === "Prospect"} />}
                      label="Prospect"
                      sx={formControlLabelStyle}
                      onChange={() => handleChange("Prospect")}
                    />
                    <FormControlLabel
                      value="Existing Customer"
                      control={<CustomCheckbox checked={selectedOption === "Existing Customer"} />}
                      label="Existing Customer"
                      sx={formControlLabelStyle}
                      onChange={() => handleChange("Existing Customer")}
                    />
                  </FormGroup>
                </FormControl>
                </div>):null}     
                <div style={{ margin: "20px 0 30px 0", textAlign: "center" }}>
                  <Button onClick={handleBack} style={backStyle}>Back</Button>
                  <Button  
                  variant="contained"
                  sx={{
                    width: selectedOption==="Prospect"?"200px":"138px",
                    height: "36px",
                    marginLeft:"10px",
                    // border: "1px solid #5186EC", 
                    borderRadius: "8px",
                    backgroundColor:"#5186EC",
                    '&:disabled': {
                        backgroundColor: '#f0f0f0', 
                      },
                      '&:hover': {
      backgroundColor: '#5186EC', // Same color on hover
    },
                  }}
                  disabled={!selectedOption}
                  onClick={handleAddCompany}
                  >{selectedOption==="Prospect"?"Add and Complete":"Add"}</Button>
                </div>
                </div>)}
                {(activeStep === 3  || activeStep === 4 )&& (
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "10px" }}>
                <div style={{ margin: "20px 0 30px 0", textAlign: "center", paddingBottom: "30px" }}>
                  <div style={containerStyle}>
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        color: "#2F3D63",
                      }}
                    >
                      Company added Successfully
                    </Typography>
                    <img src={CompletedIcon} alt="" style={{ marginLeft: "120px" }} width="45px" />
                    {!completed ? (
                      <div>
                        <Typography
                          sx={{
                            textAlign: "center",
                            fontWeight: "bold",
                            color: "#2F3D63",
                            marginBottom: "20px",
                          }}
                        >
                          Just one more step and we're done
                        </Typography>
                        <Typography sx={questionStyle}>What is the current credit limit?</Typography>
                        <TextField
                          id="currCreditLimit"
                          sx={questionInputStyle}
                          helperText={error ? errorTxt : ""}
                          label=""
                          variant="outlined"
                          error={error}
                        />
                        <Typography sx={questionStyle}>What is the open AR?</Typography>
                        <TextField
                          id="openAR"
                          sx={questionInputStyle}
                          helperText={error ? errorTxt : ""}
                          label=""
                          variant="outlined"
                          error={error}
                        />
                        <Typography sx={questionStyle}>What is the tenure of this customer (in months)?</Typography>
                        <TextField
                          id="tenure"
                          sx={questionInputStyle}
                          helperText={error ? errorTxt : ""}
                          label=""
                          variant="outlined"
                          error={error}
                        />
                      </div>
                    ) : null}
                  </div>
                  {!completed ? (
                    <div style={{ display: "flex", justifyContent: "center", position:"absolute",left:"180px"}}>
                      <Button
                        style={{
                          width: "360px",
                          height: "36px",
                          border: "1px solid #5186EC",
                          fontSize:"16px",
                          borderRadius: "8px",
                        }}
                        onClick={handleAddAnotherCompany}
                      >
                        Complete & Search another company
                      </Button>
                      <Button
                        variant="contained"
                        style={{
                          width: selectedOption === "Prospect" ? "200px" : "138px",
                          height: "36px",
                          marginLeft: "10px",
                          border: "1px solid #5186EC",
                          borderRadius: "8px",
                          backgroundColor: "#5186EC",
                        }}
                        onClick={()=>{setCompleted(true); setActiveStep(4)} }
                        color="primary"
                      >
                        Complete
                      </Button>
                    </div>
                  ) : (
                    <Button onClick={handleOptionsModalClose} variant="contained" 
                    sx={{
                      width: "138px",
                      height: "36px",
                      border: "1px solid #5186EC",
                      borderRadius: "8px",
                      backgroundColor:"#5186EC",
                      '&:disabled': {
                        backgroundColor: '#f0f0f0',
                      },
                      '&:hover': {
                        backgroundColor: '#5186EC', // Same color on hover
                      },

                    }}
                    >
                      Close
                    </Button>
                  )}
                </div>
              </div>)}
              </div>
            </Box>
          </div>
        </Modal>
      </div>
      <AddCompanyManually
        isAddCompanyManuallyModelOpen={isAddCompanyManuallyModelOpen}
        toggleAddCompanyManuallyModal={toggleAddCompanyManuallyModal}
        // companyInfo={companyInfo}
        handleOptionsModalClose={handleOptionsModalClose}
      />
      <Modal
        open={open}
        onClose={handleModalClose}
        style={modalStyle}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Add Company
            </Typography>
            {!showSearchResults ? (
              <React.Fragment>
                {searchOption === "id" && (
                  <div>
                    <TextField
                      id="companyRegistrationNumber"
                      label="Company Registration Number"
                      variant="standard"
                      value={companyRegistrationNumber}
                      onChange={handleCompanyRegistrationNumber}
                      fullWidth
                    />
                    <div style={{ marginTop: "10px" }}>
                      <FormControl fullWidth>
                        <Autocomplete
                          id="country-select"
                          options={CountryISOCodes}
                          getOptionLabel={(option) => option?.name}
                          value={CountryISOCodes.find(option => option["alpha-2"] === countryISOCode) || null}
                          onChange={handleCountryISOCodeChange}
                          renderInput={(params) => <TextField {...params} label="Country" />}
                        />
                      </FormControl>
                    </div>
                  </div>
                )}
                {searchOption === "name" && (
                  <div>
                    <TextField
                      id="companyName"
                      helperText={error ? errorTxt : ""}
                      label="Company name *"
                      variant="standard"
                      value={companyNameValue}
                      onChange={handleCompanyNameChange}
                      error={error}
                      fullWidth
                    />
                    <FormControl
                      sx={{ display: "inline-flex", flexDirection: "column", marginTop: 2 }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: "4px",
                        }}
                      >
                        <Typography
                          variant="body1"
                          style={{ marginTop: "9px" }}
                        >
                          Is Company Public?
                        </Typography>
                        <Checkbox
                          style={{ marginLeft: "10px" }}
                          checked={isPublic}
                          onChange={handleIsCompanyPublicChange}
                          color="primary"
                        />
                      </div>
                      {isPublic && (
                        <div>
                          <TextField
                            fullWidth
                            label="Ticker*"
                            variant="standard"
                            value={tickerValue}
                            onChange={handleTickerValueChange}
                          />
                          <div style={{ marginTop: "10px" }}>
                            <FormControl fullWidth>
                              <Autocomplete
                                id="country-select"
                                options={CountryISOCodes}
                                getOptionLabel={(option) => option.name}
                                value={CountryISOCodes.find(option => option["alpha-2"] === countryISOCode) || null}
                                onChange={handleCountryISOCodeChange}
                                renderInput={(params) => <TextField {...params} label="Country" />}
                              />
                            </FormControl>
                          </div>
                        </div>
                      )}
                    </FormControl>
                  </div>
                )}
                {searchOption === "ticker" && (
                  <TextField
                    id="ticker"
                    label="Ticker"
                    variant="standard"
                    value={tickerValue}
                    onChange={handleTickerValueChange}
                    fullWidth
                  />
                )}
              </React.Fragment>
            ) : (
              <List>
                {companySearchResults.map((company) => (
                  <ListItemButton
                    key={company.id}
                    onClick={() => handleCheckboxChange(company)}
                    selected={selectedCompanies.some((selectedCompany) => selectedCompany.id === company.id)}
                  >
                    <ListItemText primary={company.name} />
                  </ListItemButton>
                ))}
              </List>
            )}
            <Stack
              direction="row"
              spacing={2}
              sx={{ marginTop: "10px" }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddClick}
                disabled={disabled}
              >
                Add Company
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={searchCompany}
                disabled={isSearchDisabledBasedOnTicker}
              >
                Search Company
              </Button>
              <Button
                variant="contained"
                onClick={handleCloseClick}
              >
                Close
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
      <Dialog
        open={parentCompanyDailog}
        onClose={toggleAddCompanyManuallyModal}
      >
        <DialogTitle>{"Would you like to add this company without a parent company?"}</DialogTitle>
        <DialogContent>
          <Typography>
            Adding a company without a parent company will create a new record.
            Are you sure you want to proceed?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleYesClick} color="primary">
            Yes
          </Button>
          <Button onClick={handleNoClick} color="secondary">
            No
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={addParentConpany}
        onClose={toggleAddCompanyManuallyModal}
      >
        <DialogTitle>{"Add Parent Company"}</DialogTitle>
        <DialogContent>
          <TextField
            id="parentCompanyName"
            label="Parent Company Name"
            variant="standard"
            value={parentCompanyName}
            onChange={(e) => setParentCompanyName(e.target.value)}
            fullWidth
          />
          <div style={{ marginTop: "10px" }}>
            <FormControl fullWidth>
              <Autocomplete
                id="parent-country-select"
                options={CountryISOCodes}
                getOptionLabel={(option) => option.name}
                value={CountryISOCodes.find(option => option["alpha-2"] === parentCompanyCountry) || null}
                onChange={(e, value) => setParentCompanyCountry(value ? value["alpha-2"] : "")}
                renderInput={(params) => <TextField {...params} label="Country" />}
              />
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddParentCompany} color="primary">
            Add
          </Button>
          <Button onClick={toggleAddCompanyManuallyModal} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showParentCompanySearchResultsDialog}
        onClose={() => setShowParentCompanySearchResultsDialog(false)}
      >
        <DialogTitle>{"Select Parent Company"}</DialogTitle>
        <DialogContent>
          <List>
            {parentCompanySearchResults.map((company) => (
              <ListItemButton
                key={company.id}
                onClick={() => handleSelectClick(company)}
                selected={parentSelectedCompany && parentSelectedCompany.id === company.id}
              >
                <ListItemText primary={company.name} />
              </ListItemButton>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddParentCompany} color="primary">
            Add
          </Button>
          <Button onClick={() => setShowParentCompanySearchResultsDialog(false)} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default CreateCompanyModal;