import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import {
  companiesByName,
  getDashboardPreferences,
  getTenant,
  triggerSendEvaluationEmail,
} from "./graphql/queries";
import { createCompany, updateCompany } from "./graphql/mutations";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import FileUploadModal from "./FileUploadModal";
import { useProspectsAR } from "./context/prospectsARContext";
import { useFeatureFlag } from "./context/featureFlagContext";
import {
  GridRowModes,
  GridRowEditStopReasons,
  GridActionsCellItem,
} from "@mui/x-data-grid-premium";
import {
  Box,
  Button,
  Card,
  IconButton,
  Modal,
  Typography,
  linearProgressClasses,
} from "@mui/material";
import {
  agingTypes,
  countryScores,
  segmentationTypesEnum,
  underwritingReasons,
} from "./utils/SegmentationTypes";
import {
  colDefCreditLimitForCARBOrCL,
  colDefCreditLimitForCombinedAging,
  colDefCreditLimitForCountryScore,
  colDefCreditLimitForRN,
  colDefCreditLimitForRS,
  colDefCreditLimitForRecommendedLimit,
  colDefCreditLimitForScore,
  colDefCreditLimitForUnderwriting,
  colDefCreditLimitForUnderwritingReason,
  colDefRiskyCustomer,
} from "./utils/DataGridDef";
import {
  getTotalCombinedAgeCL,
  getTotalSegmentedOnCARBOrCL,
  getTotalSegmentedOnRNOrCN,
  getTotalSegmentedOnRS,
  getTotalSegmentedOnRecommendedLimit,
  getTotalSegmentedOnScore,
  getTotalSegmentedOnUWROrCS,
  getTotalSegmentedOnUnderwriting,
} from "./utils/Helper";
import { getRangeForRiskyCustomer } from "./utils/GetRangeForRIskyCustomer";
import {
  getRiskyCustomerAging,
  getRiskyCustomerCARBOrCL,
  getRiskyCustomerCombinedAging,
  getRiskyCustomerCountryScore,
  getRiskyCustomerRNOrCN,
  getRiskyCustomerRecommendedLimit,
  getRiskyCustomerRiskSegment,
  getRiskyCustomerScore,
  getRiskyCustomerUnderwriting,
  getRiskyCustomerUnderwritingReason,
} from "./utils/HelperRC";
import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";
import {
  CreateDashboardTablePreferences,
  updateDashboardTablePreferences,
} from "./utils/GraphQLHelper/DashboardTable";
import {
  CreditLimitColumnModal,
  RiskyCustomerColumnModal,
  TableEnum,
} from "./utils/GraphQLHelper/TableEnum";
import { toast } from "react-toastify";
import AWS from "aws-sdk";
import awsExports from "./aws-exports";
import getTenantIdFromURL from "./utils/getTenantIdFromURL";
import { useSelector } from "react-redux";
import { LinearProgress, Menu, MenuItem } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import bgArc from "./Assets/Images/bg-arc.svg";
import RiskDistributionDiagram from "./RiskDistributionDiagram/RiskDistributionDiagram";
import RecommendationTable from "./RecommendationTable/RecommendationTable";
import { useAddCompanyModal } from "./context/addCompanyModalContext";
import PortfolioScoreChart from "./PortfolioScoreChart";
import ARHeatmapNew from "./ARHeatmapNew";
import { getTenantInfo } from "./utils/GraphQLHelper/GetTenantInfo";
import CloseIcon from "@mui/icons-material/Close";
Amplify.configure(awsExports);

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

// Configure Amplify
Amplify.configure(awsconfig);

const CreateColumnHeader = (headerTitle) => {
  return (
    <Typography variant="caption" fontWeight="500">
      {headerTitle}
    </Typography>
  );
};

export default function ProspectsPage({ adminAction }) {

  const { openAddCompanyModal, setOpenAddCompanyModal } = useAddCompanyModal();

  const formatNumber = (amount) => {
    return amount.toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };
  const { tenantIdp } = useParams();
  let { summary,companies } = useLoaderData();

  console.log("🚀 ~ summary ALL:", summary);

  // const dataWithIds = (summary || [])?.map((obj, index) => {
  //   const { id, ...rest } = obj;

  //   return {
  //     id: index + 1,
  //     ...rest
  //   }
  // })

  const companyCreatedAtMap = companies.reduce((acc, company) => {
    acc[company.name] = company.createdAt;
    return acc;
  }, {});
  
  const dataWithIds = summary?.map((obj, index) => {
    const { id, ...rest } = obj;
  
    const companyName = rest["Company Name"];
    const createdAt = companyCreatedAtMap[companyName];
  
    return {
      id: index + 1,
      ...rest,
      createdAt: createdAt || null, 
      createdAtFormatted: createdAt ? new Date(createdAt).toLocaleDateString() : null

    };
  });
  dataWithIds.sort((a, b) => b.id - a.id);

  const [selectedOptionRC, setSelectedOptionRC] = useState({
    id: 8,
    label: "Recommended Limit",
  });
  const [toggleS3, setToggleS3] = useState(undefined);
  const [selectedRangeRC, setSelectedRangeRC] = useState({
    id: 0,
    label: "All",
  });
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [specificCreditLimit, setSpecificCreditLimit] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [fullName, setFullName] = useState("");
  const [title, setTitle] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [companyInfo, setCompanyInfo] = useState(null);
  const [range, setRange] = useState([]);
  const [tenantId, setTenantId] = useState();
  const [showAtRisk, setShowAtRisk] = useState(false);
  const bottomRef = useRef(null);
  const { isProspects, setProspects } = useProspectsAR();
  const [portfolioScore, setPortfolioScore] = useState(0);
  const [isAREnabled, setIsAREnabled] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const navigate = useNavigate();

  const isAdmin = useSelector((state) => state.userInfo.isAdmin);
  const URLTenant = getTenantIdFromURL();
  const currentTenant = useSelector((state) => state.userInfo.tenantId);
  const selectTenantId = () => {
    if (isAdmin) setTenantId(URLTenant);
    else setTenantId(currentTenant);
  };

  const handleSettingsClick = () => {
    handleClosePopup();
    navigate("/settings/AREnableRequest");
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  const fetchTenant = async () => {
    const { isAREnabled } = await getTenantInfo();
    setIsAREnabled(isAREnabled);
};

    let SummaryRows = dataWithIds;
    SummaryRows = SummaryRows.filter(
      (row) =>
        (row["AR Balance Aging 30"] !== null &&
          row["AR Balance Aging 30"] !== 0 &&
          row["AR Balance Aging 30"] !== "") ||
        (row["AR Balance Aging 60"] !== null &&
          row["AR Balance Aging 60"] !== 0 &&
          row["AR Balance Aging 60"] !== "") ||
        (row["AR Balance Aging 90"] !== null &&
          row["AR Balance Aging 90"] !== 0 &&
          row["AR Balance Aging 90"] !== "") ||
        (row["AR Balance Aging 90+"] !== null &&
          row["AR Balance Aging 90+"] !== 0 &&
          row["AR Balance Aging 90+"] !== "") ||
        (row["Current AR Balance"] !== null &&
          row["Current AR Balance"] !== 0 &&
          row["Current AR Balance"] !== "") ||
        (row["Total AR Balance"] !== null &&
          row["Total AR Balance"] !== 0 &&
          row["Total AR Balance"] !== "")
    );
    
    const [rows, setRows] = useState(SummaryRows);
    // rows.sort((a, b) => b['Total AR Balance'] - a['Total AR Balance']);


  let ProspectsRows = dataWithIds;
  ProspectsRows = ProspectsRows.filter(
    (row) =>
      (row["AR Balance Aging 30"] === null ||
        row["AR Balance Aging 30"] === 0 ||
        row["AR Balance Aging 30"] === "") &&
      (row["AR Balance Aging 60"] === null ||
        row["AR Balance Aging 60"] === 0 ||
        row["AR Balance Aging 60"] === "") &&
      (row["AR Balance Aging 90"] === null ||
        row["AR Balance Aging 90"] === 0 ||
        row["AR Balance Aging 90"] === "") &&
      (row["AR Balance Aging 90+"] === null ||
        row["AR Balance Aging 90+"] === 0 ||
        row["AR Balance Aging 90+"] === "") &&
      (row["Current AR Balance"] === null ||
        row["Current AR Balance"] === 0 ||
        row["Current AR Balance"] === "") &&
      (row["Total AR Balance"] === null ||
        row["Total AR Balance"] === 0 ||
        row["Total AR Balance"] === "")
  );

  let limit = "";
  console.log("Rows -> ", rows);

  const handleSetCompanyInfo = (value) => {
    setCompanyInfo(value);
  };

  const setCompanyInfoToNull = () => {
    setCompanyInfo(null);
  };

  const bgStyle = {
    width: "100%",
    height: "100%",
    background: `transparent url(${bgArc}) 0% 0% no-repeat padding-box`,
    // opacity: "0.03",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 0,
  };

  function updateFields(data) {
    const fieldsToUpdate = [
      "Current Limit",
      "Current AR Balance",
      "AR Balance Aging 30",
      "AR Balance Aging 60",
      "AR Balance Aging 90",
      "AR Balance Aging 90+",
      "Requested Limit",
      "Recommended Limit",
      "Total AR Balance",
    ];

    const fieldsToModify = ["Recommended Net", "Current Net"];

    fieldsToUpdate.forEach((field) => {
      if (data[field] === "-" || data[field] === "") {
        data[field] = null;
      }
    });

    fieldsToModify.forEach((field) => {
      if (
        data[field] === "NET 30" ||
        data[field] === "NET 45" ||
        data[field] === "NET 60" ||
        data[field] === "NET 90" ||
        data[field] === "NET 120" ||
        data[field] === "NET 180"
      ) {
        let formattedValue = data[field].toLowerCase();
        formattedValue =
          formattedValue.charAt(0).toUpperCase() + formattedValue.slice(1);
        data[field] = formattedValue;
      }
    });
  }

  rows?.forEach(updateFields);

  const [selectedOptionCL, setSelectedOptionCL] = useState({
    id: 8,
    label: "Recommended Limit",
  });

  const columnDefinition = [];
  const colDefWithWidth = colDefRiskyCustomer.map((column) => ({
    ...column,
    width: column.field === "rank" ? 80 : 140, // Set the desired width for each column
  }));

  const dateTimeSortComparator = (date1, date2) => {
    if (!date1 && !date2) return 0; // Both dates are null
    if (!date1) return 1; // 'date1' is null, 'date2' comes first
    if (!date2) return -1; // 'date2' is null, 'date1' comes first

    return new Date(date1) - new Date(date2);
  };

  const columns = getUniqueKeys(rows).filter(
    (column) =>
      column !== "" && column !== "id" && column !== "Evaluation Status"
  );

  columns.forEach((column) => {
    const definition = {
      field: column,
      headerName: column,
      width: 120,
      hide: false,
      renderHeader: (params) => {
        return CreateColumnHeader(params.colDef.headerName);
      },
    };

    if (typeof rows[0][column] === "number") {
      definition.type = "number";
    }
    if (column === "Requested Limit") {
      definition.editable = true;
    }
    if (column === "Current Net") {
      definition.editable = true;
    }
    if (column === "Current Limit") {
      definition.editable = true;
    }
    if (column === "Last Update Time") {
      definition.sortComparator = dateTimeSortComparator;
    }
    columnDefinition.push(definition);
  });

  const actionsColumnDefinition = {
    field: "actions",
    type: "actions",
    headerName: "Actions",
    hide: false,
    width: 100,
    cellClassName: "actions",
    getActions: ({ id }) => {
      const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

      if (isInEditMode) {
        return [
          <GridActionsCellItem
            icon={<SaveIcon />}
            label="Save"
            sx={{
              color: "primary.main",
            }}
            onClick={handleSaveClick(id)}
          />,
          <GridActionsCellItem
            icon={<CancelIcon />}
            label="Cancel"
            className="textPrimary"
            onClick={handleCancelClick(id)}
            color="inherit"
          />,
        ];
      }

      return [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          className="textPrimary"
          onClick={handleEditClick(id)}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={handleDeleteClick(id)}
          color="inherit"
        />,
      ];
    },
  };

  const ManualEvaluationButton = {
    field: "toggle",
    headerName: "Request Manual Evaluation",
    width: 130,
    renderCell: (params) => {
      let buttonStyle = {
        backgroundColor: "#2196F3", // Blue color
        color: "#fff", // White text color
        borderRadius: "5px", // Rounded corners
        padding: "8px 16px", // Padding for better appearance
        cursor: "pointer", // Cursor style on hover
        border: "none", // No border
        outline: "none", // Remove outline on focus
      };

      let buttonText = "Request";
      let disabled = false;

      if (params.row["Evaluation Status"] === "Sent") {
        buttonText = "Sent";
        disabled = true;
        buttonStyle = {
          ...buttonStyle,
          backgroundColor: "#ccc", // Grey color for disabled
          cursor: "default", // Default cursor for disabled
        };
      }

      return (
        <button
          style={buttonStyle}
          onClick={() => handleManualEvaluationButtonClick(params)}
          disabled={disabled}
        >
          {buttonText}
        </button>
      );
    },
  };

  columnDefinition.push(actionsColumnDefinition);
  columnDefinition.push(ManualEvaluationButton);

  const initialColumnVisibilityModel = Object.fromEntries(
    columnDefinition.map((column) => [column.field, !column.hide])
  );

  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    initialColumnVisibilityModel
  );

  const [creditLimitCVM, setCreditLimitCVM] = useState(CreditLimitColumnModal);
  const [riskyCustomerCVM, setRiskyCustomerCVM] = useState(
    RiskyCustomerColumnModal
  );

  const handleManualEvaluationButtonClick = async (params) => {
    setDialogOpen(true);
    setCompanyName(params.row["Company Name"]);
  };

  const handleCloseDialog = () => {
    limit = "";
    setSpecificCreditLimit("");
    setComment("");
    setSelectedOption("");
    setDialogOpen(false);
    setCompanyName("");
    setCompanyName("");
    setFullName("");
    setTitle("");
    setEmail("");
    setPhoneNumber("");
  };

  const handleSubmit = async (row) => {
    console.log("Selected Option:", selectedOption);

    try {
      let limit;
      if (selectedOption === "Specific Credit Limit") {
        console.log("Specific credit limit:", specificCreditLimit);
        limit = specificCreditLimit;
      } else {
        limit = "Max";
      }

      let result = await API.graphql(
        graphqlOperation(companiesByName, {
          name: companyName,
          limit: 1,
          filter: {
            tenantId: {
              eq: tenantId,
            },
          },
        })
      );

      const companyid = result.data.companiesByName.items[0].id;
      let tenant = await API.graphql(
        graphqlOperation(getTenant, {
          id: tenantId,
        })
      );
      const tenantName = tenant.data.getTenant.name;
      await API.graphql(
        graphqlOperation(triggerSendEvaluationEmail, {
          tenantId: tenantId,
          tenantName: tenantName,
          id: companyid,
          companyName: companyName,
          requestedAmount: limit,
          comments: comment,
          fullName: fullName,
          title: title,
          email: email,
          phoneNumber: phoneNumber,
        })
      );

      let foundCompany = rows.find(
        (obj) => obj["Company Name"] === companyName
      );
      if (foundCompany) {
        foundCompany["Evaluation Status"] = "Sent";
      }
      handleCloseDialog();
      uploadJsonToS3();
      toast.success("Evaluation request sent successfully!");
    } catch (error) {
      console.error("Error occurred:", error);
      toast.error(
        "An error occurred while sending the evaluation request. Please try again later."
      );
    }
  };

  const handleFullNameChange = (event) => {
    setFullName(event.target.value);
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleCommentsChange = (event) => {
    setComment(event.target.value);
  };

  const handleSpecificCreditLimitValue = (event) => {
    setSpecificCreditLimit(event.target.value);
  };

  const handleOptionChange = (event) => {
    console.log("Limit", limit);
    setSelectedOption(event.target.value);
  };

  const getRowId = (row) => {
    const timestamp = new Date().getTime();
    const randomNum = Math.random() * 10000; // Adjust the range as per your needs
    const uniqueId = `${timestamp}-${randomNum}`;
    return uniqueId;
  };

  const handleCellClick = async (params) => {
    if (params.field === "Company Name") {
      let nextToken = null;
      let items = [];
      let result;
      do {
        result = await API.graphql(
          graphqlOperation(companiesByName, {
            name: params.row["Company Name"],
            limit: 1,
            filter: {
              tenantId: {
                eq: tenantId,
              },
            },
            nextToken: nextToken,
          })
        );

        items = result.data.companiesByName.items;
        nextToken = result.data.companiesByName.nextToken;

        if (items.length > 0) {
          break;
        }
      } while (nextToken != null);

      let company = null;
      if (items.length === 0) {
        try {
          result = await API.graphql(
            graphqlOperation(createCompany, {
              input: {
                name: params.row["Company Name"],
                tenantId: tenantId,
              },
            })
          );
        } catch (error) {
          console.log(error);
        }
        company = result.data.createCompany;
      } else {
        company = items[0];
      }

      if (isAdmin)
        window.open(
          `/tenant-dashboard/${tenantId}/company/${company.id}`,
          "_blank"
        );
      else window.open(`/company/${company.id}`, "_blank");
    }
  };

  const handleColumnVisibilityModelChange = async (model, details) => {
    console.log("Column Visibility Model:", model);
    setColumnVisibilityModel(model);
    const preferenceData = {
      feature: TableEnum.ALL_SUMMARY_TABLE,
      preferences: JSON.stringify(model),
    };
    updateDashboardTablePreferences(preferenceData);
  };

  const handleCVMChangeForCreditLimit = async (model, details) => {
    console.log("Column Visibility Model:", model);
    const temp = { ...creditLimitCVM };
    temp[selectedOptionCL.label] = model;

    setCreditLimitCVM(temp);
    const preferenceData = {
      feature: TableEnum.ALL_CREDIT_LIMIT,
      preferences: JSON.stringify(temp),
    };
    updateDashboardTablePreferences(preferenceData);
  };

  const handleCVMChangeForRiskyCustomer = async (model, details) => {
    console.log("Column Visibility Model:", model);
    const temp = { ...riskyCustomerCVM };
    temp[selectedOptionRC.label] = model;

    setRiskyCustomerCVM(temp);
    const preferenceData = {
      feature: TableEnum.ALL_RISKY_CUSTOMER,
      preferences: JSON.stringify(temp),
    };
    updateDashboardTablePreferences(preferenceData);
  };

  function getUniqueKeys(arrayOfObjects) {
    let uniqueKeys = new Set();
    arrayOfObjects?.forEach((obj) => {
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          uniqueKeys.add(key);
        }
      }
    });
    return Array.from(uniqueKeys);
  }

  const handleDropdownChange = (event, newValue) => {
    if (newValue) {
      setSelectedOptionCL(newValue);
    }
  };

  const handleDropdownChangeForRC = (event, newValue) => {
    if (newValue) {
      setSelectedOptionRC(newValue);
      const rangeArray = getRangeForRiskyCustomer(newValue.id);
      setRange(rangeArray);
      setSelectedRangeRC({ id: 0, label: "All" });
    }
  };

  const handleRangeChangeRC = (event, newValue) => {
    if (newValue) {
      setSelectedRangeRC(newValue);
    }
  };

  const [rowModesModel, setRowModesModel] = React.useState({});


  const uploadJsonToS3 = async () => {
    console.log("tenant in upload json", tenantId);
    try {
      const s3 = new AWS.S3();
      // const mergedRows = rows.concat(ProspectsRows);
      const jsonString = JSON.stringify(rows);
      const key = "public/summary_" + tenantId + ".json";

      const result = await s3.putObject({
        Bucket: 'crediarc-content01853-production', // Specify your bucket name
        Key: key,
        Body: jsonString,
        ContentType: 'application/json',
      }).promise();

      console.log("File uploaded successfully", result);
    } catch (error) {
      console.error("Error uploading file", error);
    }
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleChange = async (row) => {
    try {
      console.log("tenantid handle change ", tenantId);
      let companyNameValue = row["Company Name"];
      console.log("row handle change ", row);

      if (companyNameValue.trim() !== "" && row["Company Name"].trim() !== "") {
        let result = await API.graphql(
          graphqlOperation(companiesByName, {
            name: companyNameValue,
            limit: 1,
            filter: {
              tenantId: {
                eq: tenantId,
              },
            },
          })
        );

        let company = null;
        let requestedCreditLimit = row["Requested Limit"];
        console.log("req limit ", requestedCreditLimit);

        if (result.data.companiesByName.items.length > 0) {
          company = result.data.companiesByName.items[0];

          const some = await API.graphql(
            graphqlOperation(updateCompany, {
              input: {
                tenantId: tenantId,
                id: company.id,
                requestedCreditLimit: parseFloat(requestedCreditLimit),
                view: 1,
              },
            })
          );
          company = result.data.updateCompany;
          console.log("updated result ", some.data);
        } else {
          console.log("No company found with the given name");
        }
      } else {
        console.log("companyNameValue or row['Account ID'] is an empty string");
      }
    } catch (error) {
      console.log("error while saving file", error);
    }
  };

  const handleSaveClick = (id) => () => {
    const foundItem = rows.find((item) => item.id === id);
    handleChange(foundItem);
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    const isConfirmed = window.confirm("Are you sure you want to delete?");
    if (isConfirmed) {
      setRows(rows.filter((row) => row.id !== id));
      setToggleS3(!toggleS3);
    }
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
      setToggleS3(!toggleS3);
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    console.log("Process Row Update", updatedRow);
    setToggleS3(!toggleS3);
    handleChange(updatedRow);
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const checkAndFetchPreferencesForCreditLimit = async () => {
    try {
      const feature = TableEnum.ALL_CREDIT_LIMIT;
      const result = await API.graphql(
        graphqlOperation(getDashboardPreferences, { tenantId, feature })
      );

      if (result.data.getDashboardPreferences) {
        const existingPreferences =
          result.data.getDashboardPreferences.preferences;
        console.log("Item exists:", JSON.parse(existingPreferences));
        setCreditLimitCVM(JSON.parse(existingPreferences));
      } else {
        const payload = {
          feature: TableEnum.ALL_CREDIT_LIMIT,
          preferences: JSON.stringify(creditLimitCVM),
        };
        CreateDashboardTablePreferences(payload);
      }
    } catch (error) {
      console.error("Error checking and fetching preferences:", error);
    }
  };

  const checkAndFetchPreferencesForRiskyCustomer = async () => {
    try {
      const feature = TableEnum.ALL_RISKY_CUSTOMER;
      const result = await API.graphql(
        graphqlOperation(getDashboardPreferences, { tenantId, feature })
      );

      if (result.data.getDashboardPreferences) {
        const existingPreferences =
          result.data.getDashboardPreferences.preferences;
        console.log("Item exists:", JSON.parse(existingPreferences));
        setRiskyCustomerCVM(JSON.parse(existingPreferences));
      } else {
        const payload = {
          feature: TableEnum.ALL_RISKY_CUSTOMER,
          preferences: JSON.stringify(riskyCustomerCVM),
        };
        CreateDashboardTablePreferences(payload);
      }
    } catch (error) {
      console.error("Error checking and fetching preferences:", error);
    }
  };

  const checkAndFetchPreferencesForSummaryTable = async () => {
    try {
      const feature = TableEnum.ALL_SUMMARY_TABLE;
      const result = await API.graphql(
        graphqlOperation(getDashboardPreferences, { tenantId, feature })
      );

      if (result.data.getDashboardPreferences) {
        const existingPreferences =
          result.data.getDashboardPreferences.preferences;
        console.log("Item exists:", JSON.parse(existingPreferences));
        setColumnVisibilityModel(JSON.parse(existingPreferences));
      } else {
        const payload = {
          feature: TableEnum.ALL_SUMMARY_TABLE,
          preferences: JSON.stringify(initialColumnVisibilityModel),
        };
        CreateDashboardTablePreferences(payload);
      }
    } catch (error) {
      console.error("Error checking and fetching preferences:", error);
    }
  };

  useEffect(() => {
    selectTenantId();
    if (tenantId) {
      checkAndFetchPreferencesForSummaryTable();
      checkAndFetchPreferencesForCreditLimit();
      checkAndFetchPreferencesForRiskyCustomer();
    }
  }, []);

  useEffect(() => {
    if (summary.length > 0) {
      const validScores = summary
        .map((item) => item["Combined Score"])
        .filter((score) => !isNaN(score) && score !== null && score !== "");

      if (validScores.length > 0) {
        const totalCombinedScore = validScores.reduce(
          (sum, score) => sum + score,
          0
        );
        const averageCombinedScore = totalCombinedScore / validScores.length;
        setPortfolioScore(averageCombinedScore.toFixed(1));
      } else {
        setPortfolioScore(0);
      }
    }
    fetchTenant()
  }, [summary]);
  useEffect(() => {
    if (toggleS3 !== undefined) {
      uploadJsonToS3();
    }
  }, [!toggleS3]);

  let gridRowsCL = [];
  let gridColumnsCL = [];

  const allowedScoreSegment = [
    "Alternative Score",
    "Legal Score",
    "General Risk Score",
    "Failure Score",
    "Delinquency Score",
  ];

  if (allowedScoreSegment.includes(selectedOptionCL?.label)) {
    gridRowsCL = getTotalSegmentedOnScore(rows, selectedOptionCL?.label);
    gridColumnsCL = colDefCreditLimitForScore;
  } else if (
    selectedOptionCL?.label === "Recommended Net" ||
    selectedOptionCL?.label === "Current Net"
  ) {
    gridRowsCL = getTotalSegmentedOnRNOrCN(rows, selectedOptionCL?.label);
    gridColumnsCL = colDefCreditLimitForRN;
  } else if (selectedOptionCL?.label === "Underwriting") {
    gridRowsCL = getTotalSegmentedOnUnderwriting(rows);
    gridColumnsCL = colDefCreditLimitForUnderwriting;
  } else if (selectedOptionCL?.label === "Recommended Limit") {
    gridRowsCL = getTotalSegmentedOnRecommendedLimit(rows);
    gridColumnsCL = colDefCreditLimitForRecommendedLimit;
  } else if (
    selectedOptionCL?.label === "Current AR Balance" ||
    selectedOptionCL?.label === "Current Limit"
  ) {
    gridRowsCL = getTotalSegmentedOnCARBOrCL(rows, selectedOptionCL?.label);
    gridColumnsCL = colDefCreditLimitForCARBOrCL;
  } else if (selectedOptionCL?.label === "Risk Segment") {
    gridRowsCL = getTotalSegmentedOnRS(rows);
    gridColumnsCL = colDefCreditLimitForRS;
  } else if (selectedOptionCL?.label === "Combined Aging") {
    gridRowsCL = getTotalCombinedAgeCL(rows, "combined");
    gridColumnsCL = colDefCreditLimitForCombinedAging;
  } else if (selectedOptionCL?.label === "Country Score") {
    gridRowsCL = getTotalSegmentedOnUWROrCS(
      rows,
      selectedOptionCL?.label,
      countryScores
    );
    gridColumnsCL = colDefCreditLimitForCountryScore;
  } else if (selectedOptionCL?.label === "Underwriting Reason") {
    gridRowsCL = getTotalSegmentedOnUWROrCS(
      rows,
      selectedOptionCL?.label,
      underwritingReasons
    );
    gridColumnsCL = colDefCreditLimitForUnderwritingReason;
  } else if (
    selectedOptionCL?.label === agingTypes.AGINNG30.label ||
    selectedOptionCL?.label === agingTypes.AGINNG60.label ||
    selectedOptionCL?.label === agingTypes.AGINNG90.label ||
    selectedOptionCL?.label === agingTypes.AGINNG90PLUS.label
  ) {
    gridRowsCL = getTotalCombinedAgeCL(rows, selectedOptionCL?.label);
    gridColumnsCL = colDefCreditLimitForCombinedAging;
  }

  const conditionToRemove = (row) =>
    row.metric === "Utilization %" ||
    row.metric === "Total $ value of used credit limit" ||
    row.metric === "Average Used Limit";
  const filteredRows = gridRowsCL.filter((row) => !conditionToRemove(row));

  function calculateSortingScore(
    riskSegment,
    failureScore,
    alternativeScore,
    delinquencyScore
  ) {
    let sum = 0;
    let denominator = 0;

    if (riskSegment !== undefined) {
      sum += 5 * riskSegment;
      denominator += 5;
    }

    if (failureScore !== undefined) {
      sum += 3 * failureScore;
      denominator += 3;
    }

    if (alternativeScore !== undefined) {
      sum += 2 * alternativeScore;
      denominator += 2;
    }

    if (delinquencyScore !== undefined) {
      sum += 2 * delinquencyScore;
      denominator += 2;
    }

    if (denominator !== 0) {
      return sum / denominator;
    } else {
      // Handle the case where all scores are missing
      console.error(
        "All scores are missing. Unable to calculate sorting score."
      );
      return undefined;
    }
  }

  const getTop10RiskyCustomer = (data) => {
    const processedData = data.map((item) => {
      const riskSegment = parseFloat(item["Risk Segment"]);
      const failureScore = parseFloat(item["Failure Score"]);
      const alternativeScore = parseFloat(item["Alternative Score"]);
      const delinquencyScore = parseFloat(item["Delinquency Score"]);

      // Check for NaN and set SortingScore accordingly
      const sortingScore = calculateSortingScore(
        isNaN(riskSegment) ? undefined : riskSegment,
        isNaN(failureScore) ? undefined : failureScore,
        isNaN(alternativeScore) ? undefined : alternativeScore,
        isNaN(delinquencyScore) ? undefined : delinquencyScore
      );

      return { ...item, SortingScore: sortingScore };
    });

    const sortedData = processedData.slice().sort((a, b) => {
      const scoreA = a.SortingScore || 0;
      const scoreB = b.SortingScore || 0;

      return scoreB - scoreA;
    });

    const top10RiskyCompanies = sortedData.slice(0, 10).map((item, index) => ({
      ...item,
      Rank: index + 1,
    }));

    return top10RiskyCompanies;
  };

  let gridRowsRC = [];
  if (selectedOptionRC.id === segmentationTypesEnum.RECOMMENDED_LIMIT.id) {
    const dataArray = getRiskyCustomerRecommendedLimit(
      isProspects ? ProspectsRows : rows,
      selectedRangeRC.id
    );
    gridRowsRC = getTop10RiskyCustomer(dataArray);
  } else if (
    selectedOptionRC.id === segmentationTypesEnum.RECOMMENDED_NET.id ||
    selectedOptionRC.id === segmentationTypesEnum.CURRENT_NET.id
  ) {
    const dataArray = getRiskyCustomerRNOrCN(
      isProspects ? ProspectsRows : rows,
      selectedRangeRC.id,
      selectedOptionRC.label
    );
    gridRowsRC = getTop10RiskyCustomer(dataArray);
  } else if (selectedOptionRC.id === segmentationTypesEnum.UNDERWRITING.id) {
    const dataArray = getRiskyCustomerUnderwriting(rows, selectedRangeRC.id);
    gridRowsRC = getTop10RiskyCustomer(dataArray);
  } else if (allowedScoreSegment.includes(selectedOptionRC?.label)) {
    const dataArray = getRiskyCustomerScore(
      isProspects ? ProspectsRows : rows,
      selectedRangeRC.id,
      selectedOptionRC?.label
    );
    gridRowsRC = getTop10RiskyCustomer(dataArray);
  } else if (
    selectedOptionRC.id === segmentationTypesEnum.ARB_AGING30.id ||
    selectedOptionRC.id === segmentationTypesEnum.ARB_AGING60.id ||
    selectedOptionRC.id === segmentationTypesEnum.ARB_AGING90.id ||
    selectedOptionRC.id === segmentationTypesEnum.ARB_AGING90PLUS.id
  ) {
    const dataArray = getRiskyCustomerAging(
      isProspects ? ProspectsRows : rows,
      selectedRangeRC.id,
      selectedOptionRC.label
    );
    gridRowsRC = getTop10RiskyCustomer(dataArray);
  } else if (selectedOptionRC.id === segmentationTypesEnum.COMBINED_AGING.id) {
    const dataArray = getRiskyCustomerCombinedAging(
      isProspects ? ProspectsRows : rows,
      selectedRangeRC.id
    );
    gridRowsRC = getTop10RiskyCustomer(dataArray);
  } else if (
    selectedOptionRC.id === segmentationTypesEnum.CARB.id ||
    selectedOptionRC.id === segmentationTypesEnum.CURRENT_LIMIT.id
  ) {
    const dataArray = getRiskyCustomerCARBOrCL(
      isProspects ? ProspectsRows : rows,
      selectedRangeRC.id,
      selectedOptionRC.label
    );
    gridRowsRC = getTop10RiskyCustomer(dataArray);
  } else if (selectedOptionRC.id === segmentationTypesEnum.RISK_SEGMENT.id) {
    const dataArray = getRiskyCustomerRiskSegment(
      isProspects ? ProspectsRows : rows,
      selectedRangeRC.id
    );
    gridRowsRC = getTop10RiskyCustomer(dataArray);
  } else if (selectedOptionRC.id === segmentationTypesEnum.COUNTRY_SCORE.id) {
    const dataArray = getRiskyCustomerCountryScore(
      isProspects ? ProspectsRows : rows,
      selectedRangeRC.id
    );
    gridRowsRC = getTop10RiskyCustomer(dataArray);
  } else if (
    selectedOptionRC.id === segmentationTypesEnum.UNDERWRITING_REASON.id
  ) {
    const dataArray = getRiskyCustomerUnderwritingReason(
      isProspects ? ProspectsRows : rows,
      selectedRangeRC.id
    );
    gridRowsRC = getTop10RiskyCustomer(dataArray);
  }

  const containerStyle = {
    display: "flex",
    justifyContent: "space-between", // Ensures space between the boxes
    alignItems: "center", // Centers items vertically
    gap: "16px",
    paddingTop: "20px",
    marginLeft: "0px",
    width: "100%",
  };

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
      boxShadow: "inset 0px 1px 3px grey, 0px 3px 6px white",
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: getColor(portfolioScore),
      boxShadow: "inset 0px 3px 6px #00000029",
    },
  }));

  const countRowsAtRisk = () => {
    // return isProspects ? ProspectsRows.filter(row => row['# Risk ind'] !== 0).length :  rows.filter(row => row['# Risk ind'] !== 0).length;
    return rows.filter((row) => row["# Risk ind"] !== 0).length;
  };
  const calculateMoneyAtRisk = () => {
    // if(isProspects){
    //   return 0
    //   // ProspectsRows
    //   // .filter(row => row['# Risk ind'] !== 0)
    //   // .reduce((total, row) => total + row['Total AR Balance'], 0);
    // }
    return rows
      .filter((row) => row["# Risk ind"] !== 0)
      .reduce((total, row) => total + row["Total AR Balance"], 0);
  };

  const customersAtRisk = countRowsAtRisk();
  const moneyExposedToRisk = calculateMoneyAtRisk();
  const existingCustomers = rows.length;
  const totalAmount = ProspectsRows.reduce(
    (sum, row) => sum + (row["Recommended Limit"] || 0),
    0
  );

  const getColor = (score) => {
    if (score >= 1 && score < 4) {
      return "#60AC50";
    } else if (score >= 4 && score < 8) {
      return "#E7BF3C";
    } else if (score >= 8) {
      return "#F25A48";
    } else {
      return "#f0f0f0"; // default color
    }
  };

  const getImage = (score) => {
    if (score >= 1 && score < 4) {
      return "/greenSmiley.svg";
    } else if (score >= 4 && score < 8) {
      return "/yellowSmiley.svg";
    } else {
      return "/redSmiley.svg";
    }
  };

  const [fileModalOpen, setFileModalOpen] = useState(false);

  const handleLoadAgingFiles = () => {
    if (!isAREnabled) {
      setPopupOpen(true);
      return;
    } 
    setFileModalOpen(true);
  };

  const DropdownMenu = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [isExpandDocuments, setExpandDocuments] = useState(false);
    const [isExpandMitigations, setExpandMitigation] = useState(false);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleExpandDocuments = () => {
      setExpandDocuments(!isExpandDocuments);
    };

    const handleExpandMitigations = () => {
      setExpandMitigation(!isExpandMitigations);
    };

    return (
      <div style={{ textAlign: "center" }}>
        <Button
          variant="contained"
          onClick={handleClick}
          style={{
            textTransform: "none",
            fontFamily: "Rubik, sans-serif",
            backgroundColor: "#5186EC",
            height: "25px",
            padding: "0px 1px 0px 5px",
            borderRadius: "8px",
          }}
        >
          <Typography sx={{ fontSize: "12px" }}>
            How to lower the risk?
          </Typography>
          <KeyboardArrowDownIcon />
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          getContentAnchorEl={null} // Ensures menu is aligned to top
        >
          <Typography
            variant="subtitle1"
            onClick={handleExpandDocuments}
            style={{
              marginLeft: "15px",
              marginTop: "10px",
              marginRight: "10px",
              fontFamily: "Rubik, sans-serif",
              fontWeight: 450,
              cursor: "pointer",
            }}
          >
            Request Customer Documents
          </Typography>
          {isExpandDocuments && (
            <>
              <MenuItem onClick={handleClose} style={{ paddingLeft: "20px" }}>
                Financial statement
              </MenuItem>
              <hr />
              <MenuItem onClick={handleClose} style={{ paddingLeft: "20px" }}>
                Current account statement
              </MenuItem>
              <hr />
              <MenuItem onClick={handleClose} style={{ paddingLeft: "20px" }}>
                Deposit and Saving statement
              </MenuItem>
              <hr />
            </>
          )}
          <Typography
            variant="subtitle1"
            onClick={handleExpandMitigations}
            style={{
              marginLeft: "15px",
              marginTop: "10px",
              marginRight: "10px",
              fontFamily: "Rubik, sans-serif",
              fontWeight: 450,
              cursor: "pointer",
            }}
          >
            Risk Mitigations
          </Typography>
          {isExpandMitigations && (
            <>
              <MenuItem onClick={handleClose} style={{ paddingLeft: "20px" }}>
                Comfort letter
              </MenuItem>
              <hr />
              <MenuItem onClick={handleClose} style={{ paddingLeft: "20px" }}>
                Parent company guarantee
              </MenuItem>
              <hr />
              <MenuItem onClick={handleClose} style={{ paddingLeft: "20px" }}>
                Owners' guarantee
              </MenuItem>
              <hr />
              <MenuItem onClick={handleClose} style={{ paddingLeft: "20px" }}>
                Government guarantee
              </MenuItem>
              <hr />
              <MenuItem onClick={handleClose} style={{ paddingLeft: "20px" }}>
                Insurance
              </MenuItem>
              <hr />
              <MenuItem onClick={handleClose} style={{ paddingLeft: "20px" }}>
                Collateral
              </MenuItem>
            </>
          )}
        </Menu>
      </div>
    );
  };

  const scrollToTable = useCallback(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  const handleBottomClick = (e) => {
    e.preventDefault();
    setShowAtRisk(true);
    setTimeout(scrollToTable, 50);
  };

  const { isFeatureFlag, setFeatureFlag } = useFeatureFlag();
  const {
    show_portfolio_ai_score_card,
    show_customer_risk_bar_chart,
    show_prospects_card,
    show_summary_table,
    show_existing_customer_card,
    show_portfolio_score_chart,
    show_ar_heatmap
  } = isFeatureFlag["DashBoard_Page"];

  const { show_file_upload_button } = isFeatureFlag?.["Buttons"] || {};

  const sortProspectRows = (gridRowsRC) => {
    return gridRowsRC
      .sort((a, b) => {
        if (a["Combined Score"] != null || b["Combined Score"] != null) {
          return (
            (parseFloat(b["Combined Score"]) || 0) -
            (parseFloat(a["Combined Score"]) || 0)
          );
        }

        return 0;
      })
  }

  const sortGridRows = (gridRowsRC) => {
    return gridRowsRC
      .sort((a, b) => {
        // Initial lexicographic sort by name
        const nameA = a["Company Name"]
          ? String(a["Company Name"]).toUpperCase()
          : ""; // Ignore case
        const nameB = b["Company Name"]
          ? String(b["Company Name"]).toUpperCase()
          : ""; // Ignore case
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      })
      .sort((a, b) => {
        // Sort by Paydex score
        if (a["Paydex"] != null || b["Paydex"] != null) {
          return (parseFloat(b["Paydex"]) || 0) - (parseFloat(a["Paydex"]) || 0);
        }

        if (
          a["AR Balance Aging 60"] != null ||
          a["AR Balance Aging 90"] != null ||
          b["AR Balance Aging 60"] != null ||
          b["AR Balance Aging 90"] != null
        ) {
          const ar1 =
            (a["AR Balance Aging 60"] !== null
              ? parseFloat(a["AR Balance Aging 60"])
              : 0) +
            (a["AR Balance Aging 90"] !== null
              ? parseFloat(a["AR Balance Aging 90"])
              : 0);
          const ar2 =
            (b["AR Balance Aging 60"] !== null
              ? parseFloat(b["AR Balance Aging 60"])
              : 0) +
            (b["AR Balance Aging 90"] !== null
              ? parseFloat(b["AR Balance Aging 90"])
              : 0);

          return ar2 - ar1;
        }

        if (a["Combined Score"] != null || b["Combined Score"] != null) {
          return (
            (parseFloat(b["Combined Score"]) || 0) -
            (parseFloat(a["Combined Score"]) || 0)
          );
        }

        return 0;
      });
  };

  return (
    <>
      <div style={containerStyle}>
        {show_portfolio_ai_score_card && (
          <Card
            variant="outlined"
            style={{
              height: "263px",
              display: "flex",
              flex: 1,
              flexDirection: "column",
              justifyContent: "space-between",
              boxShadow: "inset 0px 1px 3px #0000001A, 0px 3px 6px #00000029",
              position: "relative",
              backgroundColor: "#FAFAFC",
            }}
          >
            {" "}
            {/** card 1 */}
            <div style={bgStyle}></div>
            <div
              style={{
                padding: "5px 20px 0 20px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography
                style={{
                  color: "#2F3D63",
                  fontFamily: "Rubik, sans-serif",
                  fontSize: "20px",
                  alignContent: "left",
                  fontWeight: "500",
                  marginRight: "10px",
                  paddingTop: "10px",
                }}
              >
                Portfolio AI Score
              </Typography>
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <img
                  src={getImage(portfolioScore)}
                  style={{
                    height: "15px",
                    marginRight: "5px",
                    marginTop: "15px",
                  }}
                />
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    sx={{
                      color: getColor(portfolioScore),
                      fontFamily: "Rubik, sans-serif",
                      fontWeight: "500",
                      fontSize: "46px",
                      alignContent: "right",
                    }}
                  >
                    {portfolioScore}
                  </Typography>
                </div>
              </div>
            </div>
            <div>
              <BorderLinearProgress
                variant="determinate"
                value={portfolioScore * 10}
                style={{ margin: "6px 22px 10px 22px" }}
              />
              {/* <div style={{display : 'flex'}}>
          <img src='/celebrateIcon.svg' alt='celebrate icon' style={{marginLeft : '22px'}}/>
          <Typography style={{color:"#60AC50", paddingLeft: '10px' ,fontWeight:'500', fontFamily: "Rubik, sans-serif" , fontSize : '14px'}}>Reduce the risk by x points</Typography>
        </div>*/}
            </div>
            <div style={{ height: "50px" }}>
              <hr style={{ marginLeft: "19px", marginRight: "19px" }} />
              <div
                style={{
                  padding: "0 5px 0 18px",
                  display: "flex",
                  flexDirection: "row",
                  alignContent: "center",
                  height: "100%",
                }}
              >
                <img
                  src="/bulbicon.svg"
                  alt="light bulb"
                  style={{ width: "18px", marginRight: "10px" }}
                />
                <Typography
                  sx={{
                    paddingTop: "5px",
                    fontSize: "14px",
                    lineHeight: "15px",
                    zIndex: 2,
                    margin: "auto 0px",
                  }}
                >
                  <a
                    style={{ color: "#5186EC" }}
                    href="#bottom"
                    onClick={handleBottomClick}
                  >
                    <span style={{ textDecoration: "underline" }}>
                      What can you do to improve it
                    </span>
                  </a>
                </Typography>
              </div>
            </div>
          </Card>
        )}
        {show_existing_customer_card && (
          <Card
            variant="outlined"
            style={{
              height: "263px",
              display: "flex",
              flex: 1.5,
              flexDirection: "column",
              justifyContent: "space-between",
              boxShadow: "inset 0px 1px 3px #0000001A, 0px 3px 6px #00000029",
              position: "relative",
              backgroundColor: "#FAFAFC",
            }}
          >
            {" "}
            {/** card 2 */}
            <div style={bgStyle}></div>
            <div
              style={{
                padding: "5px 20px 0 20px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                height: "50px",
              }}
            >
              <Typography
                style={{
                  color: "#2F3D63",
                  fontFamily: "Rubik, sans-serif",
                  fontSize: "20px",
                  fontWeight: "500",
                  alignContent: "left",
                  marginTop: "10px",
                }}
              >
                Existing Customers
              </Typography>
              <Typography
                style={{
                  color: "#2f3d63",
                  fontFamily: "Rubik, sans-serif",
                  fontSize: "46px",
                  alignContent: "right",
                  fontWeight: "500",
                }}
              >
                {existingCustomers}
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingLeft: "10px",
                width: "80%",
                justifyContent: "space-between",
                alignSelf: "flex-start",
                marginLeft: "5px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  style={{
                    padding: "6px",
                    color: "#2F3D63",
                    fontFamily: "Rubik, sans-serif",
                    fontSize: "14px",
                    alignContent: "left",
                  }}
                >
                  Customers at risk
                </Typography>
                <Typography
                  style={{
                    padding: "6px",
                    color: "#2F3D63",
                    fontFamily: "Rubik, sans-serif",
                    fontSize: "14px",
                  }}
                >
                  Money Exposed to Risk
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    style={{
                      color: "#F25A48",
                      fontFamily: "Rubik, sans-serif",
                      fontSize: "20px",
                      fontWeight: "500",
                    }}
                  >
                    {customersAtRisk}
                  </Typography>
                  <img
                    src={getImage(customersAtRisk)}
                    alt="image"
                    style={{
                      paddingLeft: "5px",
                      height: "15px",
                      width: "15px",
                    }}
                  />
                </div>
                <Typography
                  style={{
                    color: "#2F3D63",
                    fontFamily: "Rubik, sans-serif",
                    fontSize: "22px",
                    fontWeight: "500",
                  }}
                >
                  ${formatNumber(moneyExposedToRisk)}
                </Typography>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                marginLeft: "20px",
                marginRight: "20px",
                backgroundColor: "#fff",
                zIndex: "3",
              }}
            >
              {/* <Button variant="outlined" style={{fontSize: '10px' ,fontWeight:"500", color: '#FAFAFA' , borderColor: '#E9E9E9',display:"flex",justifyContent:"flex-start",gap:"10px", width : '100%'}}>
        <img src={greenSvg} alt="check" />
          <span style={{textTransform : 'none', fontSize : '12px', fontWeight :'450', color : '#1A2A56'}}>You have collected Financial statements from 12 clients</span>
        </Button> */}
            </div>
            <div style={{ height: "50px" }}>
              <hr style={{ marginLeft: "22px", marginRight: "19px" }} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  margin: "0 3px 5px 10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                    paddingLeft: "10px",
                    marginTop: "10px",
                  }}
                >
                  <img
                    src="/bulbicon.svg"
                    alt="light bulb"
                    style={{ width: "18px", marginRight: "10px" }}
                  />
                  <Typography
                    style={{ fontSize: "14px", paddingTop: "5px", zIndex: "2" }}
                  >
                    <a
                      style={{
                        color: "#5186EC",
                        textDecoration: "underline",
                        wordWrap: "break-word",
                        cursor: "pointer",
                      }}
                      onClick={handleLoadAgingFiles}
                    >
                      Load aging files
                    </a>
                  </Typography>
                  {show_file_upload_button && (
                    <FileUploadModal
                      open={fileModalOpen}
                      close={() => setFileModalOpen(false)}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginLeft: "auto",
                    marginRight: "15px",
                    marginTop: "8px",
                    marginBottom: "auto",
                  }}
                >
                  <DropdownMenu />
                </div>
              </div>
            </div>
          </Card>
        )}
        {show_prospects_card && (
          <Card
            variant="outlined"
            style={{
              height: "263px",
              display: "flex",
              flex: 1,
              flexDirection: "column",
              justifyContent: "space-between",
              boxShadow: "inset 0px 1px 3px #0000001A, 0px 3px 6px #00000029",
              position: "relative",
              backgroundColor: "#FAFAFC",
            }}
          >
            {" "}
            {/** card 3 */}
            <div style={bgStyle}></div>
            <div
              style={{
                padding: "5px 20px 0 20px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                flex: 1,
              }}
            >
              <Typography
                style={{
                  color: "#2F3D63",
                  fontFamily: "Rubik, sans-serif",
                  marginTop: "10px",
                  fontSize: "20px",
                  alignContent: "left",
                  fontWeight: "500",
                }}
              >
                Prospects
              </Typography>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Typography
                  style={{
                    color: "#2f3d63",
                    fontFamily: "Rubik, sans-serif",
                    fontSize: "46px",
                    alignContent: "right",
                    fontWeight: "500",
                  }}
                >
                  {ProspectsRows.length}
                </Typography>
              </div>
            </div>
            <div style={{ flex: 1.5, marginLeft: "1px" }}>
              <Typography
                style={{
                  color: "#2f3d63",
                  paddingLeft: "20px",
                  fontFamily: "Rubik, sans-serif",
                  fontSize: "15px",
                }}
              >
                Sum of approved limit
              </Typography>
              <Typography
                style={{
                  color: "#2f3d63",
                  paddingLeft: "20px",
                  fontWeight: "500",
                  fontFamily: "Rubik, sans-serif",
                  fontSize: "24px",
                }}
              >
                ${formatNumber(totalAmount)}
              </Typography>
            </div>
            <div style={{ height: "50px" }}>
              <hr style={{ marginLeft: "19px", marginRight: "19px" }} />
              <div
                style={{
                  padding: "10px",
                  display: "flex",
                  flexDirection: "row",
                  alignContent: "center",
                  marginLeft: "6px",
                }}
              >
                <img
                  src="/bulbicon.svg"
                  alt="light bulb"
                  style={{ width: "18px", marginRight: "10px" }}
                />
                <Typography
                  style={{ paddingTop: "5px", fontSize: "14px", zIndex: "2" }}
                >
                  <a
                    style={{ color: "#5186EC" }}
                    onClick={() => {
                      setOpenAddCompanyModal(true);
                    }}
                  >
                    <span
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                    >
                      Add company to monitor
                    </span>
                  </a>
                </Typography>
              </div>
            </div>
          </Card>
        )}
      </div>
      {show_customer_risk_bar_chart && (
        <RiskDistributionDiagram rowData={isProspects ? ProspectsRows : rows} />
      )}
      {isProspects || !show_portfolio_score_chart ? null : (
        <PortfolioScoreChart tenantId={tenantId}></PortfolioScoreChart>
      )}
      {isProspects || !show_ar_heatmap ? null : (
        <ARHeatmapNew summary={rows} adminAction={false} tableType={"total"} />
      )}
      {show_summary_table && (
        <RecommendationTable
          rows={isProspects ? ProspectsRows : rows}
          showAtRisk={showAtRisk}
          setShowAtRisk={setShowAtRisk}
          riskyRows={isProspects ? sortProspectRows(gridRowsRC) : sortGridRows(gridRowsRC)}
        />
      )}
      <Box ref={bottomRef} id="bottom" sx={{ height: "1px" }} />
      <Modal
        open={popupOpen}
        onClose={handleClosePopup}
        aria-labelledby="ar-disabled-popup-title"
        aria-describedby="ar-disabled-popup-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            width: "400px",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            borderRadius: "20px",
            boxShadow: "inset 0px 1px 3px #0000001A, 0px 3px 6px #00000029",
            padding: "20px",
            paddingTop: "10px",
            paddingLeft: "45px",
          }}
        >
          <Typography
            id="ar-disabled-popup-title"
            variant="h6"
            component="h2"
            sx={{ color: "#2F3D63" }}
          >
            Accounts Receivable Not Enabled
          </Typography>
          <Typography
            id="ar-disabled-popup-description"
            sx={{ mt: 2, color: "#3d4e7a" }}
          >
            You currently don't have Accounts Receivable enabled. To enable it,
            please click on the link:
            <span style={{ marginLeft: "10px" }}>
              <a
                onClick={(e) => {
                  e.preventDefault();
                  handleSettingsClick();
                }}
                style={{
                  color: "#5186EC",
                  textDecoration: "underline",
                  wordWrap: "break-word",
                  cursor: "pointer",
                }}
              >
                go to settings
              </a>
            </span>
          </Typography>

          <IconButton
            sx={{ position: "absolute", top: 5, left: 5 }}
            onClick={handleClosePopup}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Modal>
      {/* <div
        style={{
          width: "100%",
          height: "85vh",
          marginTop: 80,
        }}
      >
        <Typography variant="h5" mb={5}>
          Summary & Recommendations
        </Typography>
        <DataGridPremium
          sx={{
            ".Mui-hovered": {
              cursor: "pointer",
            },
          }}
          onCellClick={handleCellClick}
          //getRowId={getRowId}
          rows={rows}
          columns={columnDefinition}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          columnVisibilityModel={columnVisibilityModel || {}}
          onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          // autosizeOnMount={true}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 50 },
            },
          }}
          slots={{
            toolbar: GridToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          pageSizeOptions={[5, 10, 50]}
        />
      </div>
      <div style={{ width: "100%", height: "85vh", marginTop: 100 }}>
        <Typography variant="h5" mb={2}>
          10 Most Risky Customers
        </Typography>
        <Box sx={{ display: "flex", gap: "20px" }}>
          <Autocomplete
            size="small"
            sx={{ width: 250, my: 5 }}
            value={selectedOptionRC}
            onChange={handleDropdownChangeForRC}
            options={segmentationTypes}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Segmentation Type"
                variant="outlined"
              />
            )}
          />
          <Autocomplete
            size="small"
            sx={{ width: 250, my: 5 }}
            value={selectedRangeRC}
            onChange={handleRangeChangeRC}
            options={range}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Segmentation Range"
                variant="outlined"
              />
            )}
          />
        </Box>
        <DataGridPremium
          getRowId={getRowId}
          rows={gridRowsRC}
          autosizeOnMount={true}
          columnVisibilityModel={riskyCustomerCVM[selectedOptionRC.label] || {}}
          onColumnVisibilityModelChange={handleCVMChangeForRiskyCustomer}
          columns={colDefWithWidth}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 50 },
            },
          }}
          slots={{
            toolbar: GridToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          pageSizeOptions={[5, 10, 50]}
        />
      </div>
      <div style={{ width: "100%", height: "40vh", marginTop: 200 }}>
        <Typography variant="h5" mb={5}>
          Credit Limit
        </Typography>
        <Autocomplete
          size="small"
          sx={{ width: 250, my: 5 }}
          value={selectedOptionCL}
          onChange={handleDropdownChange}
          options={segmentationTypes}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Segmentation Type"
              variant="outlined"
            />
          )}
        />
        <DataGridPremium
          rows={filteredRows}
          columns={gridColumnsCL}
          // autosizeOnMount={true}
          columnVisibilityModel={creditLimitCVM[selectedOptionCL.label] || {}}
          onColumnVisibilityModelChange={handleCVMChangeForCreditLimit}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 50 },
            },
          }}
          slots={{
            toolbar: GridToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          pageSizeOptions={[5, 10, 50]}
        />
      </div>
      <div style={{ width: "100%", marginTop: 200 }}>
        <CreditLimitBarChartProspects summary={rows} />
      </div>
      <div>
        <RequestManualEvaluationDialog
          title={title}
          comment={comment}
          email={email}
          fullName={fullName}
          phoneNumber={phoneNumber}
          isDialogOpen={isDialogOpen}
          selectedOption={selectedOption}
          specificCreditLimit={specificCreditLimit}
          handleTitleChange={handleTitleChange}
          handleCloseDialog={handleCloseDialog}
          handleCommentsChange={handleCommentsChange}
          handleEmailChange={handleEmailChange}
          handleFullNameChange={handleFullNameChange}
          handleOptionChange={handleOptionChange}
          handlePhoneNumberChange={handlePhoneNumberChange}
          handleSpecificCreditLimitValue={handleSpecificCreditLimitValue}
          handleSubmit={handleSubmit}
        />
      </div> */}
    </>
  );
}
