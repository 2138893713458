import React, { useState, useEffect } from "react";
import { Paper, Typography, Box, Tabs, Tab } from "@mui/material";
import Button from "@mui/material/Button";
import { useLoaderData, useNavigate } from "react-router-dom";
import "./CompanyPage.css";
import { API, Amplify, graphqlOperation } from "aws-amplify";
import { companyDocGenerator, dnbGetCompanyInfo, googleNewsCompanySearch, listLastVisitedCompanies, triggerQuestionsAgent } from "./graphql/queries";
import { getUnreadAlerts } from './utils/GraphQLHelper/AlertTable';
import { useFeatureFlag } from "./context/featureFlagContext";
import {
  deleteCompany,
  deleteGoogleSearch,
  deleteKnowledgeGraph,
  deleteLastVisitedCompanies,
  deleteWikipedia,
  deleteYahooFinance,
  updateCompany,
} from "./graphql/mutations";
import QuestionsAccordion from "./QuestionsAccordion.js";
import { toast } from "react-toastify";
import CompanySummary from "./CompanySummary.js";
import ChatFile from "./ChatFile.js";
import ReactGA from "react-ga4"
import { getTenantInfo } from "./utils/GraphQLHelper/GetTenantInfo.js";
import AWS from 'aws-sdk';
import awsExports from "./aws-exports";
import getTenantIdFromURL from "./utils/getTenantIdFromURL.js";
import { useSelector } from "react-redux";
import ExecutiveSummary from "./ExecutiveSummary.js";
import RefreshIcon from '@mui/icons-material/Refresh';
import Avatar from '@mui/material/Avatar';
import CompanyFinancialInfo from "./CompanyFinancialInfo.js";
import News from "./News.js";
import CompanySummaryEvents from "./companySummaryEvents.js";
import CompanyEventTimeline from "./CompanyEventTimeline/CompanyEventTimeline.js";

const trackingID = "G-9QZ6JYFX19";
const adminTrackingId = "G-VYW7DRSVZE";

Amplify.configure(awsExports);

AWS.config.update(
  {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  }
);

function CompanyPage({adminAction}) {
  const { company } = useLoaderData();
  const [disabled, setDisabled] = useState(false);
  const [summaryData, setSummaryData] = useState([]);
  const [companyOverviewData, setCompanyOverviewData] = useState(null);
  const [companyBalanceSheetData, setCompanyBalanceSheetData] = useState(null);
  const [companyIncomeStatementData, setCompanyIncomeStatementData] = useState(null);
  const [dnBFinancialV2Data, setDnBFinancialV2Data] = useState(null);
  const [tenantName, setTenantName] = useState("");
  const [tenantId,setTenantId] = useState()
  const isAdmin = useSelector(state => state.userInfo.isAdmin);
  const tenantEmail = useSelector(state => {
    const email = state.userInfo.email;
    if (email) {
      const username = email.split('@')[0];
      return username.length > 5 ? `${username.slice(0, 5)}..` : username;
    }
    return null;
  });
  
  const URLTenant = getTenantIdFromURL();
  const currentTenant  = useSelector(state => state.userInfo.tenantId);
  const [selectedTab , setSelectedTab] = useState(0);
  const [agingData , setAgingData] = useState(undefined);
  const [linkedInJSON , setLinkedInJSON] = useState({});
  const [DnbData , setDnbData] = useState();
  const [alerts , setAlerts] = useState([]);
  const [latestAlert , setLatestAlert] = useState("No new alerts!");
  const [currentCompany , setCurrentCompany] = useState({});
  const [questions, setQuestions] = useState(company?.questions?.items||[])
  const [companySummary,setCompanySummary] = useState({})
  const [companyDnbID , setCompanyDnbID] = React.useState(null);
  const [dnbJsonData , setDnbJsonData] = React.useState([]);
  const [showExport , setShowExport] = React.useState(false);
  const [events , setEvents] = React.useState([]);
  const [loading , setLoading] = React.useState(false);
  const [companyEvents, setCompanyEvents] = useState([]);

  const email= useSelector(state => state.userInfo.email);
  console.log("email",email)
  const selectTenantId =()=>{
    if(isAdmin) setTenantId(URLTenant)
    else setTenantId(currentTenant)
  }
  const navigate = useNavigate();
  const timer = React.useRef();

  const tickerArr = company?.ticker?.split(":");
  const tickerSymbol = tickerArr?.length > 1 ? tickerArr[1] : company?.ticker;
  const parentCompanyName = company?.parentCompanyName;

    useEffect(()=>{
      setCompanyDnbID(company?.dnbCompanyId)
    const companySummaryData = summaryData.find(companySummary => companySummary["Company Name"] == company?.name);
    setCurrentCompany(companySummaryData)
    fetchDnbDataFromJson()
  },[summaryData,companyDnbID])
  useEffect(()=>{
    const tempEvents  = getSignificantEvents(company?.summary)
    const companyInfo = getCompanyInfo(company?.summary)
    const recommendation = getRecommendation(company?.summary)
    setCompanySummary({events:tempEvents,companyInfo,recommendation});
    setEvents(tempEvents)
    fetchCompanyEvents();
  },[company])
  
  useEffect(() => {
    const fetchAllData = async ()=>{
      selectTenantId();
      await getDnBFile();
      await fetchTenantName();
      await getSummaryData();
      await getAgingFile();
      await getLinkedInFile();
      await fetchDnBFinancialV2();
      await readJsonFromS3();
    }
    fetchAllData();
  }, [tenantId , company]);

const fetchCompanyEvents = async () => {
    console.log("Current Company [fetchCompanyEvents] --> ", company);

    const payload = {
        body: JSON.stringify({
            company_name: company?.name,
        }),
        httpMethod: "POST",
    };

    console.log("Event [fetchCompanyEvents] --> ", payload);

    let response = await API.graphql(
        graphqlOperation(googleNewsCompanySearch, {
            event: JSON.stringify(payload),
        })
    );

    const getCompanyEventResponse = JSON.parse(
        response.data?.googleNewsCompanySearch
    )?.body;
    const parsedResponse = JSON.parse(getCompanyEventResponse);
    console.log("Get Company Event Response --> ", getCompanyEventResponse);
    console.log("Parsed Response --> ", parsedResponse);

    const sortedResponse = parsedResponse.sort((a, b) => b.date - a.date);

    setCompanyEvents(sortedResponse);
};

  const selectedTenant = getTenantIdFromURL();
  console.log('Current Company -> ', currentCompany);

  const readJsonFromS3 = async () => {
    try {
      const key = `public/alpha-vantage-financial-info/${company?.name}-${tenantId}.json`;
      const s3 = new AWS.S3();
      AWS.config.getCredentials((err) => {
        if (err) {
          console.log("Error retrieving AWS credentials:", err);
        } else {
          console.log("AWS credentials are valid and set up correctly.");
        }
      });
      const data = await s3.getObject({ Bucket: "crediarc-content01853-production", Key: key }).promise();
      const fileData = data.Body.toString('utf-8');
      const jsonData = JSON.parse(fileData);
      setCompanyOverviewData(jsonData.overview);
      setCompanyBalanceSheetData(jsonData.balanceSheet);
      setCompanyIncomeStatementData(jsonData.incomeStatement);
      
    } catch (error) {
      console.log('Failed to read file', error);
    }
  }

  const fetchDnBFinancialV2 = async () => {
    try {
      const key = "public/company-financial-v2/financial-" + companyDnbID + ".json";
      const s3 = new AWS.S3();
      const data = await s3.getObject({ Bucket: "crediarc-content01853-production", Key: key }).promise();
      const fileData = data.Body.toString('utf-8');
      const summary = JSON.parse(fileData);
      console.log("summary v2",summary)
      for (let i = 0; i < summary.length; i++) {
        const row = summary[i];
        for (const key in row) {
          if (typeof row[key] === "string") {
            row[key] = isNaN(parseFloat(row[key].replace(/,/g, "")))
              ? row[key]
              : parseFloat(row[key].replace(/,/g, ""));
          }
        }
      }
      const tempData = summary[0].response;
      setDnBFinancialV2Data(tempData);
      console.log("🚀 V2 data",tempData)
    } catch (error) {
      setDnBFinancialV2Data(null);
      console.log(error);
    }
  };

  const getAlerts = async ()=>{
    try {
      const allAlerts = await getUnreadAlerts();
      setAlerts(allAlerts);
      const filteredAlerts = allAlerts?.filter((alert) => {
        const companyInfo = JSON.parse(alert.companyInfo);
        if (alert.tenantId !== tenantId || company?.name !== companyInfo?.companyName) return false;
        else return true;
      })
      if(filteredAlerts.length!==0)setLatestAlert(filteredAlerts[0].message);
    } catch (error) {
      console.log(error)
    }
}

const getSignificantEvents = (summary) => {
  if (!summary) return [];
  if (
    summary.includes("No significant events were reported at this time.") ||
    summary.includes("No significant events or news were reported at this time.") ||
    summary.includes("No news available.") ||
    summary.includes("No significant events found.")
  ) {
    return [];
  }
  const lines = summary.split("\\n");
  const significantEventsIndex = lines.findIndex(line => line.trim().toUpperCase().includes("3. SIGNIFICANT EVENTS"));

  if (significantEventsIndex === -1) return [];

  const events = [];
  for (let i = significantEventsIndex + 1; i < lines.length; i++) {
    const line = lines[i].trim();
    if (line.startsWith("1.") || line.startsWith("2.") || line.startsWith("4.")) {
      break;
    }
    // Remove leading dashes and extra spaces
    const cleanedLine = line.replace(/^\s*-\s*/, '');
    if (cleanedLine) {
      events.push(cleanedLine.replace(/\"$/, ''));
    }
  }

  return events;
};


const getCompanyInfo = (summary) => {
  if (!summary) return "";

  const lines = summary.split("\\n");
  const companyInfoIndex = lines.findIndex(line => line.trim().toUpperCase().includes("1. COMPANY INFO"));

  if (companyInfoIndex === -1 || companyInfoIndex + 1 >= lines.length) return "";

  let infoLines = [];
  for (let i = companyInfoIndex + 1; i < lines.length; i++) {
    const line = lines[i].trim();
    if (line.startsWith("1.") || line.startsWith("2.") || line.startsWith("3.") || line.startsWith("4.")) {
      break; 
    }
    infoLines.push(line);
  }

  return infoLines.join(" "); 
};

const getRecommendation = (summary) => {
  if (!summary) return "";

  const lines = summary.split("\\n");
  const recommendationIndex = lines.findIndex(line => line.trim().toUpperCase().includes("2. RECOMMENDATION"));

  if (recommendationIndex === -1 || recommendationIndex + 1 >= lines.length) return "";

  let recommendationLines = [];
  for (let i = recommendationIndex + 1; i < lines.length; i++) {
    const line = lines[i].trim();
    if (line.startsWith("1.") || line.startsWith("3.") || line.startsWith("4.")) {
      break;
    }
    recommendationLines.push(line);
  }

  return recommendationLines.join(" ");
};

const WikiInfobox = company.wikipedia?.infobox
    ? JSON.parse(company.wikipedia.infobox)
    : {};

  var dataYear1 = "";
  var dataYear2 = "";
  var dataYear3 = "";
  var dataYear4 = "";
  var year1 = "";
  var revenueYear1 = "";
  var year2 = "";
  var revenueYear2 = "";
  var year3 = "";
  var revenueYear3 = "";
  //1	Revenue - last 3 years
  //financial statement
  //AV.companyIncomeStatementData.annualReports.totalRevenue
  //dnb_financials.response.organization.latestFinancials.companyOverviewData.salesRevenue
  //company_data.wikipedia.infobox.revenue
  if (companyIncomeStatementData) {
    dataYear1 = companyIncomeStatementData?.annualReports[0];
    year1 = dataYear1?.fiscalDateEnding.substring(0, 4);
    revenueYear1 = dataYear1?.totalRevenue;
    dataYear2 = companyIncomeStatementData?.annualReports[1];
    year2 = dataYear2?.fiscalDateEnding.substring(0, 4);
    revenueYear2 = dataYear2?.totalRevenue;
    dataYear3 = companyIncomeStatementData?.annualReports[2];
    year3 = dataYear3?.fiscalDateEnding.substring(0, 4);
    revenueYear3 = dataYear3?.totalRevenue;
    dataYear4 = companyIncomeStatementData?.annualReports[3];
  }
  //2	Net profit - last year

  const financialStatement = null;
  let netProfit = null;
  //financial statement.Net income
  if (financialStatement) {
    netProfit = financialStatement.NetIncome;
  }
  //AV.companyIncomeStatementData.annualReports.netIncome
  else if (companyIncomeStatementData) {
    netProfit = companyIncomeStatementData?.annualReports[0].netIncome;
  }
  //response.organization.latestFinancials.companyOverviewData.netIncome
  //else if() {

  //}
  //wikipedia.infobox.net_income	int
  else if (WikiInfobox) {
    netProfit = WikiInfobox.net_income;
  }

  //3	Market value
  let marketValue = null;
  //AV.companyOverviewData.MarketCapitalization
  if (companyOverviewData) {
    marketValue = companyOverviewData.MarketCapitalization;
  }

  //4	Operating income
  let operatingIncome = null;
  //financial statement
  if (financialStatement) {
    operatingIncome = null;
  }
  //AV.companyIncomeStatementData.annualReports.operatingIncome
  else if (companyIncomeStatementData) {
    operatingIncome = companyIncomeStatementData.annualReports[0].operatingIncome;
  }
  //wikipedia.infobox.operating_income
  else if (WikiInfobox) {
    operatingIncome = WikiInfobox.operating_income;
  }

  //5	Net interest expense
  let netInterestExpense = null;
  //financial statement
  if (financialStatement) {
    netInterestExpense = null;
  }
  //AV.companyIncomeStatementData.annualReports.interestExpense
  else if (companyIncomeStatementData) {
    netInterestExpense = companyIncomeStatementData.annualReports[0].interestExpense;
  }

  //6	EBITDA
  let ebitda = null;
  //financial statement
  if (financialStatement) {
    ebitda = null;
  }
  //AV.companyIncomeStatementData.annualReports.ebitda
  else if (companyIncomeStatementData) {
    ebitda = companyIncomeStatementData.annualReports[0].ebitda;
  }

  //7	Net income
  let netIncome = null;
  //financial statement
  if (financialStatement) {
    netIncome = 0;
  }
  //AV.companyIncomeStatementData.annualReports.netincome
  else if (companyBalanceSheetData) {
    netIncome = companyIncomeStatementData?.annualReports[0].netIncome;
  }
  //response.organization.latestFinancials.companyOverviewData.netIncome
  //wikipedia.infobox.net_income
  else if (WikiInfobox) {
    netIncome = WikiInfobox?.net_income;
  }

  //8	Cash and cash equivalents
  let CashAndCashEquivalents = null;
  //financial statement
  if (financialStatement != null) {
    CashAndCashEquivalents = null;
  }
  //AV.companyBalanceSheetData.annualReports.cashAndCashEquivalentsAtCarryingValue
  else if (companyIncomeStatementData) {
    CashAndCashEquivalents =
      companyBalanceSheetData.annualReports[0].cashAndCashEquivalentsAtCarryingValue;
  }
  //response.organization.latestFinancials.companyOverviewData.cashAndLiquidAssets

  //9	Total Assets
  let totalAssets = null;
  //financial statement
  if (financialStatement) {
    totalAssets = null;
  }
  //AV.companyBalanceSheetData.annualReports.totalAssets
  else if (companyBalanceSheetData) {
    totalAssets = companyBalanceSheetData?.annualReports[0].totalAssets;
  }
  //response.organization.latestFinancials.companyOverviewData.totalAssets	wikipedia.infobox.assets

  //10	Total Assets - last year
  let totalAssetsLastYear = null;
  //financial statement
  if (financialStatement) {
    totalAssetsLastYear = null;
  }
  //AV.companyBalanceSheetData.annualReports.totalAssets
  else if (companyBalanceSheetData) {
    totalAssetsLastYear = companyBalanceSheetData.annualReports[1].totalAssets;
  }
  //response.organization.previousFinancials[0].companyOverviewData.cashAndLiquidAssets

  //11	Short term debt
  let ShortTermDebt = null;
  //financial statement
  if (financialStatement) {
    ShortTermDebt = null;
  }
  //AV.companyBalanceSheetData.annualReports.shortTermDebt
  else if (companyBalanceSheetData) {
    ShortTermDebt = companyBalanceSheetData.annualReports[0].shortTermDebt;
  }

  //12	Long term debt
  let longTermDebt = null;
  //financial statement
  if (financialStatement) {
    longTermDebt = null;
  }
  //AV.companyBalanceSheetData.annualReports.longTermDebt
  else if (companyBalanceSheetData) {
    longTermDebt = companyBalanceSheetData.annualReports[0].longTermDebt;
  }
  //response.organization.latestFinancials.companyOverviewData.longTermDebt

  //13	Total Liabilities
  let TotalLiabilities = null;
  //financial statement
  if (financialStatement) {
    TotalLiabilities = null;
  }
  //AV.companyBalanceSheetData.annualReports.totalLiabilities
  else if (companyBalanceSheetData) {
    TotalLiabilities = companyBalanceSheetData.annualReports[0].totalLiabilities;
  }
  //response.organization.latestFinancials.companyOverviewData.totalLiabilities

  //14	Equity
  let Equity = null;
  //financial statement
  if (financialStatement) {
    Equity = null;
  }
  //AV.companyBalanceSheetData.annualReports.totalShareholderEquity
  else if (companyBalanceSheetData) {
    Equity = companyBalanceSheetData.annualReports[0].totalShareholderEquity;
  }

  //15	Equity - last year
  let EquityLastYear = null;
  //financial statement
  if (financialStatement) {
    EquityLastYear = null;
  }
  //AV.companyBalanceSheetData.annualReports.totalShareholderEquity
  else if (companyBalanceSheetData) {
    EquityLastYear = companyBalanceSheetData.annualReports[1].totalShareholderEquity;
  }

  //16	report date
  let reportDate = null;
  //financial statement
  if (financialStatement) {
    reportDate = null;
  }
  //AV.companyIncomeStatementData.annualReports.fiscalDateEnding
  else if (companyIncomeStatementData) {
    reportDate = companyIncomeStatementData.annualReports[0]?.fiscalDateEnding;
  }
  //response.organization.latestFinancials.financialStatementToDate

  function createData(name, TTM, year1, year2, year3, year4) {
    return { name, TTM, year1, year2, year3, year4 };
  }

  //TTM //last 3 years
  const rows = [
    createData(
      "Total Revenue",
      "-",
      dataYear1?.totalRevenue,
      dataYear2?.totalRevenue,
      dataYear3?.totalRevenue,
      dataYear4?.totalRevenue
    ),
    createData(
      "Cost of Revenue",
      "-",
      dataYear1?.costOfRevenue,
      dataYear2?.costOfRevenue,
      dataYear3?.costOfRevenue,
      dataYear4?.costOfRevenue
    ),
    createData(
      "Gross Profit",
      "-",
      dataYear1?.grossProfit,
      dataYear2?.grossProfit,
      dataYear3?.grossProfit,
      dataYear4?.grossProfit
    ),
    createData(
      "Operating Expense",
      "-",
      dataYear1?.operatingExpenses,
      dataYear2?.operatingExpenses,
      dataYear3?.operatingExpenses,
      dataYear4?.operatingExpenses
    ),
  ];

  var dataYearQ1 = "";
  var dataYearQ2 = "";
  var dataYearQ3 = "";
  var dataYearQ4 = "";

  if (companyOverviewData && companyIncomeStatementData && companyBalanceSheetData) {
    dataYearQ1 = companyIncomeStatementData?.quarterlyReports[0];
    dataYearQ2 = companyIncomeStatementData?.quarterlyReports[1];
    dataYearQ3 = companyIncomeStatementData?.quarterlyReports[2];
    dataYearQ4 = companyIncomeStatementData?.quarterlyReports[3];
  }

  const headingStyle = {
    // height: "16px",
    // textAlign: "left",
    fontSize: "15px",
    // padding: "20px",
  };

  const valueStyle = {
    // textAlign: "left",
    // height: "16px",
    fontSize: "14px",
    fontWeight: "600",
    // paddingLeft: "20px",
    // paddingTop: "5px",
    // marginTop: 30
  };

  function createQuaterData(name, Q1, Q2, Q3, Q4) {
    return { name, Q1, Q2, Q3, Q4 };
  }

  const QuaterRows = [
    createQuaterData(
      "Total Revenue",
      dataYearQ1?.totalRevenue,
      dataYearQ2?.totalRevenue,
      dataYearQ3?.totalRevenue,
      dataYearQ4?.totalRevenue
    ),
    createQuaterData(
      "Cost of Revenue",
      dataYearQ1?.costOfRevenue,
      dataYearQ2?.costOfRevenue,
      dataYearQ3?.costOfRevenue,
      dataYearQ4?.costOfRevenue
    ),
    createQuaterData(
      "Gross Profit",
      dataYearQ1?.grossProfit,
      dataYearQ2?.grossProfit,
      dataYearQ3?.grossProfit,
      dataYearQ4?.grossProfit
    ),
    createQuaterData(
      "Operating Expense",
      dataYearQ1?.operatingExpenses,
      dataYearQ2?.operatingExpenses,
      dataYearQ3?.operatingExpenses,
      dataYearQ4?.operatingExpenses
    ),
  ];

  const assets = (totalAssets + totalAssetsLastYear) * 0.5;
  const ROA = ((netIncome / assets) * 100).toFixed(2);
  const ROE = ((netIncome / (0.5 * (Equity + EquityLastYear))) * 100).toFixed(
    2
  );

  const financeInfoVars = [
    revenueYear1,
    revenueYear2,
    revenueYear3,
    companyIncomeStatementData?.annualReports[0].operatingIncome,
    TotalLiabilities,
    reportDate,
    totalAssets,
    totalAssetsLastYear,
    marketValue,
    netInterestExpense,
    ebitda,
    netIncome,
    CashAndCashEquivalents,
    netProfit,
    Equity,
    EquityLastYear,
    ShortTermDebt,
    longTermDebt,
  ];

  const isAllFinanceInfoVarsInvalid = financeInfoVars.every(
    (v) => v === undefined || v === null || isNaN(v) || v === ""
  );

  const profitabilityRatioVars = [
    ROA,
    ROE,
    operatingIncome / revenueYear1,
    netIncome / revenueYear1,
  ];
  let isAllPRVarsInvalid = profitabilityRatioVars.every(
    (v) => v === undefined || v === null || isNaN(v) || v === ""
  );

  const flattenedValuesQR = QuaterRows.map((obj) => Object.values(obj)).flat();

  const quarterlyReviewVars = [
    dataYearQ1?.fiscalDateEnding,
    dataYearQ2?.fiscalDateEnding,
    dataYearQ3?.fiscalDateEnding,
    dataYearQ4?.fiscalDateEnding,
    ...flattenedValuesQR,
  ];

  let isAllQRVarsInvalid = quarterlyReviewVars.every(
    (v) => v === undefined || v === null || isNaN(v) || v === ""
  );

  const flattenedValuesYR = rows.map((obj) => Object.values(obj)).flat();

  const yearlyReviewVars = [
    dataYearQ1?.fiscalDateEnding,
    dataYearQ2?.fiscalDateEnding,
    dataYearQ3?.fiscalDateEnding,
    dataYearQ4?.fiscalDateEnding,
    ...flattenedValuesYR,
  ];

  let isAllYRVarsInvalid = yearlyReviewVars.every(
    (v) => v === undefined || v === null || isNaN(v) || v === ""
  );

  const isAllVarsInvalid = [
    isAllFinanceInfoVarsInvalid,
    isAllPRVarsInvalid,
    isAllQRVarsInvalid,
    isAllYRVarsInvalid
  ].every(Boolean);

  const formatNumber = (number) => {
    if (isNaN(number) || number === null || number === undefined) {
        return '-'; // Return a placeholder for missing values
    }

    // Convert number to billions
    const millionValue = number / 1e6;

    // Format the result to 2 decimal places
    return millionValue.toFixed(2)
};

const formatMoney = (number)=>{
  if(typeof number== 'string') return number;
    if (number >= 1e9) {
      return `US $${(number / 1e9).toFixed(2)} billion`;
    } else if (number >= 1e6) {
      return `US $${(number / 1e6).toFixed(2)} million`;
    } else if (number >= 1e3) {
      return `US $${(number / 1e3).toFixed(2)} thousand`;
    } else {
      return `US $${number}`;
    }
}

// Initialize the financial data with formatted values
const financialData = {
    // Revenue for the last 3 years
    revenue: {
        year1: formatNumber(revenueYear1),
        year2: formatNumber(revenueYear2),
        year3: formatNumber(revenueYear3),
    },

    // Net Profit
    netProfit: formatNumber(netProfit),

    // Market Value
    marketValue: formatNumber(marketValue),

    // Operating Income
    operatingIncome: formatNumber(operatingIncome),

    // Net Interest Expense
    netInterestExpense: formatNumber(netInterestExpense),

    // EBITDA
    ebitda: formatNumber(ebitda),

    // Net Income
    netIncome: formatNumber(netIncome),

    // Cash and Cash Equivalents
    cashAndCashEquivalents: formatNumber(CashAndCashEquivalents),

    // Total Assets
    totalAssets: formatNumber(totalAssets),
    totalAssetsLastYear: formatNumber(totalAssetsLastYear),

    // Short Term Debt
    shortTermDebt: formatNumber(ShortTermDebt),

    // Long Term Debt
    longTermDebt: formatNumber(longTermDebt),

    // Total Liabilities
    totalLiabilities: formatNumber(TotalLiabilities),

    // Equity
    equity: formatNumber(Equity),
    equityLastYear: formatNumber(EquityLastYear),

    // Report Date
    reportDate: reportDate,

    // Profitability Ratios
    profitabilityRatios: {
        ROA: !isNaN(ROA)?ROA:"-",
        ROE: !isNaN(ROE)?ROE:"-",
        operatingIncomeToRevenue: !isNaN(((operatingIncome / revenueYear1)*100).toFixed(2))?((operatingIncome / revenueYear1)*100).toFixed(2):"-" ,
        netIncomeToRevenue: !isNaN(((netIncome / revenueYear1)*100).toFixed(2))?((netIncome / revenueYear1)*100).toFixed(2):"-" ,
    },

    // Quarterly Data
    quarterlyData: {
        Q1: {
            ...dataYearQ1,
            // Apply formatting to Q1 data
            grossProfit: formatNumber(dataYearQ1.grossProfit),
            totalRevenue: formatNumber(dataYearQ1.totalRevenue),
            costOfRevenue: formatNumber(dataYearQ1.costOfRevenue),
            costofGoodsAndServicesSold: formatNumber(dataYearQ1.costofGoodsAndServicesSold),
            operatingIncome: formatNumber(dataYearQ1.operatingIncome),
            sellingGeneralAndAdministrative: formatNumber(dataYearQ1.sellingGeneralAndAdministrative),
            researchAndDevelopment: formatNumber(dataYearQ1.researchAndDevelopment),
            operatingExpenses: formatNumber(dataYearQ1.operatingExpenses),
            investmentIncomeNet: formatNumber(dataYearQ1.investmentIncomeNet),
            netInterestIncome: formatNumber(dataYearQ1.netInterestIncome),
            interestIncome: formatNumber(dataYearQ1.interestIncome),
            interestExpense: formatNumber(dataYearQ1.interestExpense),
            nonInterestIncome: formatNumber(dataYearQ1.nonInterestIncome),
            otherNonOperatingIncome: formatNumber(dataYearQ1.otherNonOperatingIncome),
            depreciation: formatNumber(dataYearQ1.depreciation),
            depreciationAndAmortization: formatNumber(dataYearQ1.depreciationAndAmortization),
            incomeBeforeTax: formatNumber(dataYearQ1.incomeBeforeTax),
            incomeTaxExpense: formatNumber(dataYearQ1.incomeTaxExpense),
            interestAndDebtExpense: formatNumber(dataYearQ1.interestAndDebtExpense),
            netIncomeFromContinuingOperations: formatNumber(dataYearQ1.netIncomeFromContinuingOperations),
            comprehensiveIncomeNetOfTax: formatNumber(dataYearQ1.comprehensiveIncomeNetOfTax),
            ebit: formatNumber(dataYearQ1.ebit),
            ebitda: formatNumber(dataYearQ1.ebitda),
            netIncome: formatNumber(dataYearQ1.netIncome),
        },
        Q2: {
            ...dataYearQ2,
            grossProfit: formatNumber(dataYearQ2.grossProfit),
            totalRevenue: formatNumber(dataYearQ2.totalRevenue),
            costOfRevenue: formatNumber(dataYearQ2.costOfRevenue),
            costofGoodsAndServicesSold: formatNumber(dataYearQ2.costofGoodsAndServicesSold),
            operatingIncome: formatNumber(dataYearQ2.operatingIncome),
            sellingGeneralAndAdministrative: formatNumber(dataYearQ2.sellingGeneralAndAdministrative),
            researchAndDevelopment: formatNumber(dataYearQ2.researchAndDevelopment),
            operatingExpenses: formatNumber(dataYearQ2.operatingExpenses),
            investmentIncomeNet: formatNumber(dataYearQ2.investmentIncomeNet),
            netInterestIncome: formatNumber(dataYearQ2.netInterestIncome),
            interestIncome: formatNumber(dataYearQ2.interestIncome),
            interestExpense: formatNumber(dataYearQ2.interestExpense),
            nonInterestIncome: formatNumber(dataYearQ2.nonInterestIncome),
            otherNonOperatingIncome: formatNumber(dataYearQ2.otherNonOperatingIncome),
            depreciation: formatNumber(dataYearQ2.depreciation),
            depreciationAndAmortization: formatNumber(dataYearQ2.depreciationAndAmortization),
            incomeBeforeTax: formatNumber(dataYearQ2.incomeBeforeTax),
            incomeTaxExpense: formatNumber(dataYearQ2.incomeTaxExpense),
            interestAndDebtExpense: formatNumber(dataYearQ2.interestAndDebtExpense),
            netIncomeFromContinuingOperations: formatNumber(dataYearQ2.netIncomeFromContinuingOperations),
            comprehensiveIncomeNetOfTax: formatNumber(dataYearQ2.comprehensiveIncomeNetOfTax),
            ebit: formatNumber(dataYearQ2.ebit),
            ebitda: formatNumber(dataYearQ2.ebitda),
            netIncome: formatNumber(dataYearQ2.netIncome),
        },
        Q3: {
            ...dataYearQ3,
            grossProfit: formatNumber(dataYearQ3.grossProfit),
            totalRevenue: formatNumber(dataYearQ3.totalRevenue),
            costOfRevenue: formatNumber(dataYearQ3.costOfRevenue),
            costofGoodsAndServicesSold: formatNumber(dataYearQ3.costofGoodsAndServicesSold),
            operatingIncome: formatNumber(dataYearQ3.operatingIncome),
            sellingGeneralAndAdministrative: formatNumber(dataYearQ3.sellingGeneralAndAdministrative),
            researchAndDevelopment: formatNumber(dataYearQ3.researchAndDevelopment),
            operatingExpenses: formatNumber(dataYearQ3.operatingExpenses),
            investmentIncomeNet: formatNumber(dataYearQ3.investmentIncomeNet),
            netInterestIncome: formatNumber(dataYearQ3.netInterestIncome),
            interestIncome: formatNumber(dataYearQ3.interestIncome),
            interestExpense: formatNumber(dataYearQ3.interestExpense),
            nonInterestIncome: formatNumber(dataYearQ3.nonInterestIncome),
            otherNonOperatingIncome: formatNumber(dataYearQ3.otherNonOperatingIncome),
            depreciation: formatNumber(dataYearQ3.depreciation),
            depreciationAndAmortization: formatNumber(dataYearQ3.depreciationAndAmortization),
            incomeBeforeTax: formatNumber(dataYearQ3.incomeBeforeTax),
            incomeTaxExpense: formatNumber(dataYearQ3.incomeTaxExpense),
            interestAndDebtExpense: formatNumber(dataYearQ3.interestAndDebtExpense),
            netIncomeFromContinuingOperations: formatNumber(dataYearQ3.netIncomeFromContinuingOperations),
            comprehensiveIncomeNetOfTax: formatNumber(dataYearQ3.comprehensiveIncomeNetOfTax),
            ebit: formatNumber(dataYearQ3.ebit),
            ebitda: formatNumber(dataYearQ3.ebitda),
            netIncome: formatNumber(dataYearQ3.netIncome),
        },
        Q4: {
          ...dataYearQ4,
          grossProfit: formatNumber(dataYearQ4.grossProfit),
          totalRevenue: formatNumber(dataYearQ4.totalRevenue),
          costOfRevenue: formatNumber(dataYearQ4.costOfRevenue),
          costofGoodsAndServicesSold: formatNumber(dataYearQ4.costofGoodsAndServicesSold),
          operatingIncome: formatNumber(dataYearQ4.operatingIncome),
          sellingGeneralAndAdministrative: formatNumber(dataYearQ4.sellingGeneralAndAdministrative),
          researchAndDevelopment: formatNumber(dataYearQ4.researchAndDevelopment),
          operatingExpenses: formatNumber(dataYearQ4.operatingExpenses),
          investmentIncomeNet: formatNumber(dataYearQ4.investmentIncomeNet),
          netInterestIncome: formatNumber(dataYearQ4.netInterestIncome),
          interestIncome: formatNumber(dataYearQ4.interestIncome),
          interestExpense: formatNumber(dataYearQ4.interestExpense),
          nonInterestIncome: formatNumber(dataYearQ4.nonInterestIncome),
          otherNonOperatingIncome: formatNumber(dataYearQ4.otherNonOperatingIncome),
          depreciation: formatNumber(dataYearQ4.depreciation),
          depreciationAndAmortization: formatNumber(dataYearQ4.depreciationAndAmortization),
          incomeBeforeTax: formatNumber(dataYearQ4.incomeBeforeTax),
          incomeTaxExpense: formatNumber(dataYearQ4.incomeTaxExpense),
          interestAndDebtExpense: formatNumber(dataYearQ4.interestAndDebtExpense),
          netIncomeFromContinuingOperations: formatNumber(dataYearQ4.netIncomeFromContinuingOperations),
          comprehensiveIncomeNetOfTax: formatNumber(dataYearQ4.comprehensiveIncomeNetOfTax),
          ebit: formatNumber(dataYearQ4.ebit),
          ebitda: formatNumber(dataYearQ4.ebitda),
          netIncome: formatNumber(dataYearQ4.netIncome),
      },
    },

    // Yearly Data
    yearlyData: {
        rows: rows.map(row => ({
            name: row.name,
            TTM: row.TTM,
            year1: formatNumber(row.year1),
            year2: formatNumber(row.year2),
            year3: formatNumber(row.year3),
            year4: formatNumber(row.year4),
        })),
         dataYear1 ,
         dataYear2 ,
         dataYear3 ,
         dataYear4 ,
    },
};

  const google = company.googleSearch?.knowledgeGraph?.raw
    ? JSON.parse(company.googleSearch.knowledgeGraph.raw)
    : {};

  function findDnBDataByName(companyName) {
    return DnbData?.find((DnbData) => DnbData.primaryName === companyName);
  }



  const fetchDnbDataFromJson = async () => {
    if(company?.dnbCompanyId){
    try {
      const key = "public/company-info/info-"+ company?.dnbCompanyId +".json";
      const s3 = new AWS.S3();
      const data = await s3.getObject({ Bucket: "crediarc-content01853-production", Key: key }).promise();
      const fileData = data.Body.toString('utf-8');
      const currentCompany = JSON.parse(fileData);
      for (let i = 0; i < currentCompany.length; i++) {
        const row = currentCompany[i];
        for (const key in row) {
          if (typeof row[key] === "string") {
            row[key] = isNaN(parseFloat(row[key].replace(/,/g, "")))
              ? row[key]
              : parseFloat(row[key].replace(/,/g, ""));
          }
        }
      }
      console.log("setDnbJsonData",currentCompany[0]?.response)
      setDnbJsonData(currentCompany[0]?.response);
      
    } catch (error) {
      setDnbJsonData({})
      console.log(error);
    }
  }

  };


  var ARCScore = "";
  var legalScore = "";
  var alternativeScore = "";
  var countryScore = "";
  var riskScore = "";
  var recommendedLimit = "";
  var underwritingReason = "";
  var currentArBalance = "";
  var totalArBalance = "";
  var termsRecommended = "";
  var currentRecommended = "";
  var paydex = "";
  var tenure = "";
  var avgCustomerPaymentDays = "";
  var arear = "-"
  var delinquencyScore = "";
  var failureScore = "";
  var altmanZScore = "";
  let displayScores;

  function parseScore(value) {
    const parsedValue = parseFloat(value);
    return isNaN(parsedValue) || value === null || value === undefined ? undefined : parsedValue;
}

  if (currentCompany) {
    ARCScore = parseScore(currentCompany["Combined Score"]);
    legalScore = parseScore(currentCompany["Legal Score"]);
    alternativeScore = parseScore(currentCompany["Alternative Score"]);
    countryScore = parseScore(currentCompany["Country Score"]);
    delinquencyScore = parseScore(currentCompany["Delinquency Score"]);
    failureScore = parseScore(currentCompany["Failure Score"]);
    riskScore = parseScore(currentCompany["Risk Segment"]);
    altmanZScore = parseScore(currentCompany["Altman Z Score"]);

    
    const scores = [
      { label: "Risk Segment", value: riskScore },
      { label: "Alternative Score", value: alternativeScore },
      { label: "Delinquency Score", value: delinquencyScore },
      { label: "Legal Score", value: legalScore },
      { label: "Failure Score", value: failureScore },
      { label: "Country Score", value: countryScore },
      { label: "Altman Z Score", value: altmanZScore },
    ];
    
    // Filter out scores that are undefined or null
    const availableScores = scores.filter((score) => score.value !== undefined && score.value !== null);
    console.log("availableScores",availableScores)
    // Select the top 4 scores based on priority, only if there are more than 4 available scores
     displayScores = availableScores.length > 4 ? availableScores.slice(0, 4) : availableScores;
    

    recommendedLimit = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(currentCompany["Recommended Limit"]);
    
    currentArBalance = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(currentCompany["Current AR Balance"]);

    totalArBalance = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(currentCompany["Total AR Balance"]);
    
    let recommendedNet = currentCompany["Recommended Net"];
    if (recommendedNet && recommendedNet.startsWith('Net ')) {
      recommendedNet = recommendedNet.substring(4);
    }
    recommendedNet = parseInt(recommendedNet) || '-';
    termsRecommended = recommendedNet;

    let currentNet = currentCompany["Current Net"];
    if (currentNet && currentNet.startsWith('Net ')) {
      currentNet = currentNet.substring(4);
    }
    currentNet = parseInt(currentNet) || '-';
    currentRecommended = currentNet;

    paydex = currentCompany["Paydex"] ? parseInt(currentCompany["Paydex"]) : "-";
    tenure = currentCompany["Tenure"] ? parseInt(currentCompany["Tenure"]) : "-";
    avgCustomerPaymentDays = currentCompany["AVG Customer Payment Days"] ? currentCompany["AVG Customer Payment Days"] : "-";
      
    underwritingReason = currentCompany["Underwriting Reason"]=='Limited based on netIncomeFromContinuingOperations trend'?'Limited based on Net Income From Continuing Operations Trend':currentCompany["Underwriting Reason"];
  }

  const descriptionWikipedia = "";
  const regex = /\{\{Short description\|(.+?)\}\}/;
  const match = company?.wikipedia?.content?.match(regex);
  if (match) {
    const descriptionWikipedia = match[1];
    console.log(descriptionWikipedia);
  } else {
    console.log("No short description found");
  }

  const getDescription = () => {
    if (companyOverviewData?.Description) {
      return companyOverviewData.Description;
    } else if (descriptionWikipedia !== "") {
      return descriptionWikipedia;
    } else if (WikiInfobox?.description?.manual === true) {
      return WikiInfobox.description.value;
    }else if (WikiInfobox?.description) {
      return WikiInfobox.description;
    }
     else {
      return "";  
    }
  };
  const getSector = () => {
    if (agingData?.["Entity:Industry"]) {
      return agingData?.["Entity:Industry"];
    } else if (companyOverviewData?.sector) {
      return companyOverviewData.sector;
    } else if (dnbGetCompanyInfo?.primaryIndustryCodes) {
      return dnbGetCompanyInfo.primaryIndustryCodes[0].usSicV4Description;
    }
    else if (WikiInfobox?.industry?.manual === true) {
      return WikiInfobox.industry.value;
    } else if (WikiInfobox?.industry) {
      return WikiInfobox.industry;
    } 
     else {
      return "";  
    }
  };
  function extractYear(dateString) {
    if (typeof dateString !== 'string') {
        return "";
    }

    const yearPattern = /\b(\d{4})\b/;
    const monthDayYearPattern = /\b(?:January|February|March|April|May|June|July|August|September|October|November|December)\s+\d{1,2},\s+(\d{4})\b/;
    const monthYearPattern = /\b(?:January|February|March|April|May|June|July|August|September|October|November|December)\s+(\d{4})\b/;
    
    let match = dateString.match(monthDayYearPattern);
    if (match) {
        return match[1];
    }
    
    match = dateString.match(monthYearPattern);
    if (match) {
        return match[1];
    }
    
    match = dateString.match(yearPattern);
    if (match) {
        return match[1];
    }
    
    return "";
}
const getFounded = () => {
  if (company?.googleSearch?.knowledgeGraph?.raw) {
    const date = JSON.parse(company.googleSearch.knowledgeGraph.raw)?.founded;
    return date || "";
  } else if (WikiInfobox?.founded?.manual === true) {
    return WikiInfobox.founded.value;
  } else if (WikiInfobox?.foundation?.manual === true) {
    return WikiInfobox.foundation.value;
  }else if (WikiInfobox?.foundation) {
    return WikiInfobox.foundation;
  }
   else if (WikiInfobox?.founded) {
    return WikiInfobox.founded;
  } else {
    return "";  
  }
};

const getSymbol = () => {
  if (companyOverviewData?.Symbol) {
    return companyOverviewData.Symbol + " + " + companyOverviewData.Exchange;
  } else if (company?.ticker) {
    return company.ticker;
  } else if (WikiInfobox?.traded_as?.manual === true) {
    return WikiInfobox.traded_as.value;
  }  else if (WikiInfobox?.traded_as) {
    return WikiInfobox.traded_as;
  } 
  else {
    return "";  
  }
};
  const formatToUSD = (yearlyRevenue) => {
    let usdRevenue = null;
    for (let revenue of yearlyRevenue) {
        if (revenue.currency === "USD") {
            usdRevenue = revenue.value;
            break;
        }
    }
    return usdRevenue;
};
const getRevenue = () => {
  if(dnbJsonData?.[0]?.response?.organization?.financials?.[0]?.yearlyRevenue ) {
    const allRevenues = dnbJsonData?.[0]?.response?.organization?.financials?.[0]?.yearlyRevenue || [];
    const usdRevenue = formatToUSD(allRevenues);
    return usdRevenue;
  } else if (WikiInfobox?.revenue?.manual === true) {
    return WikiInfobox.revenue.value;
  }else if (WikiInfobox?.revenue) {
    return WikiInfobox.revenue;
  } 
};
  const getMarketCap = () => {
    //AV_data.Overview.MarketCapitalization
    if (companyOverviewData?.MarketCapitalization) {
      return formatNumber(companyOverviewData.MarketCapitalization);
    }
  };
  const getNumberOfEmployees = () => {
    if (dnbGetCompanyInfo?.numberOfEmployee) {
      return dnbGetCompanyInfo.numberOfEmployee[0].value;
    } else if (dnbJsonData && dnbJsonData[0]?.response?.organization?.numberOfEmployees[0]?.value) {
      return dnbJsonData[0].response.organization.numberOfEmployees[0].value;
    } else if (WikiInfobox?.num_employees?.manual === true) {
      return WikiInfobox.num_employees.value;
    }  else if (WikiInfobox?.num_employees) {
      return WikiInfobox.num_employees;
    }
    else {
      return ""; 
    }
  };
  const getCreditRating = () => {
    //financial statement.credit rating or rating
    return "";
  };
  const getLocation = () => {
    if (dnbGetCompanyInfo?.primaryAddress) {
      return dnbGetCompanyInfo.primaryAddress.addressLocality + " , " + dnbGetCompanyInfo.primaryAddress.addressCountry;
    } else if (google?.headquarters) {
      return google.headquarters;
    } else if (company?.country) {
      return company.country;
    } else if (WikiInfobox?.hq_location_country?.manual === true) {
      return WikiInfobox.hq_location_country.value+ " , " + WikiInfobox.hq_location_city.value;
    }else if (WikiInfobox?.hq_location_country) {
      return WikiInfobox.hq_location_country + " , " + WikiInfobox.hq_location_city;
    }
     else {
      return ""; // Default to empty string if no data available
    }
  };
  const getFounders = () => {
    if (WikiInfobox?.founder?.manual === true) {
      return WikiInfobox.founder.value;
    } else if (WikiInfobox?.founder) {
      return WikiInfobox.founder;
    } 
    else {
      return ""; // Default to empty string if no data available
    }
  };
  const getKeyPeople = () => {
    if (WikiInfobox?.key_people?.manual === true) {
      return WikiInfobox.key_people.value;
    }
    else if (WikiInfobox?.key_people) {
      return WikiInfobox.key_people;
    }
     else {
      return ""; // Default to empty string if no data available
    }
  };
  const getParentCompany = () => {
    if (WikiInfobox?.parent?.manual === true) {
      return WikiInfobox.parent.value;
    }else  if (WikiInfobox?.parent) {
      return WikiInfobox.parent;
    }
     else {
      return "";  
    }
  };
  let Description = getDescription();
  if (Description.length > 100) {
    Description = Description.substring(0, 100) + "...";
  }
  let Main_sector = getSector();
  let Symbol = getSymbol();
  let founded = getFounded();
  let Revenue = getRevenue();
  let Number_of_employees = getNumberOfEmployees();
  let Main_location_office = getLocation();
  let Market_cap = getMarketCap();
  let Credit_Rating = getCreditRating();
  let founders = getFounders();
  let Key_staff = getKeyPeople();
  let Parent_company = getParentCompany();

  const rightCardFields = {
    Description,
    Main_sector,
    founded,
    Symbol,
    Revenue,
    Market_cap,
    Number_of_employees,
    Credit_Rating,
    Main_location_office,
    founders,
    Key_staff,
    Parent_company
  };
  

  const infoboxCard = [
    { label: "Description", value: rightCardFields.Description },
    { label: "Main Sector", value: rightCardFields.Main_sector },
    { label: "Founded", value: rightCardFields.founded },
    { label: "Symbol", value: rightCardFields.Symbol },
    { label: "Revenue", value: formatMoney(rightCardFields?.Revenue) },
    { label: "Market Cap", value: rightCardFields.Market_cap },
    { label: "Number of Employees", value: rightCardFields.Number_of_employees },
    { label: "Credit Rating", value: rightCardFields.Credit_Rating },
    { label: "Main Office Location", value: rightCardFields.Main_location_office },
    { label: "Founders", value: rightCardFields.founders },
    { label: "Key Staff", value: rightCardFields.Key_staff },
    { label: "Parent Company", value: rightCardFields.Parent_company }
  ];

  const executiveSummary = {
    ARCScore,
    legalScore,
    alternativeScore,
    countryScore,
    riskScore,
    recommendedLimit,
    underwritingReason,
    currentArBalance,
    totalArBalance,
    termsRecommended,
    currentRecommended,
    paydex,
    tenure,
    avgCustomerPaymentDays,
    arear,
    delinquencyScore,
    failureScore,
    altmanZScore,
    displayScores,
    requestedCreditLimit:new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(company?.requestedCreditLimit),
    infoboxCard,
    location:Main_location_office
  };

  const base64ToBlob = (base64String, mimeType) => {
    // Add padding if needed
    const paddedBase64String = base64String.padEnd(base64String.length + (4 - (base64String.length % 4)) % 4, '=');
    
    const byteCharacters = atob(paddedBase64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
};


const downloadBlob = (blob, fileName) => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    toast.success('The report is generated!');
};
console.log("template data",{companySummary,questions,executiveSummary,financialData,company,website:getWebsiteLink(company?.googleSearch),dnbJsonData,financialDataNotAvailable:isAllVarsInvalid})
  function DownloadButton() {

    const generatePDF = async () => {
      setLoading(true)
      toast.info("The report is being generated, we'll update once it's ready");
      const data = {companySummary,questions,executiveSummary,financialData,company,website:getWebsiteLink(company?.googleSearch),dnbJsonData,financialDataNotAvailable:isAllVarsInvalid}
      const payload = {
        data:JSON.stringify(data),
        tenantId
      }
      console.log("data",data)
      try {
      const res = await API.graphql(
        graphqlOperation(companyDocGenerator, payload)
      );
      
      const base64String = JSON.parse(res.data.companyDocGenerator).body;
      console.log("Base64 String:", base64String);
        const pdfBlob = base64ToBlob(base64String, 'application/pdf');
        downloadBlob(pdfBlob, `${company?.name} - Crediarc Report.pdf`);
        setLoading(false)
      } catch (error) {
        console.error('Error generating PDF:', error);
      toast.error("Error while generating report");
      setLoading(false)
      }
    };


     return (
      <Button onClick={generatePDF}
      style={{
        color: loading ? "#A9A9A9" : "#5186EC", 
        textTransform: 'none',
        fontFamily: 'Rubik, sans-serif',
        width: "49px",
        height: "17px",
        fontWeight: "250",
        cursor: loading ? "not-allowed" : "pointer", 
      }}
      >
      <img src = '/exportIcon.png' alt =  'export Icon' style={{ 
          width: "30px", 
          height: "22px", 
          filter: loading ? "grayscale(100%)" : "none", 
          opacity: loading ? 0.5 : 1 
        }} />
      Export
    </Button>
  );
  }

  // setFinancialInfo(financialData)

  function DashboardButton({ company }) {
    const [inDashboard, setInDashboard] = useState(company.view);
    const [inDashboardBtnTxt, setInDashboardBtnTxt] = useState(
      company.view
        ? company.view === 1
          ? "Remove from Company List"
          : "Add to Company List"
        : "Add to Company List"
    );
    const handleInDashboard = async () => {
      const newView = inDashboard === 0 ? 1 : 0;
      await API.graphql(
        graphqlOperation(updateCompany, {
          input: {
            tenantId: company.tenantId,
            id: company.id,
            view: newView,
          },
        })
      );

      setInDashboard(newView);
      setInDashboardBtnTxt(
        newView === 0 ? "Add to Company List" : "Remove from Company List"
      );
    };
    return (
      <Button variant="outlined" onClick={handleInDashboard}>
        {inDashboardBtnTxt}
      </Button>
    );
  }

  const handleDelete = async () => {
    try {

      ReactGA.event({
        category: isAdmin ? 'Admin User' : 'User',
        action: isAdmin ? `Del_comp-${tenantEmail}_admin_${tenantName}` : `Del_comp-${tenantEmail}_${tenantName}`
      });

      const companyInput = {
        tenantId: company.tenantId,
        id: company.id,
      };
      const result = await API.graphql(
        graphqlOperation(listLastVisitedCompanies, {
          tenantId: tenantId,
        })
      );
      const lastVisitedCompany =  result.data.listLastVisitedCompanies.items.filter((elem)=>elem.companyId===company.id)
      // console.log("elem.companyId",elem.companyId)
      console.log("cmapnyId",company.id)
      console.log("lastVisitedCompany",lastVisitedCompany)
      if(lastVisitedCompany[0]?.lastOpenedAt){
        await API.graphql(
          graphqlOperation(deleteLastVisitedCompanies, {
            input: {
              tenantId: company.tenantId,
            lastOpenedAt:lastVisitedCompany[0]?.lastOpenedAt
          },
        })
      );
    console.log(`deleted lastOpenedAt for company ID: ${company.id}`);
    }

      const deleteCompanyResponse = await API.graphql(
        graphqlOperation(deleteCompany, {
          input: companyInput,
        })
      );

      console.log("Company deleted:", deleteCompanyResponse);

      if (
        company.companyGoogleSearchId &&
        company.companyGoogleSearchTenantId
      ) {
        const deleteGoogleSearchResponse = await API.graphql(
          graphqlOperation(deleteGoogleSearch, {
            input: {
              id: company.companyGoogleSearchId,
              tenantId: company.companyGoogleSearchTenantId,
            },
          })
        );
        console.log("Google Search deleted:", deleteGoogleSearchResponse);

        if (
          company.googleSearch.googleSearchKnowledgeGraphId &&
          company.googleSearch.googleSearchKnowledgeGraphTenantId
        ) {
          const deleteKnowledgeGraphResponse = await API.graphql(
            graphqlOperation(deleteKnowledgeGraph, {
              input: {
                id: company.googleSearch.googleSearchKnowledgeGraphId,
                tenantId:
                  company.googleSearch.googleSearchKnowledgeGraphTenantId,
              },
            })
          );
          console.log(
            "Google Search Knowledge Graph deleted:",
            deleteKnowledgeGraphResponse
          );
        }
      }

      if (company.companyWikipediaId && company.companyWikipediaTenantId) {
        const deleteWikipediaResponse = await API.graphql(
          graphqlOperation(deleteWikipedia, {
            input: {
              id: company.companyWikipediaId,
              tenantId: company.companyWikipediaTenantId,
            },
          })
        );
        console.log("Wikipedia deleted:", deleteWikipediaResponse);
      }

      if (
        company.companyYahooFinanceId &&
        company.companyYahooFinanceTenantId
      ) {
        const deleteYahooFinanceResponse = await API.graphql(
          graphqlOperation(deleteYahooFinance, {
            input: {
              id: company.companyYahooFinanceId,
              tenantId: company.companyYahooFinanceTenantId,
            },
          })
        );
        console.log("Yahoo Finance deleted:", deleteYahooFinanceResponse);
      }
      if(isAdmin) navigate(`/tenant-dashboard/${selectedTenant}/company`);
      else navigate("/company");
    } catch (error) {
      console.error("Error deleting company:", error);
    }
  };

  const onTriggerAgentClick = async () => {
    
    ReactGA.event({
      category: isAdmin ? 'Admin User' : 'User',
      action: isAdmin ? `Trigger_QA-${tenantEmail}_admin_${tenantName}` : `Trigger_QA-${tenantEmail}_${tenantName}`
    });

    toast.success(`Trigger Agent started!`, {
      bodyStyle: {
        fontWeight: "bold",
      }
    });
    setDisabled(true);
    await API.graphql(
      graphqlOperation(triggerQuestionsAgent, {
        tenantId: company.tenantId,
        id: company.id,
      })
    );
    timer.current = window.setTimeout(() => {
      setDisabled(false);
    }, 16000);

  };
  const getDnBFile = async () => {
    if(tenantId){
    try {
      const key = "public/dnbData.json";
      const s3 = new AWS.S3();
      AWS.config.getCredentials((err) => {
        if (err) {
          console.log("Error retrieving AWS credentials:", err);
        } else {
          console.log("AWS credentials are valid and set up correctly.");
        }
      });
      const data = await s3.getObject({ Bucket: "crediarc-content01853-production", Key: key }).promise();
      const fileData = data.Body.toString('utf-8');
      const summary = JSON.parse(fileData);

      // summary is a list of objects. It can have strings which are formatted numbers (e.g. "1,000,000")
      // or numbers (e.g. 1000000). We need to convert the strings to numbers.
      for (let i = 0; i < summary.length; i++) {
        const row = summary[i];
        for (const key in row) {
          if (typeof row[key] === "string") {
            row[key] = isNaN(parseFloat(row[key].replace(/,/g, "")))
              ? row[key]
              : parseFloat(row[key].replace(/,/g, ""));
          }
        }
      }
      setDnbData(summary);
      console.log("DNB DATA: " , DnbData);
    } catch (error) {
      console.log(error);
      return null;
    }
  }
  };

  const getFileFromS3 = async (key) => {
    try {
      const s3 = new AWS.S3();
      const data = await s3
        .getObject({
          Bucket: "crediarc-content01853-production",
          Key: key,
        })
        .promise();
      return data.Body.toString("utf-8");
    } catch (error) {
      console.log("Error retrieving file from S3:", error);
      return null;
    }
  };

  const parseSummaryData = (data) => {
    const summary = JSON.parse(data);
    return summary.map((row) => {
      const formattedRow = { ...row };
      Object.keys(formattedRow).forEach((key) => {
        if ((key !== "name" && key !== "Company Name" ) && typeof formattedRow[key] === "string") {
          const value = parseFloat(formattedRow[key].replace(/,/g, ""));
          formattedRow[key] = isNaN(value) ? formattedRow[key] : value;
        }
      });
      return formattedRow;
    });
  };

  const getAgingFile = async () => {
    if (!tenantId) return;
  
    if (company?.isManuallyAdded) {
      console.log("Findind Manual Aging Data...")
      const key = `public/aging-json-files/${tenantId}/${company?.agingFileName}`;
      const fileData = await getFileFromS3(key);
      
      if (fileData) {
        const summary = parseSummaryData(fileData);
        console.log("fileData",summary)
        const matchedItem = summary?.find(
          (item) => item["Company Name"].toLowerCase() === company?.name?.toLowerCase()
        );
        console.log("AGING DATA: ", matchedItem);
        setAgingData(matchedItem);
      }
    } else {
      console.log("Findind Aging Data...")
      const key = `public/aging_${tenantId}.json`;
      const fileData = await getFileFromS3(key);
      
      if (fileData) {
        const summary = parseSummaryData(fileData);
        const matchedItem = summary?.find(
          (item) => item["name"].toLowerCase() === company?.name?.toLowerCase()
        );
        console.log("AGING DATA: ", matchedItem);
        setAgingData(matchedItem);
      }
    }
    return null;
  };
  const getLinkedInFile = async () => {
    if(company?.linkedInFileName){ // LinkedIn logic
    try {
      const key = company?.linkedInFileName; // check file name
      const s3 = new AWS.S3();
      const data = await s3.getObject({ Bucket: "crediarc-linkedin-data", Key: key }).promise();
      const fileData = data.Body.toString('utf-8');
      const linkedInFile = JSON.parse(fileData);
      console.log("🚀 linkedIn File",linkedInFile)
      setLinkedInJSON(linkedInFile);
    } catch (error) {
      console.log(error);
      return null;
    }
  }
  };

  const getSummaryData = async () => {
    getAlerts();
    if(tenantId){
    try {
      const key = 'public/summary_' + tenantId + '.json';
      const s3 = new AWS.S3();
      AWS.config.getCredentials((err) => {
        if (err) {
          console.log("Error retrieving AWS credentials:", err); 
        } else {
          console.log("AWS credentials are valid and set up correctly.");
        }
      }); 
      const params = {
        Bucket: "crediarc-content01853-production",
        Key: key,
        IfModifiedSince: new Date(0)
    };
      const data = await s3.getObject(params).promise();
      const fileData = data.Body.toString('utf-8');
      const summary = JSON.parse(fileData);
      for (let i = 0; i < summary.length; i++) {
        const row = summary[i];
        for (const key in row) {
          if (key !== "Company Name" &&  typeof row[key] === 'string') {
            row[key] = isNaN(parseFloat(row[key].replace(/,/g, ''))) ? row[key] : parseFloat(row[key].replace(/,/g, ''));
          }
        }
      }
      setSummaryData(summary);
      return { summary }

    } catch (error) {
      console.log(error);
      return null;
    }
  }
  }

  const fetchTenantName = async () => {
    const {name} = await getTenantInfo();
    setTenantName(name);
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            {children}
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };


const CircleWithIcon = () => {
  const circleStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '20px', // Adjust the size as needed
    height: '20px', // Adjust the size as needed
    background: '#E7BF3C33',
    borderRadius: '50%',
    opacity: 1,
  };


  return (
    <div style={circleStyle}>
      <img src={'/bulbicon.svg'} style={{height: "12px" , width: "12px"}} alt="bulbicon"/>
    </div>
  );
};

function getWebsiteLink(data) {
  if (data) {
  const { knowledgeGraph, organicResult } = data;
  let websiteLink = knowledgeGraph?.website;

  if (!websiteLink && organicResult?.items) {
    const companyNameWords = company?.name.split(' ') || [];
    for (const element of organicResult.items) {
      for (const word of companyNameWords) {
        const urlRegex = /^(http|https):\/\/(?!.*(linkedin\.com|youtube\.com|facebook\.com|twitter\.com|instagram\.com|pinterest\.com|tiktok\.com|snapchat\.com|reddit\.com))[^\s/$.?#].[^\s]*$/;
        if (element?.source.toLowerCase().includes(word.toLowerCase()) && urlRegex.test(element?.link)) {
          websiteLink = element?.link;
          return websiteLink;
        }
      }
    }
  }
  return websiteLink;
}
}
const website = getWebsiteLink(company?.googleSearch);

const {isFeatureFlag, setFeatureFlag} = useFeatureFlag(); 
const {
  show_company_card,
  show_events,
  show_executive_summary,
  show_files_section,
  show_finance_info,
  show_news,
  show_questions_section
} = isFeatureFlag?.["Company_Cards_Page"] || {} ;

  return (
    <div
      className="CompanyPage"
      style={{ width: "100%", backgroundColor: "#ECEDF0" }}
    >
      <div>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Box
            style={{ display: "flex", alignItems: "center", padding: "20px" , justifyContent: "center" }}
          >
            <Avatar
              sx={{ width: 50, height: 50, cursor: "pointer" }}
              src= {company.logo ||
                // google favicon search
                `https://www.google.com/s2/favicons?sz=64&domain=${website}`
              }
            />
            <Typography
              style={{
                height: "33px",
                paddingLeft: "20px",
                fontFamily: "Rubik, sans-serif",
                fontSize: "28px",
                color: "#1A2A56",
                fontWeight: "500",
                paddingRight: "20px",
              }}
            >
              {company?.name}
            </Typography>
            <Box paddingTop={"15px"}>
            <Box
              style={{
                width: "fit-content",
                display: "flex",
                alignContent: "center",
                background: "#FFFFFF",
                border: "1px solid #E9E9E9",
                borderRadius: "8px",
                opacity: 1,
                padding: '5px'
              }}
            >
              <CircleWithIcon />
              <Typography sx={{ fontSize: "14px", color: "#2F3D63" , paddingLeft: '10px' }}>
                {latestAlert}
              </Typography>
            </Box>
            </Box>
          </Box>
          <Box
            style={{
              height: "17px",
              width: "310px",
              gap: "8px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              paddingTop: "30px",
              paddingRight: "10px"
            }}
          >
            <Button
              onClick={handleDelete}
              style={{
                color: "#5186EC",
                width: "180px",
                height: "17px",
                fontSize: "14px",
                textTransform: "none",
                fontFamily: "Rubik, sans-serif",
               fontWeight: "250"
              }}
            >
              <img src = '/binIcon.png' alt =  'Bin Icon'
                style={{ width: "22px", height: "17px" }}
              />
              Delete Company
            </Button>
            <Button
              onClick={onTriggerAgentClick}
              disabled={disabled}
              style={{
                color: "#5186EC",
                textTransform: "none",
                fontFamily: "Rubik, sans-serif",
                width: "56px",
                height: "17px",
               fontWeight: "250"
              }}
              startIcon={<RefreshIcon style={{ color: "#5186EC" }} />}
            >
              Refresh
            </Button>
            {showExport?<DownloadButton jsonData={company} filename={company?.name} />:null}
            
          </Box>
        </Box>
      </div>
      <Paper
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          backgroundColor: "#FAFAFC",
        }}
      >
        {/* <Box my={5}>
          {
            currentCompany ? (
              <Box>
                <Box style={{ display: "flex", gap: "5px" }}>
                  <Typography variant='body2' fontWeight={"bold"}>Combined Score :</Typography>
                  <Typography variant='body2' fontWeight={"bold"}>{currentCompany["Combined Score"]}</Typography>
                </Box>
                {
                  !currentCompany["Recommended Net"] === "" && (<Box style={{ display: "flex", gap: "5px" }}>
                    <Typography variant='body2' fontWeight={"bold"}>Recommended Net :</Typography>
                    <Typography variant='body2' fontWeight={"bold"}>{currentCompany["Recommended Net"]}</Typography>
                  </Box>)
                }
                <Box style={{ display: "flex", gap: "5px" }}>
                  <Typography variant='body2' fontWeight={"bold"}>Recommended Limit :</Typography>
                  <Typography variant='body2' fontWeight={"bold"}>{currentCompany["Recommended Limit"]}</Typography>
                </Box>
                <Box style={{ display: "flex", gap: "5px" }}>
                  <Typography variant='body2' fontWeight={"bold"}>Underwriting Reason :</Typography>
                  <Typography variant='body2' fontWeight={"bold"}>{currentCompany["Underwriting Reason"]}</Typography>
                </Box>
                {
                  currentCompany["Altman Z Score"] && (<Box style={{ display: "flex", gap: "5px" }}>
                    <Typography variant='body2' fontWeight={"bold"}>Altman Z Score :</Typography>
                    <Typography variant='body2' fontWeight={"bold"}>{currentCompany["Altman Z Score"]}</Typography>
                  </Box>)
                }
                {
                  parentCompanyName && (
                    <Box style={{ display: "flex", gap: "5px" }}>
                    <Typography variant='body2' fontWeight={"bold"}>Parent Company :</Typography>
                    <Typography variant='body2' fontWeight={"bold"}>{parentCompanyName}</Typography>
                    </Box>
                  )
                }
              </Box>
            ) : null
          }
        </Box> */}
        <div>
          <Tabs
            sx={{
              borderBottom: 1,
              paddingTop: "20px",
              paddingLeft: "25px",
              "& .MuiTabs-indicator": {
                backgroundColor: "#2F3D63",
                width: "176px",
                borderRadius: "8px 8px 0px 0px",
                height: "3px",
              },
              "& .MuiTab-root": {
                color: "#777A82",
                textTransform: "none",
                height: "24px",
                fontSize: "20px",
                fontFamily: "Rubik , sans-serif",
                width: "176px",
               fontWeight: "250",
              },
              "& .Mui-selected": {
                fontWeight: "500",
                textTransform: "none",
                height: "24px",
                fontSize: "20px",
                fontFamily: "Rubik , sans-serif",
                width: "176px",
                color: "#2F3D61",
              },
            }}
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="company tabs"
          >
            <Tab label="General Info" {...a11yProps(0)} width="120px" />
            {show_files_section && <Tab label="Files" {...a11yProps(1)} width="86px" />}
            {show_questions_section && <Tab label="Questions" {...a11yProps(2)} width="112px" />}
          </Tabs>
          <TabPanel value={selectedTab} index={0}>
            {show_company_card && (
              <ExecutiveSummary
                company={company}
                summary={currentCompany}
                overview={companyOverviewData}
                incomeStatement={companyIncomeStatementData}
                AgingDataForCompany={agingData}
                DnbData={DnbData}
                tenantId={tenantId}
                linkedInJSON={linkedInJSON}
                setShowExport={setShowExport}
                dnBFinancialV2Data={dnBFinancialV2Data}
                dnbJsonData={dnbJsonData}
              />
            )}
            {/* {company && <ARCScoreChart company = {company} />}             */}
            {show_executive_summary && <Typography
              style={{
                textAlign: "left",
                fontSize: "20px",
                color: "#1A2A56",
                fontWeight: "600",
                width: "290px",
                height: "24px",
                padding: " 0 25px",
              }}
            >
              Executive Summary
            </Typography>}
            {company && show_executive_summary && <CompanySummary company={company} setCompanySummary={setCompanySummary} />}
            {company && show_finance_info && !isAllVarsInvalid && (
              <CompanyFinancialInfo
                overview={companyOverviewData}
                balanceSheet={companyBalanceSheetData}
                incomeStatement={companyIncomeStatementData}
                dnBFinancialV2Data={dnBFinancialV2Data}
                company={company}
                linkedInJSON={linkedInJSON}
                dnbJsonData={dnbJsonData}
              />
            )}
            {company?.googleSearch?.news?.items.length > 0 && show_news && <News company={company} />}
            {company?.summary?.split("\\n").length > 0 && (show_events && events?.length>0) && <CompanySummaryEvents company={company} />}
            
            {companyEvents.length > 0 && <CompanyEventTimeline timelineData={companyEvents} />}
          
          </TabPanel>
          {show_files_section && <TabPanel value={selectedTab} index={1}>
            <ChatFile company={company} />
          </TabPanel>}
          {show_questions_section && <TabPanel value={selectedTab} index={show_files_section ? 2 : 1}>
            <QuestionsAccordion questions={questions} companyId={company.id} setQuestions={setQuestions}/>
          </TabPanel>}
          <div id="question-page-section" style={{display:"none"}}>
              <QuestionsAccordion questions={questions} companyId={company.id} setQuestions={setQuestions} pdf={true}/>
            </div>
        </div>
        {/*<ResourcesTabs company={company} companyOverviewData={companyOverviewData} companyBalanceSheetData={companyBalanceSheetData} companyIncomeStatementData={companyIncomeStatementData} />
          <CompanySummary company={company} />
        <ChatFile company={company} />
        <DemoProperties company={company} />
        <QuestionsAccordion company={company} />
        <ResourcesTabs company={company} companyOverviewData={companyOverviewData} companyBalanceSheetData={companyBalanceSheetData} companyIncomeStatementData={companyIncomeStatementData} />
            */}
      </Paper>
    </div>
  );
}

export default CompanyPage;